//All Countries
import {
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
} from "../../actions/countries/getCountries";

//All States
import {
  GET_STATES_REQUEST,
  GET_STATES_SUCCESS,
  GET_STATES_ERROR,
} from "../../actions/countries/getStates";

//All Cities
import {
  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_CITIES_ERROR,
} from "../../actions/countries/getCities";

//Country
import {
  GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_ERROR,
} from "../../actions/countries/getCountry";

//Update Country
import {
  PUT_COUNTRY_REQUEST,
  PUT_COUNTRY_SUCCESS,
  PUT_COUNTRY_ERROR,
} from "../../actions/countries/putCountry";

//Create Country
import {
  POST_COUNTRY_REQUEST,
  POST_COUNTRY_SUCCESS,
  POST_COUNTRY_ERROR,
} from "../../actions/countries/postCountry";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
  states: {
    loading: false,
    error: false,
    data: [],
  },
  cities: {
    loading: false,
    error: false,
    data: [],
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COUNTRIES_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_COUNTRIES_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    // -------------------------------
    case GET_STATES_REQUEST:
      return {
        ...state,
        states: {
          error: false,
          loading: true,
          data: [],
        }
      };
    case GET_STATES_SUCCESS:
      return {
        ...state,
        states: {
          data: action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_STATES_ERROR:
      return {
        ...state,
        states: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -------------------------------
    case GET_CITIES_REQUEST:
      return {
        ...state,
        cities: {
          data: [],
          error: false,
          loading: true,
        }
      };
    case GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: {
          data: action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_CITIES_ERROR:
      return {
        ...state,
        cities: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -------------------------------
    case GET_COUNTRY_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loading: false,
      };
    case GET_COUNTRY_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case PUT_COUNTRY_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_COUNTRY_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_COUNTRY_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_COUNTRY_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_COUNTRY_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_COUNTRY_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
