import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        primary: {
            main: '#3E7BF4',
            contrastText: '#FFF',
        },
        secondary: {
            main: '#49bb78',
            contrastText: '#FFF',
        },
        error: {
            main: '#c53131',
        },
    },
    overrides: {
        MuiButton: {
            borderRadius: '6px',
        },
    },
});