import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_SNAPSHOT_EXPORT_REQUEST = "GET_SNAPSHOT_EXPORT_REQUEST";
export const GET_SNAPSHOT_EXPORT_SUCCESS = "GET_SNAPSHOT_EXPORT_SUCCESS";
export const GET_SNAPSHOT_EXPORT_ERROR = "GET_SNAPSHOT_EXPORT_ERROR";

//* ACTIONS ------------------------------------------------
export const getSnapshotExportRequest = (payload = {}, callback) => ({
  type: GET_SNAPSHOT_EXPORT_REQUEST,
  payload,
  callback,
});

export const getSnapshotExportSuccess = (data) => ({
  type: GET_SNAPSHOT_EXPORT_SUCCESS,
  payload: data,
});

export const getSnapshotExportError = (error) => ({
  type: GET_SNAPSHOT_EXPORT_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getSnapshotExportServiceRequest = async ({
  periodId,
  ...params
}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/payrolls/periods/${periodId}/snapshot/export`, options);
};
