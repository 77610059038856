import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_CONCEPT_REQUEST = "PUT_CONCEPT_REQUEST";
export const PUT_CONCEPT_SUCCESS = "PUT_CONCEPT_SUCCESS";
export const PUT_CONCEPT_ERROR = "PUT_CONCEPT_ERROR";

//* ACTIONS ------------------------------------------------
export const putConceptRequest = (payload = {}, callback) => ({
  type: PUT_CONCEPT_REQUEST,
  payload,
  callback,
});

export const putConceptSuccess = () => ({
  type: PUT_CONCEPT_SUCCESS,
});

export const putConceptError = (error) => ({
  type: PUT_CONCEPT_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putConceptServiceRequest = async ({ conceptId, ...data }) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/concepts/${conceptId}`, options);
};
