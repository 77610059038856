import {
  getPayrollReportRequest,
  getPayrollReportSuccess,
  getPayrollReportError,
  getPayrollReportServiceRequest
} from "../../../store/payroll/actions/reports/getReports";

export const getPayrollReport = (formData) => async (dispatch, getState) => {
  dispatch(getPayrollReportRequest());
  try {
    const response = await getPayrollReportServiceRequest(formData);
    dispatch(getPayrollReportSuccess(response.data));
  } catch (error) {
    dispatch(getPayrollReportError(error))
  }
  return Promise.resolve();
};
