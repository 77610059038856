//All Concept Categories
import {
  GET_CONCEPT_CATEGORIES_REQUEST,
  GET_CONCEPT_CATEGORIES_SUCCESS,
  GET_CONCEPT_CATEGORIES_ERROR,
} from "../../actions/concept_categories/getConceptCategories";

//Concept Category
import {
  GET_CONCEPT_CATEGORY_REQUEST,
  GET_CONCEPT_CATEGORY_SUCCESS,
  GET_CONCEPT_CATEGORY_ERROR,
} from "../../actions/concept_categories/getConceptCategory";

//Update Concept Category
import {
  PUT_CONCEPT_CATEGORY_REQUEST,
  PUT_CONCEPT_CATEGORY_SUCCESS,
  PUT_CONCEPT_CATEGORY_ERROR,
} from "../../actions/concept_categories/putConceptCategory";

//Create Concept Category
import {
  POST_CONCEPT_CATEGORY_REQUEST,
  POST_CONCEPT_CATEGORY_SUCCESS,
  POST_CONCEPT_CATEGORY_ERROR,
} from "../../actions/concept_categories/postConceptCategory";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONCEPT_CATEGORIES_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_CONCEPT_CATEGORIES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_CONCEPT_CATEGORIES_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case GET_CONCEPT_CATEGORY_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_CONCEPT_CATEGORY_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loading: false,
      };
    case GET_CONCEPT_CATEGORY_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case PUT_CONCEPT_CATEGORY_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_CONCEPT_CATEGORY_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_CONCEPT_CATEGORY_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_CONCEPT_CATEGORY_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_CONCEPT_CATEGORY_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_CONCEPT_CATEGORY_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
