import request, { Methods, withToken } from "../../../../utils/request";

//* ACTIONTYPES --------------------------------------------
export const POST_WEEK_DEFAULT = "POST_WEEK_DEFAULT";
export const POST_WEEK_REQUEST = "POST_WEEK_REQUEST";
export const POST_WEEK_SUCCESS = "POST_WEEK_SUCCESS";
export const POST_WEEK_ERROR = "POST_WEEK_ERROR";

//* ACTIONS ------------------------------------------------
export const postWeekDefault = () => ({
  type: POST_WEEK_DEFAULT,
});

export const postWeekRequest = (payload = {}, callback) => ({
  type: POST_WEEK_REQUEST,
  payload,
  callback,
});

export const postWeekSuccess = () => ({
  type: POST_WEEK_SUCCESS,
});

export const postWeekError = (error) => ({
  type: POST_WEEK_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postWeekServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    data,
  });

  return request("/weeks", options);
};
