import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Countries
import {
  // action types
  GET_COUNTRIES_REQUEST,
  // requests
  getCountriesServiceRequest,
  // actions
  getCountriesSuccess,
  getCountriesError,
} from "../../actions/countries/getCountries";

//* country
import {
  // action types
  GET_COUNTRY_REQUEST,
  // requests
  getCountryServiceRequest,
  // actions
  getCountrySuccess,
  getCountryError,
} from "../../actions/countries/getCountry";

//* Update country
import {
  // action types
  PUT_COUNTRY_REQUEST,
  // requests
  putCountryServiceRequest,
  // actions
  putCountrySuccess,
  putCountryError,
} from "../../actions/countries/putCountry";

//* Create country
import {
  // action types
  POST_COUNTRY_REQUEST,
  // requests
  postCountryServiceRequest,
  // actions
  postCountrySuccess,
  postCountryError,
} from "../../actions/countries/postCountry";

function* getCountries(action) {
  try {
    const result = yield call(getCountriesServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getCountriesSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getCountriesError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getCountry(action) {
  try {
    const result = yield call(getCountryServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getCountrySuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getCountryError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putCountry(action) {
  try {
    yield call(putCountryServiceRequest, action.payload);
    yield put(putCountrySuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putCountryError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postCountry(action) {
  try {
    yield call(postCountryServiceRequest, action.payload);
    yield put(postCountrySuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postCountryError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* countriesSaga() {
  yield takeLatest(GET_COUNTRIES_REQUEST, getCountries);
  yield takeLatest(GET_COUNTRY_REQUEST, getCountry);
  yield takeLatest(PUT_COUNTRY_REQUEST, putCountry);
  yield takeLatest(POST_COUNTRY_REQUEST, postCountry);
}

export default countriesSaga;
