import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  data,
  pagination,
} from "../../../store/administration/selectors/countries";

const Countries = lazy(() => import("./Countries"));

const mapStateToProps = (state) => ({
  isLoading: loading(state),
  countries: data(state),
  pagination: pagination(state),
});

export default connect(mapStateToProps)(Countries);
