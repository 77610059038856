import { combineReducers } from "redux";

import concepts from "./concepts";
import payrolls from "./payrolls";
import payrollEmployees from "./employees";
import payrollEmployeeDeductions from "./employeesDeductions";
import payrollEmployeeBenefits from "./employeesBenefits";
import periods from "./periods";
import payrollsEmployeeConcepts from "./payrollsEmployeeConcepts";
import histories from "./histories";
import reports from "./reports";

export default combineReducers({
  concepts,
  payrolls,
  periods,
  payrollEmployees,
  payrollEmployeeDeductions,
  payrollEmployeeBenefits,
  payrollsEmployeeConcepts,
  histories,
  reports,
});
