import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import * as storage from "localforage";

import authentication from "./auth/reducers";
import administration from "./administration/reducers";
import benefits from "./benefits/reducers";
import deductions from "./deductions/reducers";
import payroll from "./payroll/reducers";
import legal from "./legal/reducers";
import personnelActions from "./personnelActions/reducers";
import routes from "./routes/reducer";
import security from "./security/reducers";
import vacations from "./vacations/reducers";
import general from "./general/reducers";
import schedule from "./schedule/reducers";

const persistConfig = {
  blacklist: ["loading", "error"],
  key: "authentication",
  storage,
};

export default combineReducers({
  authentication: persistReducer(persistConfig, authentication),
  administration,
  benefits,
  deductions,
  payroll,
  legal,
  personnelActions,
  routes,
  security,
  vacations,
  general,
  schedule,
});
