import { lazy } from "react";
import { connect } from "react-redux";

import { getFinancialSquare } from "../../../hooks/Deductions/FinancialSquare/getFinancialSquare";

const FinancialSquare = lazy(() => import("./FinancialSquare"));

const mapStateToProps = (state) => {
  return ({
    financialSquare: state.deductions.financialSquare.financialSquare.data,
    loading: state.deductions.financialSquare.financialSquare.loading,
    error: state.deductions.financialSquare.financialSquare.error,
    pagination: state.deductions.financialSquare.financialSquare.pagination,
  })
};

const mapDispatchToProps = dispatch => ({
  getFinancialSquare: (formData) => dispatch(getFinancialSquare(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancialSquare);
