import { lazy } from "react";
import { connect } from "react-redux";

import { currentUser } from "../../../../store/auth/selectors";

const NewLineBusiness = lazy(() => import("./NewLineBusiness"));

const mapStateToProps = (state) => {
  return({
  isLoading: state.administration.line_business.postLineBusiness.loading,
  isSuccess: state.administration.line_business.postLineBusiness.success,
  isError: state.administration.line_business.postLineBusiness.error,
  message: state.administration.line_business.postLineBusiness.message,
  currentUser: currentUser(state),
})};

export default connect(mapStateToProps)(NewLineBusiness);
