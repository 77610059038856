// Login
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR } from "../actions/login";

// Current User
import {
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_ERROR,
} from "../actions/getCurrentUser";

//Set User Company
import {
  SET_USER_COMPANY_REQUEST,
  SET_USER_COMPANY_SUCCESS,
  SET_USER_COMPANY_ERROR,
} from "../actions/setUserCompany";

// Refresh Token
import {
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
} from "../actions/refreshToken";

// Forgot Password
import {
  POST_FORGOT_PASSWORD_REQUEST,
  POST_FORGOT_PASSWORD_SUCCESS,
  POST_FORGOT_PASSWORD_ERROR,
} from "../actions/postForgotPassword";

//Logout
import { LOGOUT } from "../actions/logout";

const INITIAL_STATE = {
  isLogged: false,
  loading: false,
  error: false,
  refreshing: false,
  currentUser: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Login
    case LOGIN_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
        isLogged: true,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        error: action.payload.status || action.payload || 401,
        loading: false,
      };
    case GET_CURRENT_USER_REQUEST:
      return {
        ...state,
        error: false,
        currentUser: {
          ...state.currentUser,
        },
        loading: true,
      };
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        error: false,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
        loading: false,
      };
    case GET_CURRENT_USER_ERROR:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
        },
        error: action.payload.status || action.payload || 401,
        loading: false,
      };
    case SET_USER_COMPANY_REQUEST:
      return {
        ...state,
        error: false,
        currentUser: {
          ...state.currentUser,
        },
        loading: true,
      };
    case SET_USER_COMPANY_SUCCESS:
      return {
        ...state,
        error: false,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
        loading: false,
      };
    case SET_USER_COMPANY_ERROR:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
        },
        error: action.payload.status || action.payload || 401,
        loading: false,
      };
    case REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        error: false,
        refreshing: true,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        error: false,
        refreshing: false,
      };
    case REFRESH_TOKEN_ERROR:
      return {
        ...state,
        error: action.payload.status || action.payload || 401,
        refreshing: false,
      };
    case POST_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case POST_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        error: action.payload.status || action.payload || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
