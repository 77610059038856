//Get Data
import {
  GET_LEGAL_SPU_REQUEST,
  GET_LEGAL_SPU_SUCCESS,
  GET_LEGAL_SPU_ERROR,
} from "../../actions/spu/getSPU";

//Get Data all
import {
  GET_LEGAL_SPU_ALL_REQUEST,
  GET_LEGAL_SPU_ALL_SUCCESS,
  GET_LEGAL_SPU_ALL_ERROR,
} from "../../actions/spu/getSPUAll";

//Get Observation Code
import {
  GET_LEGAL_OBSERVATION_CODE_SPU_REQUEST,
  GET_LEGAL_OBSERVATION_CODE_SPU_SUCCESS,
  GET_LEGAL_OBSERVATION_CODE_SPU_ERROR,
} from "../../actions/spu/getObservationCodeSPU";

//Post Legal
import {
  POST_LEGAL_SPU_REQUEST,
  POST_LEGAL_SPU_SUCCESS,
  POST_LEGAL_SPU_ERROR,
} from "../../actions/spu/postSnapshotSPU";

//Put Employee
import {
  PUT_LEGAL_EMPLOYEE_SPU_REQUEST,
  PUT_LEGAL_EMPLOYEE_SPU_SUCCESS,
  PUT_LEGAL_EMPLOYEE_SPU_ERROR,
} from "../../actions/spu/putEmployeSPU";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  getLegalSPU: {
    loading: false,
    error: false,
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  getLegalSPUAll: {
    loading: false,
    error: false,
    data: [],
  },
  getLegalObservationSPU: {
    loading: false,
    error: false,
    data: [],
  },
  postLegalSnapshotSPU: {
    loading: false,
    error: false,
    data: [],
    success: false,
  },
  putLegalEmployeeSPU: {
    loading: false,
    error: false,
    data: [],
  },

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LEGAL_SPU_REQUEST:
      return {
        ...state,
        getLegalSPU: {
          error: false,
          loading: true,
        }
      };
    case GET_LEGAL_SPU_SUCCESS:
      return {
        ...state,
        getLegalSPU: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: false,
          loading: false,
        }
      };
    case GET_LEGAL_SPU_ERROR:
      return {
        ...state,
        getLegalSPU: {
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -----------------------------------------------------------
    case GET_LEGAL_SPU_ALL_REQUEST:
      return {
        ...state,
        getLegalSPUAll: {
          error: false,
          loading: true,
        }
      };
    case GET_LEGAL_SPU_ALL_SUCCESS:
      return {
        ...state,
        getLegalSPUAll: {
          data: action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_LEGAL_SPU_ALL_ERROR:
      return {
        ...state,
        getLegalSPUAll: {
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -----------------------------------------------------------
    case GET_LEGAL_OBSERVATION_CODE_SPU_REQUEST:
      return {
        ...state,
        getLegalObservationSPU: {
          ...state.getLegalObservationSPU,
          error: false,
          loading: true,
        }
      };
    case GET_LEGAL_OBSERVATION_CODE_SPU_SUCCESS:
      return {
        ...state,
        getLegalObservationSPU: {
          ...state.getLegalObservationSPU,
          data: action.payload.data,
          error: false,
          loading: false,
        }
      };
    case GET_LEGAL_OBSERVATION_CODE_SPU_ERROR:
      return {
        ...state,
        getLegalObservationSPU: {
          ...state.getLegalObservationSPU,
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -----------------------------------------------------------
    case POST_LEGAL_SPU_REQUEST:
      return {
        ...state,
        postLegalSnapshotSPU: {
          data: [],
          error: false,
          loading: true,
          success: false,
        }
      };
    case POST_LEGAL_SPU_SUCCESS:
      return {
        ...state,
        postLegalSnapshotSPU: {
          data: action.payload.data,
          error: false,
          loading: false,
          success: true,
        }
      };
    case POST_LEGAL_SPU_ERROR:
      return {
        ...state,
        postLegalSnapshotSPU: {
          error: action.payload || action.payload.status || 401,
          loading: false,
          success: false,
        }
      };
    // -----------------------------------------------------------
    case PUT_LEGAL_EMPLOYEE_SPU_REQUEST:
      return {
        ...state,
        putLegalEmployeeSPU: {
          ...state.putLegalEmployeeSPU,
          error: false,
          loading: true,
        }
      };
    case PUT_LEGAL_EMPLOYEE_SPU_SUCCESS:
      return {
        ...state,
        putLegalEmployeeSPU: {
          ...state.putLegalEmployeeSPU,
          data: action.payload,
          error: false,
          loading: false,
        }
      };
    case PUT_LEGAL_EMPLOYEE_SPU_ERROR:
      return {
        ...state,
        putLegalEmployeeSPU: {
          ...state.putLegalEmployeeSPU,
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };

    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
