import {
  getVacationsCurrentPeriodRequest,
  getVacationsCurrentPeriodSuccess,
  getVacationsCurrentPeriodError,
  getVacationsCurrentPeriodServiceRequest,
} from "../../../store/vacations/actions/vacations/getVacationsCurrentPeriod";

export const getFetchVacationCurrentPeriod = (formData) => async (dispatch, getState) => {
  dispatch(getVacationsCurrentPeriodRequest());
  try {
    const response = await getVacationsCurrentPeriodServiceRequest(formData);
    dispatch(getVacationsCurrentPeriodSuccess(response.data));
  } catch (error) {
    dispatch(getVacationsCurrentPeriodError(error))
  }
  return Promise.resolve();
};
