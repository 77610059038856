//All Financial Entities
import {
  GET_ALL_CHIEF_REQUEST,
  GET_ALL_CHIEF_SUCCESS,
  GET_ALL_CHIEF_ERROR,
} from "../../actions/chief/getAllChief";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_CHIEF_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CHIEF_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case GET_ALL_CHIEF_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    //--------------------------

    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
