import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";

//Customs
import PrivateRoute from "./PrivateRoute";
import MainLayout from "../components/Layouts/Main";

//Selectors
import { isLogged } from "../store/auth/selectors";

//* DASHBOARD ROUTES *//
//* ADMINISTRATION
import AdministrationRoutes from "./Administration";
//* BENEFITS
import BenefitsRoutes from "./Benefits";
//* DEDUCTIONS
import DeductionsRoutes from "./Deductions";
//* DEDUCTIONS
import EmployeesRoutes from "./Employees";
//* PAYROLLS
import PayrollRoutes from "./Payroll";
//* LEGAL
import Legal from "./Legal";
//* ACCIONES DE PERSONAL
import PersonnelActions from "./PersonnelActions";
//* VACATIONS
import Vacations from "./Vacations";
//* SECURITY
import Security from "./Security";
//* HOME
import Home from "./Home";
//* HORARIOS
import Schedule from "./Schedule";

const Fallback = () => <div className="w-full w-height">cargando.....</div>;

const DashboardRoutes = ({ isLogged }) => {

  return (
    <MainLayout>
      <Suspense fallback={<Fallback />}>
        <Switch>
          <PrivateRoute
            path="/administration"
            component={AdministrationRoutes}
            isAuthenticated={isLogged}
          />
          <PrivateRoute
            path="/employees"
            component={EmployeesRoutes}
            isAuthenticated={isLogged}
          />
          <PrivateRoute
            path="/payroll"
            component={PayrollRoutes}
            isAuthenticated={isLogged}
          />
          <PrivateRoute
            path="/deductions"
            component={DeductionsRoutes}
            isAuthenticated={isLogged}
          />
          <PrivateRoute
            path="/benefits"
            component={BenefitsRoutes}
            isAuthenticated={isLogged}
          />
          <PrivateRoute
            path="/legal"
            component={Legal}
            isAuthenticated={isLogged}
          />
          <PrivateRoute
            path="/personnel-actions"
            component={PersonnelActions}
            isAuthenticated={isLogged}
          />
          <PrivateRoute
            path="/vacations"
            component={Vacations}
            isAuthenticated={isLogged}
          />
          <PrivateRoute
            path="/security"
            component={Security}
            isAuthenticated={isLogged}
          />
          <PrivateRoute
            path="/home"
            component={Home}
            isAuthenticated={isLogged}
          />
          <PrivateRoute
            path="/schedule"
            component={Schedule}
            isAuthenticated={isLogged}
          />
          <Redirect to="/home" />
        </Switch>
      </Suspense>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  isLogged: isLogged(state),
});

export default connect(mapStateToProps)(DashboardRoutes);
