import { lazy } from "react";
import { connect } from "react-redux";
import {
  loadingAction,
  loading,
  detail,
} from "../../../../store/administration/selectors/concept_types";

const ShowConceptType = lazy(() => import("./ShowConceptType"));

const mapStateToProps = (state) => ({
  loadingAction: loadingAction(state),
  loading: loading(state),
  conceptType: detail(state),
});

export default connect(mapStateToProps)(ShowConceptType);
