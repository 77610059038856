import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_LEGAL_F910_REQUEST = "GET_LEGAL_F910_REQUEST";
export const GET_LEGAL_F910_SUCCESS = "GET_LEGAL_F910_SUCCESS";
export const GET_LEGAL_F910_ERROR = "GET_LEGAL_F910_ERROR";

//* ACTIONS ------------------------------------------------
export const getF910Request = (payload = {}, callback) => ({
  type: GET_LEGAL_F910_REQUEST,
  payload,
  callback,
});

export const getF910Success = (data) => ({
  type: GET_LEGAL_F910_SUCCESS,
  payload: data,
});

export const getF910Error = (error) => ({
  type: GET_LEGAL_F910_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getF910ServiceRequest = async (params) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/isr/report_f910`, options);
};
