import styled from "styled-components";

const Styled = styled.div`
  /* z-index: 20000; */
  &.is-showed {
    background: rgba(0, 0, 0, 0.5);
    /* visibility: visible; */
    animation: fadeIn;
    animation-duration: 0.4s;
  }
  &.is-hidden {
    display: none;
    animation: fadeOut;
    animation-duration: 2s;
    animate-delay: 2s;
  }
  .in-layout {
    padding-left: 4.1875rem;
  }
  .z-index-10 {
    z-index: 10;
  }
`;

export default Styled;
