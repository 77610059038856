import {
  getVacationsEnjoyRequest,
  getVacationsEnjoySuccess,
  getVacationsEnjoyError,
  getVacationsEnjoyServiceRequest,
} from "../../../store/vacations/actions/vacations/getVacationsEnjoy";

export const getFetchVacationEnjoy = (formData) => async (dispatch, getState) => {
  dispatch(getVacationsEnjoyRequest());
  try {
    const response = await getVacationsEnjoyServiceRequest(formData);
    dispatch(getVacationsEnjoySuccess(response.data));
  } catch (error) {
    dispatch(getVacationsEnjoyError(error))
  }
  return Promise.resolve();
};
