import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import MainLayout from "./Main";

// selectors
import {
  isLogged,
  isRefreshingToken,
  currentUser,
} from "../../../store/auth/selectors";
import {
  loading,
  data,
} from "../../../store/administration/selectors/companies";

const mapStateToProps = (state) => ({
  isLogged: isLogged(state),
  currentUser: currentUser(state),
  isRefreshingToken: isRefreshingToken(state),
  isLoadingCompanies: loading(state),
  companies: data(state),
});

export default connect(mapStateToProps)(withRouter(MainLayout));
