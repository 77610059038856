import { lazy } from "react";
import { connect } from "react-redux";
import { currentUser } from "../../../../store/auth/selectors";
import { loading as isLoadingAllEmployees } from "../../../../store/administration/selectors/employees";
import {
  loading as isLoadingUbications,
  data as ubicationsData,
} from "../../../../store/administration/selectors/ubications";
import {
  loading as isLoadingPositions,
  data as positionsData,
} from "../../../../store/administration/selectors/positions";

const NewEmployee = lazy(() => import("./NewEmployee"));

const mapStateToProps = (state) => ({
  currentUser: currentUser(state),
  isLoading: isLoadingAllEmployees(state),
  isLoadingUbications: isLoadingUbications(state),
  ubications: ubicationsData(state),
  isLoadingPositions: isLoadingPositions(state),
  positions: positionsData(state),
});

export default connect(mapStateToProps)(NewEmployee);
