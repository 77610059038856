import React from "react";
import { Route, Switch } from "react-router-dom";

import Employees from "../../../modules/Employees/Employees";
import NewEmployee from "../../../modules/Employees/Employees/new";
import ShowEmployee from "../../../modules/Employees/Employees/show";

export default () => {
  return (
    <Switch>
      <Route exact path="/employees/employees" component={Employees} />
      <Route
        exact
        path="/employees/employees/new"
        component={NewEmployee}
      />
      <Route
        exact
        path="/employees/employees/:id"
        component={ShowEmployee}
      />
    </Switch>
  );
};