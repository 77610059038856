import { createSelector } from "reselect";

const ubications = (state) => state.administration.ubications;

export const loading = createSelector(ubications, (state) => state.loading);

export const loadingAction = createSelector(
  ubications,
  (state) => state.loadingAction
);

export const data = createSelector(ubications, (state) => state.data);

export const pagination = createSelector(
  ubications,
  (state) => state.pagination
);

export const detail = createSelector(ubications, (state) => state.detail);
