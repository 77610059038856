import { lazy } from "react";
import { connect } from "react-redux";
import { getLegalISSS } from "../../../hooks/Legal/ISSS/useFetchISSS";
import { getLegalISSSAll } from "../../../hooks/Legal/ISSS/useFetchISSSAll";
import { postLegalISSS } from "../../../hooks/Legal/ISSS/useFetchSnapshotISSS";
import { putLegalEmployeeISSS } from "../../../hooks/Legal/ISSS/useFetchEmployeeISSS";
import { getLegalObservationCodeISSS } from "../../../hooks/Legal/ISSS/useFetchObservationCodeISSS";
import { getPayrollHistory } from "../../../hooks/Payroll/Histories/useFetchHistoies";

const ISSS = lazy(() => import("./ISSS"));

const mapStateToProps = (state) => {
  return({
  dataISSS: state.legal.isss.getLegalISSS.data,
  isLoading: state.legal.isss.getLegalISSS.loading,
  isError: state.legal.isss.getLegalISSS.error,
  pagination: state.legal.isss.getLegalISSS.pagination,
  
  dataISSSAll: state.legal.isss.getLegalISSSAll.data,
  isLoadingAll: state.legal.isss.getLegalISSSAll.loading,
  isErrorAll: state.legal.isss.getLegalISSSAll.error,

  snapshotIsssData: state.legal.isss.postLegalSnapshotISSS.data,
  snapshotIsssSuccess: state.legal.isss.postLegalSnapshotISSS.success,
  snapshotIsssLoading: state.legal.isss.postLegalSnapshotISSS.loading,
  snapshotIsssError: state.legal.isss.postLegalSnapshotISSS.error,

  dataCodes: state.legal.isss.getLegalObservationISSS.data,
  isLoadingCodes: state.legal.isss.getLegalObservationISSS.loading,
  isErrorCodes: state.legal.isss.getLegalObservationISSS.error,

  isLoadingPut: state.legal.isss.putLegalEmployeeISSS.loading,
  isErrorPut: state.legal.isss.putLegalEmployeeISSS.error,

  payrolls: state.payroll.payrolls.data,
  periods: state.payroll.histories.data,
  periodsLoading: state.payroll.histories.loading,
})};

const mapDispatchToProps = dispatch => ({
  getLegalISSS: (formData) => dispatch(getLegalISSS(formData)),
  getLegalISSSAll: (formData) => dispatch(getLegalISSSAll(formData)),
  postLegalISSS: (formData) => dispatch(postLegalISSS(formData)),
  putLegalEmployeeISSS: (formData) => dispatch(putLegalEmployeeISSS(formData)),
  getLegalObservationCodeISSS: (formData) => dispatch(getLegalObservationCodeISSS(formData)),
  getPayrollHistory: (formData) => dispatch(getPayrollHistory(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ISSS);
