import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Documents
import {
  // action types
  GET_DOCUMENTS_REQUEST,
  // requests
  getDocumentsServiceRequest,
  // actions
  getDocumentsSuccess,
  getDocumentsError,
} from "../../actions/documents/getDocuments";

//* Document
import {
  // action types
  GET_DOCUMENT_REQUEST,
  // requests
  getDocumentServiceRequest,
  // actions
  getDocumentSuccess,
  getDocumentError,
} from "../../actions/documents/getDocument";

//* Update Document
import {
  // action types
  PUT_DOCUMENT_REQUEST,
  // requests
  putDocumentServiceRequest,
  // actions
  putDocumentSuccess,
  putDocumentError,
} from "../../actions/documents/putDocument";

//* Create Document
import {
  // action types
  POST_DOCUMENT_REQUEST,
  // requests
  postDocumentServiceRequest,
  // actions
  postDocumentSuccess,
  postDocumentError,
} from "../../actions/documents/postDocument";

function* getDocuments(action) {
  try {
    const result = yield call(getDocumentsServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getDocumentsSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getDocumentsError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getDocument(action) {
  try {
    const result = yield call(getDocumentServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getDocumentSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getDocumentError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putDocument(action) {
  try {
    yield call(putDocumentServiceRequest, action.payload);
    yield put(putDocumentSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putDocumentError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postDocument(action) {
  try {
    yield call(postDocumentServiceRequest, action.payload);
    yield put(postDocumentSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postDocumentError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* documentsSaga() {
  yield takeLatest(GET_DOCUMENTS_REQUEST, getDocuments);
  yield takeLatest(GET_DOCUMENT_REQUEST, getDocument);
  yield takeLatest(PUT_DOCUMENT_REQUEST, putDocument);
  yield takeLatest(POST_DOCUMENT_REQUEST, postDocument);
}

export default documentsSaga;
