import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Financial Entities
import {
  // action types
  GET_FINANCIAL_ENTITIES_REQUEST,
  // requests
  getFinancialEntitiesServiceRequest,
  // actions
  getFinancialEntitiesSuccess,
  getFinancialEntitiesError,
} from "../../actions/financial_entities/getFinancialEntities";

//* Financial Entitie
import {
  // action types
  GET_FINANCIAL_ENTITIE_REQUEST,
  // requests
  getFinancialEntitieServiceRequest,
  // actions
  getFinancialEntitieSuccess,
  getFinancialEntitieError,
} from "../../actions/financial_entities/getFinancialEntitie";

//* Update Financial Entitie
import {
  // action types
  PUT_FINANCIAL_ENTITIE_REQUEST,
  // requests
  putFinancialEntitieServiceRequest,
  // actions
  putFinancialEntitieSuccess,
  putFinancialEntitieError,
} from "../../actions/financial_entities/putFinancialEntitie";

//* Create Financial Entitie
import {
  // action types
  POST_FINANCIAL_ENTITIE_REQUEST,
  // requests
  postFinancialEntitieServiceRequest,
  // actions
  postFinancialEntitieSuccess,
  postFinancialEntitieError,
} from "../../actions/financial_entities/postFinancialEntitie";

function* getFinancialEntities(action) {
  try {
    const result = yield call(
      getFinancialEntitiesServiceRequest,
      action.payload
    );
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getFinancialEntitiesSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getFinancialEntitiesError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getFinancialEntitie(action) {
  try {
    const result = yield call(
      getFinancialEntitieServiceRequest,
      action.payload
    );
    const data = get(result, "data.data", {});

    yield put(getFinancialEntitieSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getFinancialEntitieError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putFinancialEntitie(action) {
  try {
    yield call(putFinancialEntitieServiceRequest, action.payload);
    yield put(putFinancialEntitieSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putFinancialEntitieError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postFinancialEntitie(action) {
  try {
    yield call(postFinancialEntitieServiceRequest, action.payload);
    yield put(postFinancialEntitieSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postFinancialEntitieError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* financialEntitiesSaga() {
  yield takeLatest(GET_FINANCIAL_ENTITIES_REQUEST, getFinancialEntities);
  yield takeLatest(GET_FINANCIAL_ENTITIE_REQUEST, getFinancialEntitie);
  yield takeLatest(PUT_FINANCIAL_ENTITIE_REQUEST, putFinancialEntitie);
  yield takeLatest(POST_FINANCIAL_ENTITIE_REQUEST, postFinancialEntitie);
}

export default financialEntitiesSaga;
