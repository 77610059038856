//All Weeks
import {
  GET_ALL_DASHBOARD_REQUEST,
  GET_ALL_DASHBOARD_SUCCESS,
  GET_ALL_DASHBOARD_ERROR,
} from "../../actions/dashboard/getAllDashboard";

//sincronizar
import {
  POST_SYNC_SCHEDULE_DEFAULT,
  POST_SYNC_SCHEDULE_REQUEST,
  POST_SYNC_SCHEDULE_SUCCESS,
  POST_SYNC_SCHEDULE_ERROR,
} from "../../actions/dashboard/postSyncSchedule";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  postSyncSchedule: {
    loading: false,
    error: false,
    data: {},
    success: false,
    message: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_DASHBOARD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case GET_ALL_DASHBOARD_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    //--------------------------
    case POST_SYNC_SCHEDULE_DEFAULT:
      return {
        ...state,
        postSyncSchedule: INITIAL_STATE.postSyncSchedule
      };
    case POST_SYNC_SCHEDULE_REQUEST:
      return {
        ...state,
        postSyncSchedule: {
          data: {},
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case POST_SYNC_SCHEDULE_SUCCESS:
      return {
        ...state,
        postSyncSchedule: {
          data: action.payload,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case POST_SYNC_SCHEDULE_ERROR:
      return {
        ...state,
        postSyncSchedule: {
          data: {},
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };

    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
