import styled from "styled-components";

import { tuna } from "../../../../utils/colors";

const Styled = styled.div`
  .login-right-side {
    background-color: ${tuna};
  }
`;

export default Styled;
