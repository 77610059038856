import React from "react";

import { ReactComponent } from "../../../assets/images/power-by-staffi.svg";
import { white } from "../../../utils/colors";

const StaffiPower = ({ fillColor = white, ...props }) => {
  return <ReactComponent fill={fillColor} {...props} />;
};

export default StaffiPower;
