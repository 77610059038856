import { lazy } from "react";
import { connect } from "react-redux";

const LineBusiness = lazy(() => import("./LineBusiness"));

const mapStateToProps = (state) => {
  return ({
    isLoading: state.administration.line_business.loading,
    lineBusiness: state.administration.line_business.data,
    pagination: state.administration.line_business.pagination,
  })
};

export default connect(mapStateToProps)(LineBusiness);
