import {
  putLegalEmployeeISSSRequest,
  putLegalEmployeeISSSSuccess,
  putLegalEmployeeISSSError,
  putLegalEmployeeISSSServiceRequest
} from "../../../store/legal/actions/isss/putEmployeISSS";

export const putLegalEmployeeISSS = (formData) => async (dispatch, getState) => {
  dispatch(putLegalEmployeeISSSRequest());
  try {
    const response = await putLegalEmployeeISSSServiceRequest(formData);
    dispatch(putLegalEmployeeISSSSuccess(response.data));
  } catch (error) {
    dispatch(putLegalEmployeeISSSError(error))
  }
  return Promise.resolve();
};
