import { lazy } from "react";
import { connect } from "react-redux";
import { getF910 } from "../../../hooks/Legal/F910/useFetchISR";
import { getCodeIngreso } from "../../../hooks/Legal/F910/useFetcCodeIngreso";

const F910 = lazy(() => import("./F910"));

const mapStateToProps = (state) => {
  return ({
    company: state.authentication.currentUser.currentCompany.tradename,
    lastRoutes: state.routes.routes,

    ISR: state.legal.f910.getLegalF910.data,
    isLoading: state.legal.f910.getLegalF910.loading,
    isError: state.legal.f910.getLegalF910.error,
    pagination: state.legal.f910.getLegalF910.pagination,
    codeIngreso: state.legal.f910.getCodeIngreso
  })
};

const mapDispatchToProps = dispatch => ({
  getF910: (formData) => dispatch(getF910(formData)),
  getCodeIngreso: (formData) => dispatch(getCodeIngreso(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(F910);
