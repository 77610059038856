import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_DOCUMENT_REQUEST = "PUT_DOCUMENT_REQUEST";
export const PUT_DOCUMENT_SUCCESS = "PUT_DOCUMENT_SUCCESS";
export const PUT_DOCUMENT_ERROR = "PUT_DOCUMENT_ERROR";

//* ACTIONS ------------------------------------------------
export const putDocumentRequest = (payload = {}, callback) => ({
  type: PUT_DOCUMENT_REQUEST,
  payload,
  callback,
});

export const putDocumentSuccess = () => ({
  type: PUT_DOCUMENT_SUCCESS,
});

export const putDocumentError = (error) => ({
  type: PUT_DOCUMENT_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putDocumentServiceRequest = async ({ documentId, ...data }) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/documents/${documentId}`, options);
};
