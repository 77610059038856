import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_PAYROLL_EMPLOYEES_REQUEST = "GET_PAYROLL_EMPLOYEES_REQUEST";
export const GET_PAYROLL_EMPLOYEES_SUCCESS = "GET_PAYROLL_EMPLOYEES_SUCCESS";
export const GET_PAYROLL_EMPLOYEES_ERROR = "GET_PAYROLL_EMPLOYEES_ERROR";

//* ACTIONS ------------------------------------------------
export const getPayrollEmployeesRequest = (payload = {}, callback) => ({
  type: GET_PAYROLL_EMPLOYEES_REQUEST,
  payload,
  callback,
});

export const getPayrollEmployeesSuccess = (data) => ({
  type: GET_PAYROLL_EMPLOYEES_SUCCESS,
  payload: data,
});

export const getPayrollEmployeesError = (error) => ({
  type: GET_PAYROLL_EMPLOYEES_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getPayrollEmployeesServiceRequest = async ({
  payrollId,
  ...params
}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/payrolls/${payrollId}/employees`, options);
};
