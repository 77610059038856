import React from "react";
import { Route, Switch } from "react-router-dom";

import History from "../../../modules/Benefits/History";

export default () => {
  return (
    <Switch>
      <Route exact path="/benefits/historical" component={History} />
    </Switch>
  );
};
