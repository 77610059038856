import { lazy } from "react";
import { connect } from "react-redux";
import {
  loadingAction,
  loading,
  detail,
} from "../../../../store/administration/selectors/requirements";

const ShowRequirement = lazy(() => import("./ShowRequirement"));

const mapStateToProps = (state) => ({
  loadingAction: loadingAction(state),
  loading: loading(state),
  requirement: detail(state),
});

export default connect(mapStateToProps)(ShowRequirement);
