import { createSelector } from "reselect";

const areas = (state) => state.administration.areas;

export const loading = createSelector(areas, (state) => state.loading);

export const loadingAction = createSelector(
  areas,
  (state) => state.loadingAction
);

export const data = createSelector(areas, (state) => state.data);

export const pagination = createSelector(areas, (state) => state.pagination);

export const detail = createSelector(areas, (state) => state.detail);
