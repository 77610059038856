import { combineReducers } from "redux";

import spu from "./spu";
import afp from "./afp";
import isss from "./isss";
import isr from "./isr";
import f910 from "./f910";

export default combineReducers({
  spu,
  afp,
  isss,
  isr,
  f910,
});
