import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  data,
  pagination,
} from "../../../store/administration/selectors/documents";

const Documents = lazy(() => import("./Documents"));

const mapStateToProps = (state) => ({
  isLoading: loading(state),
  documents: data(state),
  pagination: pagination(state),
});

export default connect(mapStateToProps)(Documents);
