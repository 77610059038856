import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_UNSUSCRIBE_REQUEST = "PUT_UNSUSCRIBE_REQUEST";
export const PUT_UNSUSCRIBE_SUCCESS = "PUT_UNSUSCRIBE_SUCCESS";
export const PUT_UNSUSCRIBE_ERROR = "PUT_UNSUSCRIBE_ERROR";

//* ACTIONS ------------------------------------------------
export const putUnsuscribeRequest = (payload = {}, callback) => ({
  type: PUT_UNSUSCRIBE_REQUEST,
  payload,
  callback,
});

export const putUnsuscribeSuccess = (data) => ({
  type: PUT_UNSUSCRIBE_SUCCESS,
  payload: data,
});

export const putUnsuscribeError = (error) => ({
  type: PUT_UNSUSCRIBE_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putUnsuscribeServiceRequest = async ({ employeeId, data }) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: data,
  });
  return request(`employees/${employeeId}/inactivate`, options);
};
