import React from "react";
import { Route, Switch } from "react-router-dom";

import Histories from "../../../modules/Payroll/Histories";
import HistoriesPeriods from "../../../modules/Payroll/Histories/Periods";

export default () => {
  return (
    <Switch>
      <Route exact path="/payroll/history" component={Histories} />
      <Route exact path="/payroll/history/:id/periods/:periodId" component={HistoriesPeriods} />
    </Switch>
  );
};