import React from "react";
import { Link } from "react-router-dom";
import { Form, Field, Formik } from "formik";
import { get, find, isEmpty } from "lodash";
import * as storage from "localforage";

//custom
import Styled from "./styled";
import Input from "../../../components/Form/InputText";
import Button from "../../../components/Buttons/Button";
import StaffiLogo from "../../../components/logos/staffi";
import PowerStaffi from "../../../components/logos/powerStaffi";
import StaffiBusinessLines from "../../../components/logos/staffiBusinessLines";

//validators
import Validators from "./Validators";

// actions
import { loginRequest } from "../../../store/auth/actions/login";



const login = async (action) => {
  // try {
  //   const data = await call(getLoginRequest, action.payload);
  //   await storage.setItem("token", get(data, "data.access_token", ""));

  //   // obtener id company (ya que para este momento no existe aún)
  //   const getCompany = find(get(data, "data.modules", []), (company) => !isEmpty(company.module))
  //   const getIdCompany = get(getCompany, "id", 1)

  //   // obtener la empresa a la que tiene derecho este fulano.
  //   const resp = await getCompaniesPermited(get(data, "data.user_id", ""), get(data, "data.access_token", ""), getIdCompany)
  //   const companyAccessList = get(resp, "data.data", [])
  //   const currentCompany = find(companyAccessList, (company) => !!(company.id))

  //   await storage.setItem("companyId", get(currentCompany, "id"));
  //   await storage.setItem("userId", get(data, "data.user_id", ""));

  //   await put(loginSuccess(data.data));
  //   await put(getCurrentUserSuccess({ currentCompany }))

  //   action.callback && action.callback(null, data.data);
  // } catch (e) {
  //   await put(loginError(e.response));

  //   action.callback && action.callback(e.response);
  // }
}

const SignInPage = ({ isLogged, isLoading, dispatch, history }) => {
  const onSubmit = async (values, setErrors) => {
    const { password, user: username } = values;

    let formData = {
      username,
      password,
    };

    return await new Promise((resolve, reject) => {
      dispatch(
        loginRequest(formData, (error, dataResponse) => {


          if (error) {
            setErrors({
              user: "Credenciales inválidas",
            });
            return reject(error);
          }
          // const modules = filter(dataResponse.modules, (item) => isEmpty(item.module))
          const lastPath = "/home";
          // if (modules.length > 1) {
          //   history.replace(lastPath);
          // } else {
          window.location.push(lastPath)
          // }
          resolve();

          //   dispatch(
          //   getCurrentEmployeeRequest(getCurrentEmployeeError => {
          //     if (!getCurrentEmployeeError) {
          //       resolve();

          //       return history.push('/unbilled');
          //     }

          //     return reject(getCurrentEmployeeError);
          //   })
          // );
        })
      );
    });
  };

  return (
    <Styled className="h-screen w-screen flex flex-row animate__animated animate__fadeIn">
      <div className="col-span-2 w-1/2">
        <Formik
          initialValues={{
            user: "",
            password: "",
          }}
          enableReinitialize
          validationSchema={Validators}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            onSubmit(values, setErrors);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, resetForm, values, setFieldValue }) => {
            return (
              <Form className="w-full h-full flex flex-col items-center justify-between flex-wrap">
                <div className="p-10 mr-auto">
                  <StaffiLogo className="mb-5" />
                </div>
                <div className="w-3/5">
                  <h2 className="mb-10 font-bold">Inicia sesión</h2>
                  <Field
                    name="user"
                    label={<span>Correo Electrónico <span className="text-red-700">*</span></span>}
                    placeholder="Ingesa tu correo electrónico"
                    component={Input}
                    containerClass="mb-8"
                    require
                  />
                  <Field
                    type="password"
                    name="password"
                    label={<span>Contraseña <span className="text-red-700">*</span></span>}
                    placeholder="Ingesa tu correo contraseña"
                    component={Input}
                    containerClass="mb-0"
                    require
                  />
                  <div className="w-full flex justify-end mb-8 mt-3">
                    {/* <Link to="/forgot-password" className="text-gray-600">
                      ¿Olvido su contraseña?
                    </Link> */}
                  </div>
                  <Button
                    type="submit"
                    isLoading={isSubmitting || isLoading}
                    text="Iniciar sesión"
                  />
                </div>
                <div className="flex flex-row justify-center items-center gap-1 p-10">
                  Powered by
                  <PowerStaffi />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="bg-gray-700 w-1/2 h-full">
        <StaffiBusinessLines className="w-full h-full object-cover" />
      </div>
    </Styled>
  );
};

export default SignInPage;
