import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_STATES_REQUEST = "GET_STATES_REQUEST";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_ERROR = "GET_STATES_ERROR";

//* ACTIONS ------------------------------------------------
export const getStatesRequest = (payload = {}, callback) => ({
  type: GET_STATES_REQUEST,
  payload,
  callback,
});

export const getStatesSuccess = (data) => ({
  type: GET_STATES_SUCCESS,
  payload: data,
});

export const getStatesError = (error) => ({
  type: GET_STATES_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getStatesServiceRequest = async ({ countryId, ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/countries/${countryId}/states`, options);
};
