//All Deductions payrolls
import {
  GET_FINANCIAL_SQUARE_REQUEST,
  GET_FINANCIAL_SQUARE_SUCCESS,
  GET_FINANCIAL_SQUARE_ERROR,
} from "../../actions/financialSquare/getFinancialSquare";

//All Deductions
import {
  GET_FINANCIAL_SQUARE_TOTAL_REQUEST,
  GET_FINANCIAL_SQUARE_TOTAL_SUCCESS,
  GET_FINANCIAL_SQUARE_TOTAL_ERROR,
} from "../../actions/financialSquare/getFinancialSquareTotal";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  financialSquare: {
    loading: false,
    error: false,
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  financialSquareTotal: {
    loading: false,
    error: false,
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_FINANCIAL_SQUARE_REQUEST:
      return {
        ...state,
        financialSquare: {
          ...state.financialSquare,
          error: false,
          loading: true,
        }
      };
    case GET_FINANCIAL_SQUARE_SUCCESS:
      return {
        ...state,
        financialSquare: {
          ...state.financialSquare,
          error: false,
          loading: false,
          ...action.payload,
        }
      };
    case GET_FINANCIAL_SQUARE_ERROR:
      return {
        ...state,

        loading: false,
        ...state,
        financialSquare: {
          ...state.financialSquare,
          error: action.payload || action.payload.status || 401,
          loading: false,
          data: [],
        }
      };
    // -----------------------
    case GET_FINANCIAL_SQUARE_TOTAL_REQUEST:
      return {
        ...state,
        financialSquareTotal: {
          ...state.financialSquareTotal,
          error: false,
          loading: true,
        }
      };
    case GET_FINANCIAL_SQUARE_TOTAL_SUCCESS:
      return {
        ...state,
        financialSquareTotal: {
          ...state.financialSquareTotal,
          error: false,
          loading: false,
          ...action.payload,
        }
      };
    case GET_FINANCIAL_SQUARE_TOTAL_ERROR:
      return {
        ...state,

        loading: false,
        ...state,
        financialSquareTotal: {
          ...state.financialSquareTotal,
          error: action.payload || action.payload.status || 401,
          loading: false,
          data: [],
        }
      };
    // -----------------------
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
