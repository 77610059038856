import { createSelector } from "reselect";

const conceptCategories = (state) => state.administration.concept_categories;

export const loading = createSelector(
  conceptCategories,
  (state) => state.loading
);

export const loadingAction = createSelector(
  conceptCategories,
  (state) => state.loadingAction
);

export const data = createSelector(conceptCategories, (state) => state.data);

export const pagination = createSelector(
  conceptCategories,
  (state) => state.pagination
);

export const detail = createSelector(
  conceptCategories,
  (state) => state.detail
);
