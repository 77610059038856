import concepts from "./concepts";
import payrolls from "./payrolls";
import payrollEmployees from "./employees";
import payrollEmployeeDeductions from "./employeesDeductions";
import payrollEmployeeBenefits from "./employeesBenefits";
import periods from "./periods";

export default [
  concepts,
  payrolls,
  payrollEmployees,
  payrollEmployeeDeductions,
  payrollEmployeeBenefits,
  periods,
];
