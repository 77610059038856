import React from "react";
import { Route, Switch } from "react-router-dom";

import Users from "../../../modules/Security/Users";
import NewUbication from "../../../modules/Security/Users/new";
import ShowUbication from "../../../modules/Security/Users/show";

export default () => {
  return (
    <Switch>
      <Route exact path="/security/users" component={Users} />
      <Route
        exact
        path="/security/users/new"
        component={NewUbication}
      />
      <Route
        exact
        path="/security/users/:id"
        component={ShowUbication}
      />
    </Switch>
  );
};
