import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  data,
  pagination,
} from "../../../store/payroll/selectors/concepts";

const Concepts = lazy(() => import("./Concepts"));

const mapStateToProps = (state) => ({
  isLoading: loading(state),
  concepts: data(state),
  pagination: pagination(state),
});

export default connect(mapStateToProps)(Concepts);
