import request, { Methods, withToken } from "../../../utils/request";
import { getUserCompany } from "../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_ALL_PERSONNEL_ACTIONS_REQUEST = "GET_ALL_PERSONNEL_ACTIONS_REQUEST";
export const GET_ALL_PERSONNEL_ACTIONS_SUCCESS = "GET_ALL_PERSONNEL_ACTIONS_SUCCESS";
export const GET_ALL_PERSONNEL_ACTIONS_ERROR = "GET_ALL_PERSONNEL_ACTIONS_ERROR";

//* ACTIONS ------------------------------------------------
export const getAllPersonnelActionsRequest = (payload = {}, callback) => ({
  type: GET_ALL_PERSONNEL_ACTIONS_REQUEST,
  payload,
  callback,
});

export const getAllPersonnelActionsSuccess = (data) => ({
  type: GET_ALL_PERSONNEL_ACTIONS_SUCCESS,
  payload: data,
});

export const getAllPersonnelActionsError = (error) => ({
  type: GET_ALL_PERSONNEL_ACTIONS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getAllPersonnelActionsServiceRequest = async ({ ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/personnel_actions`, options);
};
