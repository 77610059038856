import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_AREAS_REQUEST = "GET_AREAS_REQUEST";
export const GET_AREAS_SUCCESS = "GET_AREAS_SUCCESS";
export const GET_AREAS_ERROR = "GET_AREAS_ERROR";

//* ACTIONS ------------------------------------------------
export const getAreasRequest = (payload = {}, callback) => ({
  type: GET_AREAS_REQUEST,
  payload,
  callback,
});

export const getAreasSuccess = (data) => ({
  type: GET_AREAS_SUCCESS,
  payload: data,
});

export const getAreasError = (error) => ({
  type: GET_AREAS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getAreasServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/areas`, options);
};
