import { put, takeEvery } from "redux-saga/effects";
import { isEqual } from "lodash";

//* refresh token
import { refreshTokenRequest } from "../auth/actions/refreshToken";

//* logout
// actions
import { logout } from "../auth/actions/logout";

function* catchError(action) {
  try {
    const status = action.payload ? action.payload.status : 0;

    if (isEqual(status, 401)) {
      yield put(refreshTokenRequest());
    }
    if (isEqual(status, 403)) {
      global.location.href = "/403";
    }

    if (isEqual(status, 404)) {
      global.location.href = "/404";
    }

    if (isEqual(status, 500)) {
      global.location.href = "/500";
    }
  } catch (error) {
    yield put(logout());

    global.location.href = "/log-out";
  }
}

function* catchErrorSagas() {
  yield takeEvery("*", catchError);
}

export default catchErrorSagas;
