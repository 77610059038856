import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_REQUIREMENT_REQUEST = "PUT_REQUIREMENT_REQUEST";
export const PUT_REQUIREMENT_SUCCESS = "PUT_REQUIREMENT_SUCCESS";
export const PUT_REQUIREMENT_ERROR = "PUT_REQUIREMENT_ERROR";

//* ACTIONS ------------------------------------------------
export const putRequirementRequest = (payload = {}, callback) => ({
  type: PUT_REQUIREMENT_REQUEST,
  payload,
  callback,
});

export const putRequirementSuccess = () => ({
  type: PUT_REQUIREMENT_SUCCESS,
});

export const putRequirementError = (error) => ({
  type: PUT_REQUIREMENT_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putRequirementServiceRequest = async ({
  requirementId,
  ...data
}) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/requirements/${requirementId}`, options);
};
