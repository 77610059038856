//Get Data
import {
  ROUTES,
} from "../action/route";

//Logout
import { LOGOUT } from "../../auth/actions/logout";

const INITIAL_STATE = {
  routes: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ROUTES:
      return {
        routes: action.payload,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
