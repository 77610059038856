import React from "react";
import { Route, Switch } from "react-router-dom";

import CostCenters from "../../../modules/Administration/CostCenters";
import NewCostCenter from "../../../modules/Administration/CostCenters/new";
import ShowCostCenter from "../../../modules/Administration/CostCenters/show";

export default () => {
  return (
    <Switch>
      <Route exact path="/administration/costcenters" component={CostCenters} />
      <Route
        exact
        path="/administration/costcenters/new"
        component={NewCostCenter}
      />
      <Route
        exact
        path="/administration/costcenters/:id"
        component={ShowCostCenter}
      />
    </Switch>
  );
};
