import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Areas
import {
  // action types
  GET_AREAS_REQUEST,
  // requests
  getAreasServiceRequest,
  // actions
  getAreasSuccess,
  getAreasError,
} from "../../actions/areas/getAreas";

//* Areas
import {
  // action types
  GET_AREA_REQUEST,
  // requests
  getAreaServiceRequest,
  // actions
  getAreaSuccess,
  getAreaError,
} from "../../actions/areas/getArea";

//* Update Areas
import {
  // action types
  PUT_AREA_REQUEST,
  // requests
  putAreaServiceRequest,
  // actions
  putAreaSuccess,
  putAreaError,
} from "../../actions/areas/putArea";

//* Create Areas
import {
  // action types
  POST_AREA_REQUEST,
  // requests
  postAreaServiceRequest,
  // actions
  postAreaSuccess,
  postAreaError,
} from "../../actions/areas/postArea";

function* getAreas(action) {
  try {
    const result = yield call(getAreasServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getAreasSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getAreasError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getArea(action) {
  try {
    const result = yield call(getAreaServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getAreaSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getAreaError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putArea(action) {
  try {
    yield call(putAreaServiceRequest, action.payload);
    yield put(putAreaSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putAreaError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postArea(action) {
  try {
    yield call(postAreaServiceRequest, action.payload);
    yield put(postAreaSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postAreaError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* areasSaga() {
  yield takeLatest(GET_AREAS_REQUEST, getAreas);
  yield takeLatest(GET_AREA_REQUEST, getArea);
  yield takeLatest(PUT_AREA_REQUEST, putArea);
  yield takeLatest(POST_AREA_REQUEST, postArea);
}

export default areasSaga;
