import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Ubications
import {
  // action types
  GET_UBICATIONS_REQUEST,
  // requests
  getUbicationsServiceRequest,
  // actions
  getUbicationsSuccess,
  getUbicationsError,
} from "../../actions/ubications/getUbications";

//* Ubication
import {
  // action types
  GET_UBICATION_REQUEST,
  // requests
  getUbicationServiceRequest,
  // actions
  getUbicationSuccess,
  getUbicationError,
} from "../../actions/ubications/getUbication";

//* Update Ubication
import {
  // action types
  PUT_UBICATION_REQUEST,
  // requests
  putUbicationServiceRequest,
  // actions
  putUbicationSuccess,
  putUbicationError,
} from "../../actions/ubications/putUbication";

//* Create Ubication
import {
  // action types
  POST_UBICATION_REQUEST,
  // requests
  postUbicationServiceRequest,
  // actions
  postUbicationSuccess,
  postUbicationError,
} from "../../actions/ubications/postUbication";

function* getUbications(action) {
  try {
    const result = yield call(getUbicationsServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getUbicationsSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getUbicationsError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getUbication(action) {
  try {
    const result = yield call(getUbicationServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getUbicationSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getUbicationError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putUbication(action) {
  try {
    yield call(putUbicationServiceRequest, action.payload);
    yield put(putUbicationSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putUbicationError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postUbication(action) {
  try {
    yield call(postUbicationServiceRequest, action.payload);
    yield put(postUbicationSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postUbicationError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* ubicationsSaga() {
  yield takeLatest(GET_UBICATIONS_REQUEST, getUbications);
  yield takeLatest(GET_UBICATION_REQUEST, getUbication);
  yield takeLatest(PUT_UBICATION_REQUEST, putUbication);
  yield takeLatest(POST_UBICATION_REQUEST, postUbication);
}

export default ubicationsSaga;
