import React from "react";
import { Route, Switch } from "react-router-dom";

import EnjoyVacation from "../../../modules/Vacations/EnjoyVacation";

export default () => {
  return (
    <Switch>
      <Route exact path="/vacations/enjoy-vacations" component={EnjoyVacation} />
    </Switch>
  );
};