import React from "react";
import AppRouter from "./routers/AppRouter";
import theme from "./utils/theme"
import { ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const App = () => (
  <ThemeProvider theme={theme}>
    <AppRouter />
  </ThemeProvider>
);

export default App;
