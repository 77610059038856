import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_CONCEPT_CATEGORY_REQUEST = "PUT_CONCEPT_CATEGORY_REQUEST";
export const PUT_CONCEPT_CATEGORY_SUCCESS = "PUT_CONCEPT_CATEGORY_SUCCESS";
export const PUT_CONCEPT_CATEGORY_ERROR = "PUT_CONCEPT_CATEGORY_ERROR";

//* ACTIONS ------------------------------------------------
export const putConceptCategoryRequest = (payload = {}, callback) => ({
  type: PUT_CONCEPT_CATEGORY_REQUEST,
  payload,
  callback,
});

export const putConceptCategorySuccess = () => ({
  type: PUT_CONCEPT_CATEGORY_SUCCESS,
});

export const putConceptCategoryError = (error) => ({
  type: PUT_CONCEPT_CATEGORY_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putConceptCategoryServiceRequest = async ({
  conceptCategoryId,
  ...data
}) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/concept_categories/${conceptCategoryId}`, options);
};
