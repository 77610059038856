import { createSelector } from "reselect";

const requirements = (state) => state.administration.requirements;

export const loading = createSelector(requirements, (state) => state.loading);

export const loadingAction = createSelector(
  requirements,
  (state) => state.loadingAction
);

export const data = createSelector(requirements, (state) => state.data);

export const pagination = createSelector(
  requirements,
  (state) => state.pagination
);

export const detail = createSelector(requirements, (state) => state.detail);
