import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  data,
  pagination,
} from "../../../store/administration/selectors/costcenters";

const CostCenters = lazy(() => import("./CostCenters"));

const mapStateToProps = (state) => ({
  isLoading: loading(state),
  costcenters: data(state),
  pagination: pagination(state),
});

export default connect(mapStateToProps)(CostCenters);
