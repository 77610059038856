import React from "react";
import { Route, Switch } from "react-router-dom";

import Requirements from "../../../modules/Administration/Requirements";
import NewRequirement from "../../../modules/Administration/Requirements/new";
import ShowRequirement from "../../../modules/Administration/Requirements/show";

export default () => {
  return (
    <Switch>
      <Route
        exact
        path="/administration/requirements"
        component={Requirements}
      />
      <Route
        exact
        path="/administration/requirements/new"
        component={NewRequirement}
      />
      <Route
        exact
        path="/administration/requirements/:id"
        component={ShowRequirement}
      />
    </Switch>
  );
};
