import {
  getF910Request,
  getF910Success,
  getF910Error,
  getF910ServiceRequest,
} from "../../../store/legal/actions/f910/getF910";

export const getF910 = (formData) => async (dispatch, getState) => {

  dispatch(getF910Request());
  try {
    const response = await getF910ServiceRequest(formData);
    dispatch(getF910Success(response.data));
  } catch (error) {
    dispatch(getF910Error(error))
  }
  return Promise.resolve();
};
