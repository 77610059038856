import React from "react";
import { Route, Switch } from "react-router-dom";

import FinancialSquare from "../../../modules/Deductions/FinancialSquare";

export default () => {
  return (
    <Switch>
      <Route exact path="/deductions/financial-square" component={FinancialSquare} />
    </Switch>
  );
};
