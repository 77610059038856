import {
  getModulesByUserRequest,
  getModulesByUserSuccess,
  getModulesByUserError,
  getModulesByUserServiceRequest,
} from "../../../store/security/actions/users/getModulesByUser";

export const getFetchModulesByUser = (formData) => async (dispatch, getState) => {
  dispatch(getModulesByUserRequest());
  try {
    const response = await getModulesByUserServiceRequest(formData);
    dispatch(getModulesByUserSuccess(response.data));
  } catch (error) {
    dispatch(getModulesByUserError(error))
  }
  return Promise.resolve();
};

