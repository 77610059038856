//All Users
import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
} from "../../actions/users/getUsers";

//All Roles
import {
  GET_ROLES_REQUEST,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR,
} from "../../actions/users/getRoles";

//All Roles
import {
  GET_MODULES_REQUEST,
  GET_MODULES_SUCCESS,
  GET_MODULES_ERROR,
} from "../../actions/users/getModules";

//All Roles
import {
  GET_MODULES_BY_USER_REQUEST,
  GET_MODULES_BY_USER_SUCCESS,
  GET_MODULES_BY_USER_ERROR,
} from "../../actions/users/getModulesByUser";

//Concept User
import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
} from "../../actions/users/getUser";

//Update User
import {
  PUT_USER_REQUEST,
  PUT_USER_SUCCESS,
  PUT_USER_ERROR,
} from "../../actions/users/putUser";

//Create User
import {
  POST_USER_REQUEST,
  POST_USER_SUCCESS,
  POST_USER_ERROR,
} from "../../actions/users/postUser";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  detail: {
    data: {}
  },
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  roles: {
    loading: false,
    error: false,
    data: [],
  },
  modules: {
    loading: false,
    error: false,
    data: [],
  },
  moduleByUser: {
    loading: false,
    error: false,
    data: [],
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_USERS_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
      // ----------------------------------
      case GET_ROLES_REQUEST:
      return {
        ...state,
        roles: {
          data: [],
          error: false,
          loading: true,
        }
      };
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: {
          ...action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_ROLES_ERROR:
      return {
        ...state,
        roles: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // --------------------
      case GET_MODULES_REQUEST:
      return {
        ...state,
        modules: {
          data: [],
          error: false,
          loading: true,
        }
      };
    case GET_MODULES_SUCCESS:
      return {
        ...state,
        modules: {
          ...action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_MODULES_ERROR:
      return {
        ...state,
        modules: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // --------------------
      case GET_MODULES_BY_USER_REQUEST:
      return {
        ...state,
        moduleByUser: {
          data: [],
          error: false,
          loading: true,
        }
      };
    case GET_MODULES_BY_USER_SUCCESS:
      return {
        ...state,
        moduleByUser: {
          ...action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_MODULES_BY_USER_ERROR:
      return {
        ...state,
        moduleByUser: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // --------------------
    case GET_USER_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loading: false,
      };
    case GET_USER_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
      //----------------------------------------
    case PUT_USER_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_USER_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_USER_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
      //---------------------------------------------
    case POST_USER_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_USER_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_USER_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
