import {
  getLegalSPURequest,
  getLegalSPUSuccess,
  getLegalSPUError,
  getLegalSPUServiceRequest
} from "../../../store/legal/actions/spu/getSPU";

export const getLegalSPU = (formData) => async (dispatch, getState) => {
  dispatch(getLegalSPURequest());
  try {
    const response = await getLegalSPUServiceRequest(formData);
    dispatch(getLegalSPUSuccess(response.data));
  } catch (error) {
    dispatch(getLegalSPUError(error))
  }
  return Promise.resolve();
};
