import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_PAYROLL_EMPLOYEE_BENEFITS_REQUEST =
  "GET_PAYROLL_EMPLOYEE_BENEFITS_REQUEST";
export const GET_PAYROLL_EMPLOYEE_BENEFITS_SUCCESS =
  "GET_PAYROLL_EMPLOYEE_BENEFITS_SUCCESS";
export const GET_PAYROLL_EMPLOYEE_BENEFITS_ERROR =
  "GET_PAYROLL_EMPLOYEE_BENEFITS_ERROR";

//* ACTIONS ------------------------------------------------
export const getPayrollEmployeeBenefitsRequest = (payload = {}, callback) => ({
  type: GET_PAYROLL_EMPLOYEE_BENEFITS_REQUEST,
  payload,
  callback,
});

export const getPayrollEmployeeBenefitsSuccess = (data) => ({
  type: GET_PAYROLL_EMPLOYEE_BENEFITS_SUCCESS,
  payload: data,
});

export const getPayrollEmployeeBenefitsError = (error) => ({
  type: GET_PAYROLL_EMPLOYEE_BENEFITS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getPayrollEmployeeBenefitsServiceRequest = async ({
  payrollId,
  employeeId,
  ...params
}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(
    `/payrolls/${payrollId}/employees/${employeeId}/benefits`,
    options
  );
};
