import { createSelector } from "reselect";

const auth = (state) => state.authentication;

export const isLogged = createSelector(auth, (state) => state.isLogged);
export const isLoading = createSelector(auth, (state) => state.loading);
export const isRefreshingToken = createSelector(
  auth,
  (state) => state.refreshing
);

export const currentUser = createSelector(auth, (state) => state.currentUser);
