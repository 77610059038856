import React from "react";
import Staffi from "../../components/logos/staffi";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: 'calc(100% + 2em)',
    height: 'calc(100% + 2em)',
    margin: '-1em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
}));

const AFP = () => {
  const classes = useStyles()
  return (
    <div className={`${classes.root}`} style={{height: "calc(100vh - 84px)"}}>
      <Staffi className="w-4/4" />
      {/* <div className="w-4/4" /> */}
    </div>
  );
};

export default AFP;