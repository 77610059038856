import authentication from "./auth/sagas";
import administration from "./administration/sagas";
import benefits from "./benefits/sagas";
import deductions from "./deductions/sagas";
import payroll from "./payroll/sagas";
import catchErrors from "./catchErrors";

export default [
  authentication,
  ...administration,
  ...benefits,
  ...deductions,
  ...payroll,
  catchErrors,
];
