import React from "react";
import { Route, Switch } from "react-router-dom";

import ISRMensual from "../../../modules/Legal/ISRMensual";

export default () => {
  return (
    <Switch>
      <Route exact path="/legal/isr" component={ISRMensual} />
    </Switch>
  );
};