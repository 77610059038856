import {
  getCodeIngresoRequest,
  getCodeIngresoSuccess,
  getCodeIngresoError,
  getCodeIngresoServiceRequest,
} from "../../../store/legal/actions/f910/getCodeIngreso";

export const getCodeIngreso = (formData) => async (dispatch, getState) => {
  dispatch(getCodeIngresoRequest());
  try {
    const response = await getCodeIngresoServiceRequest(formData);
    dispatch(getCodeIngresoSuccess(response.data));
  } catch (error) {
    dispatch(getCodeIngresoError(error))
  }
  return Promise.resolve();
};
