import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_FINANCIAL_ENTITIE_REQUEST = "POST_FINANCIAL_ENTITIE_REQUEST";
export const POST_FINANCIAL_ENTITIE_SUCCESS = "POST_FINANCIAL_ENTITIE_SUCCESS";
export const POST_FINANCIAL_ENTITIE_ERROR = "POST_FINANCIAL_ENTITIE_ERROR";

//* ACTIONS ------------------------------------------------
export const postFinancialEntitieRequest = (payload = {}, callback) => ({
  type: POST_FINANCIAL_ENTITIE_REQUEST,
  payload,
  callback,
});

export const postFinancialEntitieSuccess = () => ({
  type: POST_FINANCIAL_ENTITIE_SUCCESS,
});

export const postFinancialEntitieError = (error) => ({
  type: POST_FINANCIAL_ENTITIE_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postFinancialEntitieServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request("/financial_entities", options);
};
