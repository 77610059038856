//All Deductions
import {
  GET_DEDUCTIONS_HISTORY_REQUEST,
  GET_DEDUCTIONS_HISTORY_SUCCESS,
  GET_DEDUCTIONS_HISTORY_ERROR,
} from "../../actions/history/getDeductionsHistory";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DEDUCTIONS_HISTORY_REQUEST:
      return {
        ...state,
        data: [],
        error: false,
        loading: true,
      };
    case GET_DEDUCTIONS_HISTORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_DEDUCTIONS_HISTORY_ERROR:
      return {
        ...state,
        data: [],
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
