import {
  postLegalSPURequest,
  postLegalSPUSuccess,
  postLegalSPUError,
  postLegalSPUServiceRequest
} from "../../../store/legal/actions/spu/postSnapshotSPU";

export const postLegalSPU = (formData) => async (dispatch, getState) => {
  dispatch(postLegalSPURequest());
  try {
    const response = await postLegalSPUServiceRequest(formData);
    dispatch(postLegalSPUSuccess(response.data));
  } catch (error) {
    dispatch(postLegalSPUError(error.response || error))
  }
  return Promise.resolve();
};
