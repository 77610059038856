import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_BENEFIT_REQUEST = "POST_BENEFIT_REQUEST";
export const POST_BENEFIT_SUCCESS = "POST_BENEFIT_SUCCESS";
export const POST_BENEFIT_ERROR = "POST_BENEFIT_ERROR";

//* ACTIONS ------------------------------------------------
export const postBenefitRequest = (payload = {}, callback) => ({
  type: POST_BENEFIT_REQUEST,
  payload,
  callback,
});

export const postBenefitSuccess = () => ({
  type: POST_BENEFIT_SUCCESS,
});

export const postBenefitError = (error) => ({
  type: POST_BENEFIT_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postBenefitServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request("/benefits", options);
};
