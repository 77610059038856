import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_BENEFIT_EMPLOYEES_REQUEST = "GET_BENEFIT_EMPLOYEES_REQUEST";
export const GET_BENEFIT_EMPLOYEES_SUCCESS = "GET_BENEFIT_EMPLOYEES_SUCCESS";
export const GET_BENEFIT_EMPLOYEES_ERROR = "GET_BENEFIT_EMPLOYEES_ERROR";

//* ACTIONS ------------------------------------------------
export const getBenefitEmployeesRequest = (payload = {}, callback) => ({
  type: GET_BENEFIT_EMPLOYEES_REQUEST,
  payload,
  callback,
});

export const getBenefitEmployeesSuccess = (data) => ({
  type: GET_BENEFIT_EMPLOYEES_SUCCESS,
  payload: data,
});

export const getBenefitEmployeesError = (error) => ({
  type: GET_BENEFIT_EMPLOYEES_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getBenefitEmployeesServiceRequest = async ({
  benefitId,
  payrollId,
  ...params
}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(
    `/benefits/${benefitId}/payrolls/${payrollId}/employees`,
    options
  );
};
