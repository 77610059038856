import React from "react";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";

//Customs
import PrivateRoute from "../PrivateRoute";

//Selectors
import { isLogged } from "../../store/auth/selectors";

//* ROUTES *//
import Administration from "./Administration";
import Historial from "./Historial";
import Create from "./Create";
import Aprove from "./Aprove";

const LegalRoutes = ({ isLogged }) => {
  return (
    <Switch>
      <PrivateRoute
        path="/personnel-actions/administration"
        component={Administration}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/personnel-actions/histories"
        component={Historial}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/personnel-actions/create"
        component={Create}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/personnel-actions/approval"
        component={Aprove}
        isAuthenticated={isLogged}
      />
      <Redirect to="/" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isLogged: isLogged(state),
});

export default connect(mapStateToProps)(LegalRoutes);
