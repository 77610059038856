//All Deductions payrolls
import {
  GET_DEDUCTIONS_PAYROLL_REQUEST,
  GET_DEDUCTIONS_PAYROLL_SUCCESS,
  GET_DEDUCTIONS_PAYROLL_ERROR
} from "../../actions/deductions/getDeductionsPayrolls";

//All Deductions
import {
  GET_DEDUCTIONS_REQUEST,
  GET_DEDUCTIONS_SUCCESS,
  GET_DEDUCTIONS_ERROR,
} from "../../actions/deductions/getDeductions";

//Deduction
import {
  GET_DEDUCTION_PAYROLL_REQUEST,
  GET_DEDUCTION_SUCCESS,
  GET_DEDUCTION_ERROR,
} from "../../actions/deductions/getDeductionPayrolls";

//Update Deduction
import {
  PUT_DEDUCTION_REQUEST,
  PUT_DEDUCTION_SUCCESS,
  PUT_DEDUCTION_ERROR,
} from "../../actions/deductions/putDeduction";

//Create Deduction
import {
  POST_DEDUCTION_REQUEST,
  POST_DEDUCTION_SUCCESS,
  POST_DEDUCTION_ERROR,
} from "../../actions/deductions/postDeduction";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  loadingDetail: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DEDUCTIONS_PAYROLL_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_DEDUCTIONS_PAYROLL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_DEDUCTIONS_PAYROLL_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    // -----------------------
    case GET_DEDUCTIONS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_DEDUCTIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_DEDUCTIONS_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    // -----------------------
    case GET_DEDUCTION_PAYROLL_REQUEST:
      return {
        ...state,
        error: false,
        loadingDetail: true,
      };
    case GET_DEDUCTION_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loadingDetail: false,
      };
    case GET_DEDUCTION_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingDetail: false,
      };
    case PUT_DEDUCTION_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_DEDUCTION_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_DEDUCTION_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_DEDUCTION_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case POST_DEDUCTION_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case POST_DEDUCTION_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
