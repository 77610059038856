import { lazy } from "react";
import { connect } from "react-redux";
import {
  loadingAction,
  loading,
  detail,
} from "../../../../store/administration/selectors/documents";

const ShowDocument = lazy(() => import("./ShowDocument"));

const mapStateToProps = (state) => ({
  loadingAction: loadingAction(state),
  loading: loading(state),
  document: detail(state),
});

export default connect(mapStateToProps)(ShowDocument);
