import { lazy } from "react";
import { connect } from "react-redux";

import { getFetchAreasByPeriods } from "../../../../hooks/Administration/Areas/getAreasByPeriods"
import { getHeaderPeriodHistory } from "../../../../hooks/Payroll/Payrolls/getHeaderPeriodHistory"

const Area = lazy(() => import("./Area"));

const mapStateToProps = (state) => {
  return ({
    areas: state.administration.areas.areaByPeriods.data,
    loading: state.administration.areas.areaByPeriods.loading,
    error: state.administration.areas.areaByPeriods.error,
    pagination: state.administration.areas.areaByPeriods.pagination,
    loadingPayroll: state.payroll.periods.getHeaderHistory.loading,
    payroll: state.payroll.periods.getHeaderHistory.data,
  })
};

const mapDispatchToProps = dispatch => ({
  getFetchAreasByPeriods: (formData) => dispatch(getFetchAreasByPeriods(formData)),
  getHeaderPeriodHistory: (formData) => dispatch(getHeaderPeriodHistory(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Area);
