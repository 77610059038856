import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_COMPANY_REQUEST = "PUT_COMPANY_REQUEST";
export const PUT_COMPANY_SUCCESS = "PUT_COMPANY_SUCCESS";
export const PUT_COMPANY_ERROR = "PUT_COMPANY_ERROR";

//* ACTIONS ------------------------------------------------
export const putCompanyRequest = (payload = {}, callback) => ({
  type: PUT_COMPANY_REQUEST,
  payload,
  callback,
});

export const putCompanySuccess = () => ({
  type: PUT_COMPANY_SUCCESS,
});

export const putCompanyError = (error) => ({
  type: PUT_COMPANY_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putCompanyServiceRequest = async ({ companyId, ...data }) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/companies/${companyId}`, options);
};
