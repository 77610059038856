import { lazy } from "react";
import { connect } from "react-redux";
import {
  loadingAction,
  loading,
  data,
  detail,
} from "../../../../store/administration/selectors/areas";

const ShowArea = lazy(() => import("./ShowArea"));

const mapStateToProps = (state) => ({
  loadingAction: loadingAction(state),
  loading: loading(state),
  area: detail(state),
  areas: data(state),
});

export default connect(mapStateToProps)(ShowArea);
