import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_FINANCIAL_SQUARE_REQUEST = "GET_FINANCIAL_SQUARE_REQUEST";
export const GET_FINANCIAL_SQUARE_SUCCESS = "GET_FINANCIAL_SQUARE_SUCCESS";
export const GET_FINANCIAL_SQUARE_ERROR = "GET_FINANCIAL_SQUARE_ERROR";

//* ACTIONS ------------------------------------------------
export const getFinancialSquareRequest = (payload = {}, callback) => ({
  type: GET_FINANCIAL_SQUARE_REQUEST,
  payload,
  callback,
});

export const getFinancialSquareSuccess = (data) => ({
  type: GET_FINANCIAL_SQUARE_SUCCESS,
  payload: data,
});

export const getFinancialSquareError = (error) => ({
  type: GET_FINANCIAL_SQUARE_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getFinancialSquareServiceRequest = async ({ payrollId, ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/deductions/payroll/${payrollId}/financial_square`, options);
};
