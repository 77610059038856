import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_LEGAL_ISR_DEFAULT = "POST_LEGAL_ISR_DEFAULT";
export const POST_LEGAL_ISR_REQUEST = "POST_LEGAL_ISR_REQUEST";
export const POST_LEGAL_ISR_SUCCESS = "POST_LEGAL_ISR_SUCCESS";
export const POST_LEGAL_ISR_ERROR = "POST_LEGAL_ISR_ERROR";

//* ACTIONS ------------------------------------------------
export const postLegalISRDefault = (payload = {}, callback) => ({
  type: POST_LEGAL_ISR_DEFAULT,
});

export const postLegalISRRequest = (payload = {}, callback) => ({
  type: POST_LEGAL_ISR_REQUEST,
  payload,
  callback,
});

export const postLegalISRSuccess = (data) => ({
  type: POST_LEGAL_ISR_SUCCESS,
  payload: data,
});

export const postLegalISRError = (error) => ({
  type: POST_LEGAL_ISR_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postLegalISRServiceRequest = async ({ months, years, ...data }) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });
  return request(`/payrolls/isr/months/${months}/years/${years}`, options);
};
