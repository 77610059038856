//All Financial Entities
import {
  GET_ALL_LINE_BUSINESS_REQUEST,
  GET_ALL_LINE_BUSINESS_SUCCESS,
  GET_ALL_LINE_BUSINESS_ERROR,
} from "../../actions/line_business/getAllLineBusiness";

//Financial Entitie
import {
  GET_LINE_BUSINESS_REQUEST,
  GET_LINE_BUSINESS_SUCCESS,
  GET_LINE_BUSINESS_ERROR,
} from "../../actions/line_business/getLineBusiness";

//Update Financial Entitie
import {
  PUT_LINE_BUSINESS_DEFAULT,
  PUT_LINE_BUSINESS_REQUEST,
  PUT_LINE_BUSINESS_SUCCESS,
  PUT_LINE_BUSINESS_ERROR,
} from "../../actions/line_business/putLineBusiness";

//Create Financial Entitie
import {
  POST_LINE_BUSINESS_DEFAULT,
  POST_LINE_BUSINESS_REQUEST,
  POST_LINE_BUSINESS_SUCCESS,
  POST_LINE_BUSINESS_ERROR,
} from "../../actions/line_business/postLineBusiness";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
  putLineBusiness: {
    loading: false,
    error: false,
    data: {},
    success: false,
    message: null,
  },
  postLineBusiness: {
    loading: false,
    error: false,
    data: {},
    success: false,
    message: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_LINE_BUSINESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_LINE_BUSINESS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case GET_ALL_LINE_BUSINESS_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    //--------------------------
    case GET_LINE_BUSINESS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_LINE_BUSINESS_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        loading: false,
      };
    case GET_LINE_BUSINESS_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    //--------------------------
    case PUT_LINE_BUSINESS_DEFAULT:
      return {
        ...state,
        putLineBusiness: INITIAL_STATE.putLineBusiness
      };
    case PUT_LINE_BUSINESS_REQUEST:
      return {
        ...state,
        putLineBusiness: {
          data: {},
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case PUT_LINE_BUSINESS_SUCCESS:
      return {
        ...state,
        putLineBusiness: {
          data: action.payload,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case PUT_LINE_BUSINESS_ERROR:
      return {
        ...state,
        putLineBusiness: {
          data: {},
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };
    //--------------------------
    case POST_LINE_BUSINESS_DEFAULT:
      return {
        ...state,
        postLineBusiness: INITIAL_STATE.postLineBusiness
      };
    case POST_LINE_BUSINESS_REQUEST:
      return {
        ...state,
        postLineBusiness: {
          data: {},
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case POST_LINE_BUSINESS_SUCCESS:
      return {
        ...state,
        postLineBusiness: {
          data: action.payload,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case POST_LINE_BUSINESS_ERROR:
      return {
        ...state,
        postLineBusiness: {
          data: {},
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };


    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
