import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_AREA_REQUEST = "PUT_AREA_REQUEST";
export const PUT_AREA_SUCCESS = "PUT_AREA_SUCCESS";
export const PUT_AREA_ERROR = "PUT_AREA_ERROR";

//* ACTIONS ------------------------------------------------
export const putAreaRequest = (payload = {}, callback) => ({
  type: PUT_AREA_REQUEST,
  payload,
  callback,
});

export const putAreaSuccess = () => ({
  type: PUT_AREA_SUCCESS,
});

export const putAreaError = (error) => ({
  type: PUT_AREA_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putAreaServiceRequest = async ({ areaId, ...data }) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/areas/${areaId}`, options);
};
