import React from "react";
import ReactDOM from "react-dom";
import cx from "classnames";

// custom
import Styled from "./styled";

const Modal = ({ isActive, onClose, children, ...props }) => {
  return ReactDOM.createPortal(
    <Styled
      className={cx(
        "absolute left-0 top-0 h-screen w-screen flex items-center justify-center",
        {
          "is-hidden": !isActive,
          "is-showed": isActive,
        }
      )}
      onClick={onClose}
    >
      {children}
    </Styled>,
    document.getElementById("modal-root")
  );
};

export default Modal;
