import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST =
  "PUT_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST";
export const PUT_PAYROLL_EMPLOYEE_DEDUCTION_SUCCESS =
  "PUT_PAYROLL_EMPLOYEE_DEDUCTION_SUCCESS";
export const PUT_PAYROLL_EMPLOYEE_DEDUCTION_ERROR =
  "PUT_PAYROLL_EMPLOYEE_DEDUCTION_ERROR";

//* ACTIONS ------------------------------------------------
export const putPayrollEmployeeDeductionRequest = (payload = {}, callback) => ({
  type: PUT_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST,
  payload,
  callback,
});

export const putPayrollEmployeeDeductionSuccess = () => ({
  type: PUT_PAYROLL_EMPLOYEE_DEDUCTION_SUCCESS,
});

export const putPayrollEmployeeDeductionError = (error) => ({
  type: PUT_PAYROLL_EMPLOYEE_DEDUCTION_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putPayrollEmployeeDeductionServiceRequest = async ({
  deductionId,
  ...data
}) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/employee/deductions/${deductionId}`, options);
};
