import {
  postPersonnelActionsDefault,
  postPersonnelActionsRequest,
  postPersonnelActionsSuccess,
  postPersonnelActionsError,
  postPersonnelActionsServiceRequest
} from "../../store/personnelActions/actions/postPersonnelActions";

export const postPersonnelActions = (formData) => async (dispatch, getState) => {
  dispatch(postPersonnelActionsRequest());
  try {
    const response = await postPersonnelActionsServiceRequest(formData);
    dispatch(postPersonnelActionsSuccess(response.data));
  } catch (error) {
    dispatch(postPersonnelActionsError(error))
  }
  setTimeout(() => {
    dispatch(postPersonnelActionsDefault())
  }, 1500);
  return Promise.resolve();
};
