//Get Data
import {
  GET_VACATIONS_CURRENT_PERIOD_REQUEST,
  GET_VACATIONS_CURRENT_PERIOD_SUCCESS,
  GET_VACATIONS_CURRENT_PERIOD_ERROR,
} from "../../actions/vacations/getVacationsCurrentPeriod";

//Get Data all
import {
  GET_VACATIONS_ENJOY_REQUEST,
  GET_VACATIONS_ENJOY_SUCCESS,
  GET_VACATIONS_ENJOY_ERROR,
} from "../../actions/vacations/getVacationsEnjoy";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  getVacationsCurrentPeriod: {
    loading: false,
    error: false,
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  getVacationsEnjoy: {
    loading: false,
    error: false,
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_VACATIONS_CURRENT_PERIOD_REQUEST:
      return {
        ...state,
        getVacationsCurrentPeriod: {
          error: false,
          loading: true,
        }
      };
    case GET_VACATIONS_CURRENT_PERIOD_SUCCESS:
      return {
        ...state,
        getVacationsCurrentPeriod: {
          ...action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_VACATIONS_CURRENT_PERIOD_ERROR:
      return {
        ...state,
        getVacationsCurrentPeriod: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -----------------------------------------------------------
    case GET_VACATIONS_ENJOY_REQUEST:
      return {
        ...state,
        getVacationsEnjoy: {
          error: false,
          loading: true,
        }
      };
    case GET_VACATIONS_ENJOY_SUCCESS:
      return {
        ...state,
        getVacationsEnjoy: {
          ...action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_VACATIONS_ENJOY_ERROR:
      return {
        ...state,
        getVacationsEnjoy: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -----------------------------------------------------------
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
