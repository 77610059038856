import { createSelector } from "reselect";

const payrollEmployees = (state) => state.payroll.payrollEmployees;

export const loading = createSelector(
  payrollEmployees,
  (state) => state.loading
);

export const loadingAction = createSelector(
  payrollEmployees,
  (state) => state.loadingAction
);

export const data = createSelector(payrollEmployees, (state) => state.data);

export const pagination = createSelector(
  payrollEmployees,
  (state) => state.pagination
);
