//* ACTIONTYPES --------------------------------------------
export const ROUTES = "ROUTES";

//* ACTIONS CREATORS ---------------------------------------
export const routes = (data) => ({
  type: ROUTES,
  payload: data,
});

//* ACTIONS -------------------------------------------------
export const setRoutes = (path) => (dispatch, getState) => {
  const lastRoutes = getState().routes.routes
  dispatch(routes([path, ...lastRoutes]));
};


