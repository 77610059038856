//All Concepts
import {
  GET_CONCEPTS_REQUEST,
  GET_CONCEPTS_SUCCESS,
  GET_CONCEPTS_ERROR,
} from "../../actions/concepts/getConcepts";

//Concept
import {
  GET_CONCEPT_REQUEST,
  GET_CONCEPT_SUCCESS,
  GET_CONCEPT_ERROR,
} from "../../actions/concepts/getConcept";

//Update Concept
import {
  PUT_CONCEPT_REQUEST,
  PUT_CONCEPT_SUCCESS,
  PUT_CONCEPT_ERROR,
} from "../../actions/concepts/putConcept";

//Create Concept
import {
  POST_CONCEPT_REQUEST,
  POST_CONCEPT_SUCCESS,
  POST_CONCEPT_ERROR,
} from "../../actions/concepts/postConcept";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONCEPTS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_CONCEPTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_CONCEPTS_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case GET_CONCEPT_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_CONCEPT_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loading: false,
      };
    case GET_CONCEPT_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case PUT_CONCEPT_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_CONCEPT_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_CONCEPT_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_CONCEPT_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_CONCEPT_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_CONCEPT_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
