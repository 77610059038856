import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_PAYROLL_REQUEST = "PUT_PAYROLL_REQUEST";
export const PUT_PAYROLL_SUCCESS = "PUT_PAYROLL_SUCCESS";
export const PUT_PAYROLL_ERROR = "PUT_PAYROLL_ERROR";

//* ACTIONS ------------------------------------------------
export const putPayrollRequest = (payload = {}, callback) => ({
  type: PUT_PAYROLL_REQUEST,
  payload,
  callback,
});

export const putPayrollSuccess = () => ({
  type: PUT_PAYROLL_SUCCESS,
});

export const putPayrollError = (error) => ({
  type: PUT_PAYROLL_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putPayrollServiceRequest = async ({ payrollId, ...data }) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/payrolls/${payrollId}`, options);
};
