import {
  postLegalISRDefault,
  postLegalISRRequest,
  postLegalISRSuccess,
  postLegalISRError,
  postLegalISRServiceRequest
} from "../../../store/legal/actions/isr/postSnapshotISR";

export const postLegalSnapshotISR = (formData) => async (dispatch, getState) => {
  dispatch(postLegalISRRequest());
  try {
    const response = await postLegalISRServiceRequest(formData);
    dispatch(postLegalISRSuccess(response.data));
    
  } catch (error) {
    dispatch(postLegalISRError(error.response || error))
  }
  setTimeout(() => {
    dispatch(postLegalISRDefault())
  }, 1500);
  return Promise.resolve();
};
