import request, { Methods, withToken } from "../../../../utils/request";

//* ACTIONTYPES --------------------------------------------
export const GET_ALL_DASHBOARD_REQUEST = "GET_ALL_DASHBOARD_REQUEST";
export const GET_ALL_DASHBOARD_SUCCESS = "GET_ALL_DASHBOARD_SUCCESS";
export const GET_ALL_DASHBOARD_ERROR = "GET_ALL_DASHBOARD_ERROR";

//* ACTIONS ------------------------------------------------
export const getAllDashboardRequest = (payload = {}, callback) => ({
  type: GET_ALL_DASHBOARD_REQUEST,
  payload,
  callback,
});

export const getAllDashboardSuccess = (data) => ({
  type: GET_ALL_DASHBOARD_SUCCESS,
  payload: data,
});

export const getAllDashboardError = (error) => ({
  type: GET_ALL_DASHBOARD_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getAllDashboardServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
  });

  return request(`/schedules/fasani`, options);
};
