import {
  getSnapshotRequest,
  getSnapshotSuccess,
  getSnapshotError,
  getSnapshotServiceRequest,
} from "../../../store/payroll/actions/periods/getSnapshot";


export const getFetchPayrollsPeriodSnapshot = (formData) => async (dispatch, getState) => {
  dispatch(getSnapshotRequest());
  try {
    const response = await getSnapshotServiceRequest(formData);
    dispatch(getSnapshotSuccess(response.data));
  } catch (error) {
    dispatch(getSnapshotError(error))
  }
  return Promise.resolve();
};;
