import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  data,
  pagination,
} from "../../../store/benefits/selectors/benefits";

const Benefits = lazy(() => import("./Benefits"));

const mapStateToProps = (state) => ({
  isLoading: loading(state),
  benefits: data(state),
  pagination: pagination(state),
});

export default connect(mapStateToProps)(Benefits);
