import request, { Methods, withToken } from "../../../utils/request";
import { isEmpty } from "lodash"

//* ACTIONTYPES --------------------------------------------
export const SET_USER_COMPANY_REQUEST = "SET_USER_COMPANY_REQUEST";
export const SET_USER_COMPANY_SUCCESS = "SET_USER_COMPANY_SUCCESS";
export const SET_USER_COMPANY_ERROR = "SET_USER_COMPANY_ERROR";

//* ACTIONS ------------------------------------------------
export const setUserCompanyRequest = (payload, callback) => ({
  type: SET_USER_COMPANY_REQUEST,
  payload,
  callback,
});

export const setUserCompanySuccess = (data) => {
  if (!isEmpty(data.currentCompany)) {
    return ({
      type: SET_USER_COMPANY_SUCCESS,
      payload: data,
    })
  } else {
    return ({})
  }
};

export const setUserCompanyError = (error) => ({
  type: SET_USER_COMPANY_ERROR,
  payload: error,
});


//* REQUEST SERVICE ---------------------------------------
export const getCompaniesPermited = async (id, token, idCompany) => {
  const options = await withToken({
    method: Methods.GET,
    headers: {
      Authorization: `Bearer ${token}`,
      CompanyId: idCompany
    },
  });

  return request(`/companies/user/${id}`, options);
};
