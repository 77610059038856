import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_BENEFITS_REQUEST = "GET_BENEFITS_REQUEST";
export const GET_BENEFITS_SUCCESS = "GET_BENEFITS_SUCCESS";
export const GET_BENEFITS_ERROR = "GET_BENEFITS_ERROR";

//* ACTIONS ------------------------------------------------
export const getBenefitsRequest = (payload = {}, callback) => ({
  type: GET_BENEFITS_REQUEST,
  payload,
  callback,
});

export const getBenefitsSuccess = (data) => ({
  type: GET_BENEFITS_SUCCESS,
  payload: data,
});

export const getBenefitsError = (error) => ({
  type: GET_BENEFITS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getBenefitsServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/benefits`, options);
};
