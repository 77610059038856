import React from "react";
import { Route, Switch } from "react-router-dom";

import LineBusiness from "../../../modules/Administration/LineBusiness";
import NewLineBusiness from "../../../modules/Administration/LineBusiness/new";
import ShowLineBusiness from "../../../modules/Administration/LineBusiness/show";

export default () => {
  return (
    <Switch>
      <Route
        exact
        path="/administration/line-business"
        component={LineBusiness}
      />
      <Route
        exact
        path="/administration/line-business/new"
        component={NewLineBusiness}
      />
      <Route
        exact
        path="/administration/line-business/:id"
        component={ShowLineBusiness}
      />
    </Switch>
  );
};
