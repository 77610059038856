import React from "react";
import { Route, Switch } from "react-router-dom";

import ConceptCategories from "../../../modules/Administration/ConceptCategories";
import NewConceptCategory from "../../../modules/Administration/ConceptCategories/new";
import ShowConceptCategory from "../../../modules/Administration/ConceptCategories/show";

export default () => {
  return (
    <Switch>
      <Route
        exact
        path="/administration/concept-categories"
        component={ConceptCategories}
      />
      <Route
        exact
        path="/administration/concept-categories/new"
        component={NewConceptCategory}
      />
      <Route
        exact
        path="/administration/concept-categories/:id"
        component={ShowConceptCategory}
      />
    </Switch>
  );
};
