import {
  postLegalAFPRequest,
  postLegalAFPSuccess,
  postLegalAFPError,
  postLegalAFPServiceRequest
} from "../../../store/legal/actions/afp/postSnapshotAFP";

export const postLegalAFP = (formData) => async (dispatch, getState) => {
  dispatch(postLegalAFPRequest());
  try {
    const response = await postLegalAFPServiceRequest(formData);
    dispatch(postLegalAFPSuccess(response.data));
  } catch (error) {
    dispatch(postLegalAFPError(error.response || error))
  }
  return Promise.resolve();
};
