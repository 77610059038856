import React from "react";
import { FaUserCheck } from "react-icons/fa";

const NotFoundPage = () => {
  return (
    <section className="w-screen h-screen flex items-center justify-center">
      <FaUserCheck size="3rem" className="mr-4" />
      <h1>Revalidando tus credenciales...</h1>
    </section>
  );
};

export default NotFoundPage;
