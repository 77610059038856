import { lazy } from "react";
import { connect } from "react-redux";

import { getFetchUsers } from "../../../hooks/Security/Users/getFetchUsers";


const Users = lazy(() => import("./Users"));


const mapStateToProps = (state) => {
  return ({
    isLoading: state.security.user.loading,
    users: state.security.user.data,
    pagination: state.security.user.pagination,
  })
};

const mapDispatchToProps = dispatch => ({
  getFetchUsers: (formData) => dispatch(getFetchUsers(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
