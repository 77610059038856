import {
  getDeductionsHistoryRequest,
  getDeductionsHistorySuccess,
  getDeductionsHistoryError,
  getDeductionsHistoryServiceRequest,
} from "../../../store/deductions/actions/history/getDeductionsHistory";

export const getFetchDeductionsHistory = (formData) => async (dispatch, getState) => {
  dispatch(getDeductionsHistoryRequest());
  try {
    const response = await getDeductionsHistoryServiceRequest(formData);
    dispatch(getDeductionsHistorySuccess(response.data));
  } catch (error) {
    dispatch(getDeductionsHistoryError(error))
  }
  return Promise.resolve();
};

