import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_DOCUMENT_REQUEST = "POST_DOCUMENT_REQUEST";
export const POST_DOCUMENT_SUCCESS = "POST_DOCUMENT_SUCCESS";
export const POST_DOCUMENT_ERROR = "POST_DOCUMENT_ERROR";

//* ACTIONS ------------------------------------------------
export const postDocumentRequest = (payload = {}, callback) => ({
  type: POST_DOCUMENT_REQUEST,
  payload,
  callback,
});

export const postDocumentSuccess = () => ({
  type: POST_DOCUMENT_SUCCESS,
});

export const postDocumentError = (error) => ({
  type: POST_DOCUMENT_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postDocumentServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request("/documents", options);
};
