import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage, getIn } from "formik";
import cx from "classnames";
import Skeleton from "react-loading-skeleton";

import Styled from "./styled";
import { datePickerMaxDate } from "../../../utils/constants";
registerLocale("es", es);

export default ({
  field = { name: "" },
  form = { touched: false, errors: false },
  iconRight = null,
  isTextArea = false,
  rows = 3,
  selected,
  onChange = () => {},
  containerClass,
  loading,
  ...props
}) => {
  const { touched, errors } = form;
  const { label } = props;
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);

  return (
    <Styled
      className={cx("relative", {
        [containerClass]: containerClass,
      })}
    >
      <label
        htmlFor={field.name}
        className="block text-gray-700 text-sm font-bold mb-2 ml-4"
      >
        {loading ? <Skeleton width="50%" /> : label}
      </label>

      {loading ? (
        <Skeleton height="3rem" />
      ) : (
        <DatePicker
          showMonthDropdown
          showYearDropdown
          peekNextMonth
          dropdownMode="select"
          locale="es"
          maxDate={datePickerMaxDate}
          wrapperClassName="w-full"
          autoComplete="off"
          type="text"
          id={field.name}
          className={cx(
            "appearance-none border focus:border-blue-600 bg-transparent w-full text-gray-700 py-3 px-2 leading-tight focus:outline-none truncate",
            {
              "border-red-700": touch && error,
              "border-green-300": touch && !error,
              "border-gray-400": !touch && !error,
            }
          )}
          dateFormat="dd/MM/yyyy"
          {...field}
          {...props}
          selected={selected}
          onChange={onChange}
        />
      )}
      <ErrorMessage
        name={field.name}
        render={() => {
          return touch && error ? (
            <p className="text-xs text-red-700 absolute">{error}</p>
          ) : null;
        }}
      />
    </Styled>
  );
};
