import React from "react";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";

//Customs
import PrivateRoute from "../PrivateRoute";

//Selectors
import { isLogged } from "../../store/auth/selectors";

//* ROUTES *//
//* BENEFITS ADMIN
import AdministrationRoutes from "./Administration";
import HistoryRoutes from "./History";

const BenefitsRoutes = ({ isLogged }) => {
  return (
    <Switch>
      <PrivateRoute
        path="/benefits/administration"
        component={AdministrationRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/benefits/historical"
        component={HistoryRoutes}
        isAuthenticated={isLogged}
      />
      {/* <Redirect to="/administration/positions" /> */}
      <Redirect to="/" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isLogged: isLogged(state),
});

export default connect(mapStateToProps)(BenefitsRoutes);
