import {
  getLegalObservationCodeAFPRequest,
  getLegalObservationCodeAFPSuccess,
  getLegalObservationCodeAFPError,
  getLegalObservationCodeAFPServiceRequest,
} from "../../../store/legal/actions/afp/getObservationCodeAFP";

export const  getLegalObservationCodeAFP = () => async (dispatch, getState) => {
  dispatch(getLegalObservationCodeAFPRequest());
  try {
    const response = await getLegalObservationCodeAFPServiceRequest();
    dispatch(getLegalObservationCodeAFPSuccess(response.data));
  } catch (error) {
    dispatch(getLegalObservationCodeAFPError(error))
  }
  return Promise.resolve();
};
