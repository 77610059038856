import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  data,
} from "../../../../store/administration/selectors/concept_categories";

const NewConceptCategory = lazy(() => import("./NewConceptCategory"));

const mapStateToProps = (state) => ({
  loading: loading(state),
  conceptCategories: data(state),
});

export default connect(mapStateToProps)(NewConceptCategory);
