import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Concept Categories
import {
  // action types
  GET_CONCEPT_CATEGORIES_REQUEST,
  // requests
  getConceptCategoriesServiceRequest,
  // actions
  getConceptCategoriesSuccess,
  getConceptCategoriesError,
} from "../../actions/concept_categories/getConceptCategories";

//* Concept Category
import {
  // action types
  GET_CONCEPT_CATEGORY_REQUEST,
  // requests
  getConceptCategoryServiceRequest,
  // actions
  getConceptCategorySuccess,
  getConceptCategoryError,
} from "../../actions/concept_categories/getConceptCategory";

//* Update Concept Category
import {
  // action types
  PUT_CONCEPT_CATEGORY_REQUEST,
  // requests
  putConceptCategoryServiceRequest,
  // actions
  putConceptCategorySuccess,
  putConceptCategoryError,
} from "../../actions/concept_categories/putConceptCategory";

//* Create Concept Category
import {
  // action types
  POST_CONCEPT_CATEGORY_REQUEST,
  // requests
  postConceptCategoryServiceRequest,
  // actions
  postConceptCategorySuccess,
  postConceptCategoryError,
} from "../../actions/concept_categories/postConceptCategory";

function* getConceptCategories(action) {
  try {
    const result = yield call(
      getConceptCategoriesServiceRequest,
      action.payload
    );
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getConceptCategoriesSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getConceptCategoriesError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getConceptCategory(action) {
  try {
    const result = yield call(getConceptCategoryServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getConceptCategorySuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getConceptCategoryError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putConceptCategory(action) {
  try {
    yield call(putConceptCategoryServiceRequest, action.payload);
    yield put(putConceptCategorySuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putConceptCategoryError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postConceptCategory(action) {
  try {
    yield call(postConceptCategoryServiceRequest, action.payload);
    yield put(postConceptCategorySuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postConceptCategoryError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* conceptCategoriesSaga() {
  yield takeLatest(GET_CONCEPT_CATEGORIES_REQUEST, getConceptCategories);
  yield takeLatest(GET_CONCEPT_CATEGORY_REQUEST, getConceptCategory);
  yield takeLatest(PUT_CONCEPT_CATEGORY_REQUEST, putConceptCategory);
  yield takeLatest(POST_CONCEPT_CATEGORY_REQUEST, postConceptCategory);
}

export default conceptCategoriesSaga;
