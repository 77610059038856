import request, { Methods, withToken } from "../../../../utils/request";

//* ACTIONTYPES --------------------------------------------
export const POST_SYNC_SCHEDULE_DEFAULT = "POST_SYNC_SCHEDULE_DEFAULT";
export const POST_SYNC_SCHEDULE_REQUEST = "POST_SYNC_SCHEDULE_REQUEST";
export const POST_SYNC_SCHEDULE_SUCCESS = "POST_SYNC_SCHEDULE_SUCCESS";
export const POST_SYNC_SCHEDULE_ERROR = "POST_SYNC_SCHEDULE_ERROR";

//* ACTIONS ------------------------------------------------
export const postSyncScheduleDefault = () => ({
  type: POST_SYNC_SCHEDULE_DEFAULT,
});

export const postSyncScheduleRequest = (payload = {}, callback) => ({
  type: POST_SYNC_SCHEDULE_REQUEST,
  payload,
  callback,
});

export const postSyncScheduleSuccess = () => ({
  type: POST_SYNC_SCHEDULE_SUCCESS,
});

export const postSyncScheduleError = (error) => ({
  type: POST_SYNC_SCHEDULE_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postSyncScheduleServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    data,
  });

  return request("/schedules/fasani/sync", options);
};
