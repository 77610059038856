import React from "react";
import { Route, Switch } from "react-router-dom";

import Ubications from "../../../modules/Administration/Ubications";
import NewUbication from "../../../modules/Administration/Ubications/new";
import ShowUbication from "../../../modules/Administration/Ubications/show";

export default () => {
  return (
    <Switch>
      <Route exact path="/administration/ubications" component={Ubications} />
      <Route
        exact
        path="/administration/ubications/new"
        component={NewUbication}
      />
      <Route
        exact
        path="/administration/ubications/:id"
        component={ShowUbication}
      />
    </Switch>
  );
};
