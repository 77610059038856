import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_CONCEPT_TYPE_REQUEST = "GET_CONCEPT_TYPE_REQUEST";
export const GET_CONCEPT_TYPE_SUCCESS = "GET_CONCEPT_TYPE_SUCCESS";
export const GET_CONCEPT_TYPE_ERROR = "GET_CONCEPT_TYPE_ERROR";

//* ACTIONS ------------------------------------------------
export const getConceptTypeRequest = (payload = {}, callback) => ({
  type: GET_CONCEPT_TYPE_REQUEST,
  payload,
  callback,
});

export const getConceptTypeSuccess = (data) => ({
  type: GET_CONCEPT_TYPE_SUCCESS,
  payload: data,
});

export const getConceptTypeError = (error) => ({
  type: GET_CONCEPT_TYPE_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getConceptTypeServiceRequest = async (conceptTypeId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/concept_types/${conceptTypeId}`, options);
};
