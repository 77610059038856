import { assign } from "lodash";

import request, { Methods } from "../../../utils/request";

//* ACTIONTYPES --------------------------------------------
export const POST_FORGOT_PASSWORD_REQUEST = "POST_FORGOT_PASSWORD_REQUEST";
export const POST_FORGOT_PASSWORD_SUCCESS = "POST_FORGOT_PASSWORD_SUCCESS";
export const POST_FORGOT_PASSWORD_ERROR = "POST_FORGOT_PASSWORD_ERROR";

//* ACTIONS ------------------------------------------------
export const forgotPasswordRequest = (email = {}, callback) => ({
  type: POST_FORGOT_PASSWORD_REQUEST,
  payload: email,
  callback,
});

export const forgotPasswordSuccess = (data) => ({
  type: POST_FORGOT_PASSWORD_SUCCESS,
  payload: data,
});

export const forgotPasswordError = (error) => ({
  type: POST_FORGOT_PASSWORD_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postforgotPasswordRequest = async (email) => {
  const options = {
    data: assign({}, email),
    method: Methods.POST,
  };

  return request(`/oauth/password`, options);
};
