import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_HEADER_PERIOD_HISTORY_REQUEST = "GET_HEADER_PERIOD_HISTORY_REQUEST";
export const GET_HEADER_PERIOD_HISTORY_SUCCESS = "GET_HEADER_PERIOD_HISTORY_SUCCESS";
export const GET_HEADER_PERIOD_HISTORY_ERROR = "GET_HEADER_PERIOD_HISTORY_ERROR";

//* ACTIONS ------------------------------------------------
export const getHeaderPeriodHistoryRequest = (payload = {}, callback) => ({
  type: GET_HEADER_PERIOD_HISTORY_REQUEST,
  payload,
  callback,
});

export const getHeaderPeriodHistorySuccess = (data) => ({
  type: GET_HEADER_PERIOD_HISTORY_SUCCESS,
  payload: data,
});

export const getHeaderPeriodHistoryError = (error) => ({
  type: GET_HEADER_PERIOD_HISTORY_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getHeaderPeriodHistoryServiceRequest = async ({ payrollId, periodId }) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/payrolls/${payrollId}/periods/${periodId}/history`, options);
};
