import { lazy } from "react";
import { connect } from "react-redux";

import {
  loading,
  loadingAction,
  detail,
} from "../../../../store/deductions/selectors/deductions";
import {
  loading as loadingConcepts,
  data as concepts,
} from "../../../../store/payroll/selectors/concepts";
import {
  loading as loadingPayrolls,
  data as payrolls,
} from "../../../../store/payroll/selectors/payrolls";
import {
  loading as loadingEmployees,
  data as employees,
} from "../../../../store/payroll/selectors/employees";
import {
  data as financialEntities,
  loading as loadingFinancialEntities,
} from "../../../../store/administration/selectors/financial_entities";

const ShowDeduction = lazy(() => import("./ShowDeduction"));

const mapStateToProps = (state) => ({
  loadingAction: loadingAction(state),
  loading: loading(state),
  detail: detail(state),
  loadingConcepts: loadingConcepts(state),
  concepts: concepts(state),
  loadingPayrolls: loadingPayrolls(state),
  payrolls: payrolls(state),
  loadingEmployees: loadingEmployees(state),
  employees: employees(state),
  loadingFinancialEntities: loadingFinancialEntities(state),
  financialEntities: financialEntities(state),
});

export default connect(mapStateToProps)(ShowDeduction);
