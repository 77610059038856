import { createSelector } from "reselect";

const costcenters = (state) => state.administration.costcenters;

export const loading = createSelector(costcenters, (state) => state.loading);

export const loadingAction = createSelector(
  costcenters,
  (state) => state.loadingAction
);

export const data = createSelector(costcenters, (state) => state.data);

export const pagination = createSelector(
  costcenters,
  (state) => state.pagination
);

export const detail = createSelector(costcenters, (state) => state.detail);
