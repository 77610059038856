import { createSelector } from "reselect";

const positions = (state) => state.administration.positions;

export const loading = createSelector(positions, (state) => state.loading);

export const loadingAction = createSelector(
  positions,
  (state) => state.loadingAction
);

export const data = createSelector(positions, (state) => state.data);

export const pagination = createSelector(
  positions,
  (state) => state.pagination
);

export const detail = createSelector(positions, (state) => state.detail);
