import { lazy } from "react";
import { connect } from "react-redux";
import {
  loadingAction,
  loading,
  detail,
} from "../../../../store/administration/selectors/ubications";

const ShowUbication = lazy(() => import("./ShowUbication"));

const mapStateToProps = (state) => ({
  loadingAction: loadingAction(state),
  loading: loading(state),
  ubication: detail(state),
});

export default connect(mapStateToProps)(ShowUbication);
