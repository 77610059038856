import {
  putLegalEmployeeSPURequest,
  putLegalEmployeeSPUSuccess,
  putLegalEmployeeSPUError,
  putLegalEmployeeSPUServiceRequest
} from "../../../store/legal/actions/spu/putEmployeSPU";

export const putLegalEmployeeSPU = (formData) => async (dispatch, getState) => {
  dispatch(putLegalEmployeeSPURequest());
  try {
    const response = await putLegalEmployeeSPUServiceRequest(formData);
    dispatch(putLegalEmployeeSPUSuccess(response?.data));
  } catch (error) {
    dispatch(putLegalEmployeeSPUError(error.response || error))
  }
  return Promise.resolve();
};
