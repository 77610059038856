import {
  getModulesRequest,
  getModulesSuccess,
  getModulesError,
  getModulesServiceRequest,
} from "../../../store/security/actions/users/getModules";

export const getFetchModules = (formData) => async (dispatch, getState) => {
  dispatch(getModulesRequest());
  try {
    const response = await getModulesServiceRequest(formData);
    dispatch(getModulesSuccess(response.data));
  } catch (error) {
    dispatch(getModulesError(error))
  }
  return Promise.resolve();
};

