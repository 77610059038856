import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* Period
import {
  // action types
  GET_PERIOD_REQUEST,
  // requests
  getPeriodServiceRequest,
  // actions
  getPeriodSuccess,
  getPeriodError,
} from "../../actions/periods/getPeriod";

//* Snapshot
import {
  // action types
  GET_SNAPSHOT_REQUEST,
  // requests
  getSnapshotServiceRequest,
  // actions
  getSnapshotSuccess,
  getSnapshotError,
} from "../../actions/periods/getSnapshot";

//* Snapshot EXPORT
import {
  // action types
  GET_SNAPSHOT_EXPORT_REQUEST,
  // requests
  getSnapshotExportServiceRequest,
  // actions
  getSnapshotExportSuccess,
  getSnapshotExportError,
} from "../../actions/periods/getSnapshotExport";

//* Initialize Period
import {
  // action types
  PUT_INITIALIZE_PERIOD_REQUEST,
  // requests
  putInitializePeriodServiceRequest,
  // actions
  putInitializePeriodSuccess,
  putInitializePeriodError,
} from "../../actions/periods/putInitializePeriod";

//* Update Period
import {
  // action types
  PUT_UPDATE_PERIOD_REQUEST,
  // requests
  putUpdatePeriodServiceRequest,
  // actions
  putUpdatePeriodSuccess,
  putUpdatePeriodError,
} from "../../actions/periods/putUpdatePeriod";

//* Update Period
import {
  // action types
  PUT_CALCULATE_PERIOD_REQUEST,
  // requests
  putCalculatePeriodServiceRequest,
  // actions
  putCalculatePeriodSuccess,
  putCalculatePeriodError,
} from "../../actions/periods/putCalculatePeriod";

//* Approve Period
import {
  // action types
  PUT_APPROVE_PERIOD_REQUEST,
  // requests
  putApprovePeriodServiceRequest,
  // actions
  putApprovePeriodSuccess,
  putApprovePeriodError,
} from "../../actions/periods/putApprovePeriod";

//* Apply Period
import {
  // action types
  PUT_APPLY_PERIOD_REQUEST,
  // requests
  putApplyPeriodServiceRequest,
  // actions
  putApplyPeriodSuccess,
  putApplyPeriodError,
} from "../../actions/periods/putApplyPeriod";

//* Create Period
import {
  // action types
  POST_PERIOD_REQUEST,
  // requests
  postPeriodServiceRequest,
  // actions
  postPeriodSuccess,
  postPeriodError,
} from "../../actions/periods/postPeriod";

//* Create Period
import {
  // action types
  POST_CONCEPT_TO_PERIOD_REQUEST,
  // requests
  postConceptToPeriodServiceRequest,
  // actions
  postConceptToPeriodSuccess,
  postConceptToPeriodError,
} from "../../actions/periods/postConceptToPeriod";

function* getPeriod(action) {
  try {
    const result = yield call(getPeriodServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getPeriodSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getPeriodError(e.response));

    action.callback && action.callback(e.response);
  }
}
function* getSnapshot(action) {
  try {
    const result = yield call(getSnapshotServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getSnapshotSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getSnapshotError(e.response));

    action.callback && action.callback(e.response);
  }
}
function* getSnapshotExport(action) {
  try {
    const result = yield call(getSnapshotExportServiceRequest, action.payload);
    const data = get(result, "data.data", []);

    yield put(getSnapshotExportSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getSnapshotExportError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putInitializePeriod(action) {
  try {
    yield call(putInitializePeriodServiceRequest, action.payload);
    yield put(putInitializePeriodSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putInitializePeriodError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putUpdatePeriod(action) {
  try {
    yield call(putUpdatePeriodServiceRequest, action.payload);
    yield put(putUpdatePeriodSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putUpdatePeriodError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putCalculatePeriod(action) {
  try {
    const data = yield call(putCalculatePeriodServiceRequest, action.payload);
    yield put(putCalculatePeriodSuccess(null));

    action.callback && action.callback(null, data.data);
  } catch (e) {
    yield put(putCalculatePeriodError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putApprovePeriod(action) {
  try {
    yield call(putApprovePeriodServiceRequest, action.payload);
    yield put(putApprovePeriodSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putApprovePeriodError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putApplyPeriod(action) {
  try {
    yield call(putApplyPeriodServiceRequest, action.payload);
    yield put(putApplyPeriodSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putApplyPeriodError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postPeriod(action) {
  try {
    yield call(postPeriodServiceRequest, action.payload);
    yield put(postPeriodSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postPeriodError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postConceptToPeriod(action) {
  try {
    yield call(postConceptToPeriodServiceRequest, action.payload);
    yield put(postConceptToPeriodSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postConceptToPeriodError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* payrollPeriodsSaga() {
  yield takeLatest(GET_PERIOD_REQUEST, getPeriod);
  yield takeLatest(GET_SNAPSHOT_REQUEST, getSnapshot);
  yield takeLatest(GET_SNAPSHOT_EXPORT_REQUEST, getSnapshotExport);
  yield takeLatest(PUT_INITIALIZE_PERIOD_REQUEST, putInitializePeriod);
  yield takeLatest(PUT_UPDATE_PERIOD_REQUEST, putUpdatePeriod);
  yield takeLatest(PUT_CALCULATE_PERIOD_REQUEST, putCalculatePeriod);
  yield takeLatest(PUT_APPROVE_PERIOD_REQUEST, putApprovePeriod);
  yield takeLatest(PUT_APPLY_PERIOD_REQUEST, putApplyPeriod);
  yield takeLatest(POST_PERIOD_REQUEST, postPeriod);
  yield takeLatest(POST_CONCEPT_TO_PERIOD_REQUEST, postConceptToPeriod);
}

export default payrollPeriodsSaga;
