import {
  postLegalISSSRequest,
  postLegalISSSSuccess,
  postLegalISSSError,
  postLegalISSSServiceRequest
} from "../../../store/legal/actions/isss/postSnapshotISSS";

export const postLegalISSS = (formData) => async (dispatch, getState) => {
  dispatch(postLegalISSSRequest());
  try {
    const response = await postLegalISSSServiceRequest(formData);
    dispatch(postLegalISSSSuccess(response.data));
  } catch (error) {
    dispatch(postLegalISSSError(error.response || error))
  }
  return Promise.resolve();
};
