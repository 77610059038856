//All Financial Entities
import {
  GET_FINANCIAL_ENTITIES_REQUEST,
  GET_FINANCIAL_ENTITIES_SUCCESS,
  GET_FINANCIAL_ENTITIES_ERROR,
} from "../../actions/financial_entities/getFinancialEntities";

//Financial Entitie
import {
  GET_FINANCIAL_ENTITIE_REQUEST,
  GET_FINANCIAL_ENTITIE_SUCCESS,
  GET_FINANCIAL_ENTITIE_ERROR,
} from "../../actions/financial_entities/getFinancialEntitie";

//Update Financial Entitie
import {
  PUT_FINANCIAL_ENTITIE_REQUEST,
  PUT_FINANCIAL_ENTITIE_SUCCESS,
  PUT_FINANCIAL_ENTITIE_ERROR,
} from "../../actions/financial_entities/putFinancialEntitie";

//Create Financial Entitie
import {
  POST_FINANCIAL_ENTITIE_REQUEST,
  POST_FINANCIAL_ENTITIE_SUCCESS,
  POST_FINANCIAL_ENTITIE_ERROR,
} from "../../actions/financial_entities/postFinancialEntitie";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_FINANCIAL_ENTITIES_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_FINANCIAL_ENTITIES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_FINANCIAL_ENTITIES_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case GET_FINANCIAL_ENTITIE_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_FINANCIAL_ENTITIE_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loading: false,
      };
    case GET_FINANCIAL_ENTITIE_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case PUT_FINANCIAL_ENTITIE_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_FINANCIAL_ENTITIE_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_FINANCIAL_ENTITIE_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_FINANCIAL_ENTITIE_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_FINANCIAL_ENTITIE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_FINANCIAL_ENTITIE_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
