//Get Data
import {
  GET_LEGAL_ISR_REQUEST,
  GET_LEGAL_ISR_SUCCESS,
  GET_LEGAL_ISR_ERROR,
} from "../../actions/isr/getISR";

//Post Legal
import {
  POST_LEGAL_ISR_DEFAULT,
  POST_LEGAL_ISR_REQUEST,
  POST_LEGAL_ISR_SUCCESS,
  POST_LEGAL_ISR_ERROR,
} from "../../actions/isr/postSnapshotISR";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  getLegalISR: {
    loading: false,
    error: false,
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  postLegalSnapshotISR: {
    loading: false,
    error: false,
    data: [],
    success: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case GET_LEGAL_ISR_REQUEST:
      return {
        ...state,
        getLegalISR: {
          data: [],
          error: false,
          loading: true,
        }
      };
    case GET_LEGAL_ISR_SUCCESS:
      return {
        ...state,
        getLegalISR: {
          ...action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_LEGAL_ISR_ERROR:
      return {
        ...state,
        getLegalISR: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -----------------------------------------------------------
    case POST_LEGAL_ISR_DEFAULT:
      return {
        ...state,
        postLegalSnapshotISR: {
          ...state.postLegalSnapshotISR,
          error: false,
          loading: false,
          success: false,
        }
      };
    case POST_LEGAL_ISR_REQUEST:
      return {
        ...state,
        postLegalSnapshotISR: {
          data: [],
          error: false,
          loading: true,
          success: false,
        }
      };
    case POST_LEGAL_ISR_SUCCESS:
      return {
        ...state,
        postLegalSnapshotISR: {
          ...action.payload,
          error: false,
          loading: false,
          success: true,
        }
      };
    case POST_LEGAL_ISR_ERROR:
      return {
        ...state,
        postLegalSnapshotISR: {
          error: action.payload || action.payload.status || 401,
          loading: false,
          success: false,
        }
      };

    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
