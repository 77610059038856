import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_DOCUMENT_REQUEST = "GET_DOCUMENT_REQUEST";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_ERROR = "GET_DOCUMENT_ERROR";

//* ACTIONS ------------------------------------------------
export const getDocumentRequest = (payload = {}, callback) => ({
  type: GET_DOCUMENT_REQUEST,
  payload,
  callback,
});

export const getDocumentSuccess = (data) => ({
  type: GET_DOCUMENT_SUCCESS,
  payload: data,
});

export const getDocumentError = (error) => ({
  type: GET_DOCUMENT_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getDocumentServiceRequest = async (documentId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/documents/${documentId}`, options);
};
