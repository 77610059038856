import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_USER_REQUEST = "PUT_USER_REQUEST";
export const PUT_USER_SUCCESS = "PUT_USER_SUCCESS";
export const PUT_USER_ERROR = "PUT_USER_ERROR";

//* ACTIONS ------------------------------------------------
export const putUserRequest = (payload = {}, callback) => ({
  type: PUT_USER_REQUEST,
  payload,
  callback,
});

export const putUserSuccess = () => ({
  type: PUT_USER_SUCCESS,
});

export const putUserError = (error) => ({
  type: PUT_USER_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putUserServiceRequest = async ({ userId, ...data }) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/users/${userId}`, options);
};
