import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_PAYROLL_REPORT_PAYMENT_CUSC_REQUEST = "GET_PAYROLL_REPORT_PAYMENT_CUSC_REQUEST";
export const GET_PAYROLL_REPORT_PAYMENT_CUSC_SUCCESS = "GET_PAYROLL_REPORT_PAYMENT_CUSC_SUCCESS";
export const GET_PAYROLL_REPORT_PAYMENT_CUSC_ERROR = "GET_PAYROLL_REPORT_PAYMENT_CUSC_ERROR";

//* ACTIONS ------------------------------------------------
export const getPayrollReportPaymentCuscRequest = (payload = {}, callback) => ({
  type: GET_PAYROLL_REPORT_PAYMENT_CUSC_REQUEST,
  payload,
  callback,
});

export const getPayrollReportPaymentCuscSuccess = (data) => ({
  type: GET_PAYROLL_REPORT_PAYMENT_CUSC_SUCCESS,
  payload: data,
});

export const getPayrollReportPaymentCuscError = (error) => ({
  type: GET_PAYROLL_REPORT_PAYMENT_CUSC_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getPayrollReportPaymentCuscServiceRequest = async ({ periodId = 0, ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/payrolls/periods/${periodId}/payment/txtCuscatlan`, options);
};
