import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* employees
import {
  // action types
  GET_PAYROLL_EMPLOYEES_REQUEST,
  // requests
  getPayrollEmployeesServiceRequest,
  // actions
  getPayrollEmployeesSuccess,
  getPayrollEmployeesError,
} from "../../actions/employees/getEmployees";

//* add employee to payroll
import {
  // action types
  POST_PAYROLL_EMPLOYEE_REQUEST,
  // requests
  postPayrollEmployeeServiceRequest,
  // actions
  postPayrollEmployeeSuccess,
  postPayrollEmployeeError,
} from "../../actions/employees/postEmployee";

//* delete employee to payroll
import {
  // action types
  DELETE_PAYROLL_EMPLOYEE_REQUEST,
  // requests
  deletePayrollEmployeeServiceRequest,
  // actions
  deletePayrollEmployeeSuccess,
  deletePayrollEmployeeError,
} from "../../actions/employees/deleteEmployee";

function* getPayrollEmployees(action) {
  try {
    const result = yield call(
      getPayrollEmployeesServiceRequest,
      action.payload
    );
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getPayrollEmployeesSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getPayrollEmployeesError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* deletePayrollEmployee(action) {
  try {
    yield call(deletePayrollEmployeeServiceRequest, action.payload);
    yield put(deletePayrollEmployeeSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(deletePayrollEmployeeError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postPayrollEmployee(action) {
  try {
    yield call(postPayrollEmployeeServiceRequest, action.payload);
    yield put(postPayrollEmployeeSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postPayrollEmployeeError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* payrollEmployeesSaga() {
  yield takeLatest(GET_PAYROLL_EMPLOYEES_REQUEST, getPayrollEmployees);
  yield takeLatest(DELETE_PAYROLL_EMPLOYEE_REQUEST, deletePayrollEmployee);
  yield takeLatest(POST_PAYROLL_EMPLOYEE_REQUEST, postPayrollEmployee);
}

export default payrollEmployeesSaga;
