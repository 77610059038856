import React from "react";
import { Route, Switch } from "react-router-dom";

import Countries from "../../../modules/Administration/Countries";
import NewCountry from "../../../modules/Administration/Countries/new";
import ShowCountry from "../../../modules/Administration/Countries/show";

export default () => {
  return (
    <Switch>
      <Route exact path="/administration/countries" component={Countries} />
      <Route
        exact
        path="/administration/countries/new"
        component={NewCountry}
      />
      <Route
        exact
        path="/administration/countries/:id"
        component={ShowCountry}
      />
    </Switch>
  );
};
