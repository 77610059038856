import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_POSITION_REQUEST = "GET_POSITION_REQUEST";
export const GET_POSITION_SUCCESS = "GET_POSITION_SUCCESS";
export const GET_POSITION_ERROR = "GET_POSITION_ERROR";

//* ACTIONS ------------------------------------------------
export const getPositionRequest = (payload = {}, callback) => ({
  type: GET_POSITION_REQUEST,
  payload,
  callback,
});

export const getPositionSuccess = (data) => ({
  type: GET_POSITION_SUCCESS,
  payload: data,
});

export const getPositionError = (error) => ({
  type: GET_POSITION_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getPositionServiceRequest = async (positionId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/positions/${positionId}`, options);
};
