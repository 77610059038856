import { lazy } from "react";
import { connect } from "react-redux";

//Selectors
import { loading } from "../../../../store/administration/selectors/financial_entities";
import { currentUser } from "../../../../store/auth/selectors";

const NewFinancialEntitie = lazy(() => import("./NewFinancialEntitie"));

const mapStateToProps = (state) => ({
  loading: loading(state),
  currentUser: currentUser(state),
});

export default connect(mapStateToProps)(NewFinancialEntitie);
