import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_DEDUCTIONS_REQUEST = "GET_DEDUCTIONS_REQUEST";
export const GET_DEDUCTIONS_SUCCESS = "GET_DEDUCTIONS_SUCCESS";
export const GET_DEDUCTIONS_ERROR = "GET_DEDUCTIONS_ERROR";

//* ACTIONS ------------------------------------------------
export const getDeductionsRequest = (payload = {}, callback) => ({
  type: GET_DEDUCTIONS_REQUEST,
  payload,
  callback,
});

export const getDeductionsSuccess = (data) => ({
  type: GET_DEDUCTIONS_SUCCESS,
  payload: data,
});

export const getDeductionsError = (error) => ({
  type: GET_DEDUCTIONS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getDeductionsServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/deductions`, options);
};
