import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  data,
  pagination,
} from "../../../store/administration/selectors/positions";
import {
  loading as loadingAreas,
  data as areas,
} from "../../../store/administration/selectors/areas";

const Positions = lazy(() => import("./Positions"));

const mapStateToProps = (state) => ({
  isLoading: loading(state),
  positions: data(state),
  pagination: pagination(state),
  isLoadingAreas: loadingAreas(state),
  areas: areas(state),
});

export default connect(mapStateToProps)(Positions);
