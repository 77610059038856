import {
  putLegalEmployeeAFPRequest,
  putLegalEmployeeAFPSuccess,
  putLegalEmployeeAFPError,
  putLegalEmployeeAFPServiceRequest
} from "../../../store/legal/actions/afp/putEmployeAFP";

export const putLegalEmployeeAFP = (formData) => async (dispatch, getState) => {
  dispatch(putLegalEmployeeAFPRequest());
  try {
    const response = await putLegalEmployeeAFPServiceRequest(formData);
    dispatch(putLegalEmployeeAFPSuccess(response?.data));
  } catch (error) {
    dispatch(putLegalEmployeeAFPError(error.response || error))
  }
  return Promise.resolve();
};
