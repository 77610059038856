//All Positions
import {
  GET_POSITIONS_REQUEST,
  GET_POSITIONS_SUCCESS,
  GET_POSITIONS_ERROR,
} from "../../actions/positions/getPositions";

//Position
import {
  GET_POSITION_REQUEST,
  GET_POSITION_SUCCESS,
  GET_POSITION_ERROR,
} from "../../actions/positions/getPosition";

//Update Position
import {
  PUT_POSITION_REQUEST,
  PUT_POSITION_SUCCESS,
  PUT_POSITION_ERROR,
} from "../../actions/positions/putPosition";

//Create Position
import {
  POST_POSITION_REQUEST,
  POST_POSITION_SUCCESS,
  POST_POSITION_ERROR,
} from "../../actions/positions/postPosition";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Login
    case GET_POSITIONS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_POSITIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_POSITIONS_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case GET_POSITION_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_POSITION_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loading: false,
      };
    case GET_POSITION_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case PUT_POSITION_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_POSITION_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_POSITION_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_POSITION_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_POSITION_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_POSITION_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
