import React from "react";
import { Route, Switch } from "react-router-dom";

import Create from "../../../modules/PersonnelActions/Create";

export default () => {
  return (
    <Switch>
      <Route exact path="/personnel-actions/create" component={Create} />
    </Switch>
  );
};