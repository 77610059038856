import React from "react";
import { Route, Switch } from "react-router-dom";

import Companies from "../../../modules/Administration/Companies";
import NewCompany from "../../../modules/Administration/Companies/new";
import ShowCompany from "../../../modules/Administration/Companies/show";

export default () => {
  return (
    <Switch>
      <Route exact path="/administration/companies" component={Companies} />
      <Route
        exact
        path="/administration/companies/new"
        component={NewCompany}
      />
      <Route
        exact
        path="/administration/companies/:id"
        component={ShowCompany}
      />
    </Switch>
  );
};
