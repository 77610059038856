import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  loadingAction,
  data,
  pagination,
} from "../../../store/administration/selectors/employees";

const Employees = lazy(() => import("./Employees"));

const mapStateToProps = (state) => ({
  lastRoutes: state.routes.routes,
  isLoading: loading(state),
  loadingAction: loadingAction(state),
  employees: data(state),
  pagination: pagination(state),
  unsuscribeSuccess: state.administration.employees.unsuscribe.success,
  unsuscribeError: state.administration.employees.unsuscribe.error,
  unsuscribeLoading: state.administration.employees.unsuscribe.loading,
});

export default connect(mapStateToProps)(Employees);
