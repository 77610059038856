import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_PERIOD_REQUEST = "GET_PERIOD_REQUEST";
export const GET_PERIOD_SUCCESS = "GET_PERIOD_SUCCESS";
export const GET_PERIOD_ERROR = "GET_PERIOD_ERROR";

//* ACTIONS ------------------------------------------------
export const getPeriodRequest = (payload = {}, callback) => ({
  type: GET_PERIOD_REQUEST,
  payload,
  callback,
});

export const getPeriodSuccess = (data) => ({
  type: GET_PERIOD_SUCCESS,
  payload: data,
});

export const getPeriodError = (error) => ({
  type: GET_PERIOD_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getPeriodServiceRequest = async (payrollId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/payrolls/${payrollId}/periods/current`, options);
};
