import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_COUNTRY_REQUEST = "POST_COUNTRY_REQUEST";
export const POST_COUNTRY_SUCCESS = "POST_COUNTRY_SUCCESS";
export const POST_COUNTRY_ERROR = "POST_COUNTRY_ERROR";

//* ACTIONS ------------------------------------------------
export const postCountryRequest = (payload = {}, callback) => ({
  type: POST_COUNTRY_REQUEST,
  payload,
  callback,
});

export const postCountrySuccess = () => ({
  type: POST_COUNTRY_SUCCESS,
});

export const postCountryError = (error) => ({
  type: POST_COUNTRY_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postCountryServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request("/countries", options);
};
