// import React from "react";

// const LogOutPage = () => {
//   return <div>Log Out page</div>;
// };

// export default LogOutPage;

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as storage from "localforage";

// actions
import { logout } from "../../../store/auth/actions/logout";

// class LogOutPage extends React.PureComponent {
//   componentDidMount() {
//     const { dispatch, history } = this.props;

//     dispatch(logout());
//     storage.clear();

//     return history.push("/log-in");
//   }

//   render() {
//     return null;
//   }
// }
const LogOutPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(logout());
    storage.clear();
    return history.push("/log-in");
  }, [dispatch, history]);

  return null;
};

export default LogOutPage;
