import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_USER_REQUEST = "POST_USER_REQUEST";
export const POST_USER_SUCCESS = "POST_USER_SUCCESS";
export const POST_USER_ERROR = "POST_USER_ERROR";

//* ACTIONS ------------------------------------------------
export const postUserRequest = (payload = {}, callback) => ({
  type: POST_USER_REQUEST,
  payload,
  callback,
});

export const postUserSuccess = () => ({
  type: POST_USER_SUCCESS,
});

export const postUserError = (error) => ({
  type: POST_USER_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postUserServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request("/users", options);
};
