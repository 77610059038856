import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_PAYROLL_REPORT_PAYMENT_BAC_REQUEST = "GET_PAYROLL_REPORT_PAYMENT_BAC_REQUEST";
export const GET_PAYROLL_REPORT_PAYMENT_BAC_SUCCESS = "GET_PAYROLL_REPORT_PAYMENT_BAC_SUCCESS";
export const GET_PAYROLL_REPORT_PAYMENT_BAC_ERROR = "GET_PAYROLL_REPORT_PAYMENT_BAC_ERROR";

//* ACTIONS ------------------------------------------------
export const getPayrollReportPaymentBACRequest = (payload = {}, callback) => ({
  type: GET_PAYROLL_REPORT_PAYMENT_BAC_REQUEST,
  payload,
  callback,
});

export const getPayrollReportPaymentBACSuccess = (data) => ({
  type: GET_PAYROLL_REPORT_PAYMENT_BAC_SUCCESS,
  payload: data,
});

export const getPayrollReportPaymentBACError = (error) => ({
  type: GET_PAYROLL_REPORT_PAYMENT_BAC_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getPayrollReportPaymentBACServiceRequest = async ({ periodId = 0, correlative = 0, ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/payrolls/periods/${periodId}/payment/txtBac/correlative/${correlative}`, options);
};
