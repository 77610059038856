import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST =
  "POST_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST";
export const POST_PAYROLL_EMPLOYEE_DEDUCTION_SUCCESS =
  "POST_PAYROLL_EMPLOYEE_DEDUCTION_SUCCESS";
export const POST_PAYROLL_EMPLOYEE_DEDUCTION_ERROR =
  "POST_PAYROLL_EMPLOYEE_DEDUCTION_ERROR";

//* ACTIONS ------------------------------------------------
export const postPayrollEmployeeDeductionRequest = (
  payload = {},
  callback
) => ({
  type: POST_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST,
  payload,
  callback,
});

export const postPayrollEmployeeDeductionSuccess = () => ({
  type: POST_PAYROLL_EMPLOYEE_DEDUCTION_SUCCESS,
});

export const postPayrollEmployeeDeductionError = (error) => ({
  type: POST_PAYROLL_EMPLOYEE_DEDUCTION_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postPayrollEmployeeDeductionServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request("/employee/deductions", options);
};
