import styled from "styled-components";

import { catskillWhite, persianRed, tuna } from "../../../../utils/colors";

const Styled = styled.div`
  input {
    border-radius: 0.3rem;
    &:disabled {
      background: ${catskillWhite};
      cursor: not-allowed;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${persianRed};
    outline: none;
    &:hover {
      background-color: ${tuna};
    }
  }
`;

export default Styled;
