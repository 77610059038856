import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_LEGAL_EMPLOYEE_ISSS_REQUEST =
  "PUT_LEGAL_EMPLOYEE_ISSS_REQUEST";
export const PUT_LEGAL_EMPLOYEE_ISSS_SUCCESS =
  "PUT_LEGAL_EMPLOYEE_ISSS_SUCCESS";
export const PUT_LEGAL_EMPLOYEE_ISSS_ERROR =
  "PUT_LEGAL_EMPLOYEE_ISSS_ERROR";

//* ACTIONS ------------------------------------------------
export const putLegalEmployeeISSSRequest = (payload = {}, callback) => ({
  type: PUT_LEGAL_EMPLOYEE_ISSS_REQUEST,
  payload,
  callback,
});

export const putLegalEmployeeISSSSuccess = () => ({
  type: PUT_LEGAL_EMPLOYEE_ISSS_SUCCESS,
});

export const putLegalEmployeeISSSError = (error) => ({
  type: PUT_LEGAL_EMPLOYEE_ISSS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putLegalEmployeeISSSServiceRequest = async ({
  employeeId,
  periodId,
  ...data
}) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/payrolls/periods/${periodId}/employee/${employeeId}/isss`, options);
};
