import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Employee Deductions
import {
  // action types
  GET_PAYROLL_EMPLOYEE_DEDUCTIONS_REQUEST,
  // requests
  getPayrollEmployeeDeductionsServiceRequest,
  // actions
  getPayrollEmployeeDeductionsSuccess,
  getPayrollEmployeeDeductionsError,
} from "../../actions/employeesDeductions/getEmployeeDeductions";

//* Employee Deduction
import {
  // action types
  GET_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST,
  // requests
  getPayrollEmployeeDeductionServiceRequest,
  // actions
  getPayrollEmployeeDeductionSuccess,
  getPayrollEmployeeDeductionError,
} from "../../actions/employeesDeductions/getEmployeeDeduction";

//* Update Employee Deduction
import {
  // action types
  PUT_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST,
  // requests
  putPayrollEmployeeDeductionServiceRequest,
  // actions
  putPayrollEmployeeDeductionSuccess,
  putPayrollEmployeeDeductionError,
} from "../../actions/employeesDeductions/putEmployeeDeduction";

//* Create Employee Deduction
import {
  // action types
  POST_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST,
  // requests
  postPayrollEmployeeDeductionServiceRequest,
  // actions
  postPayrollEmployeeDeductionSuccess,
  postPayrollEmployeeDeductionError,
} from "../../actions/employeesDeductions/postEmployeeDeduction";

function* getPayrollEmployeeDeductions(action) {
  try {
    const result = yield call(
      getPayrollEmployeeDeductionsServiceRequest,
      action.payload
    );
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getPayrollEmployeeDeductionsSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getPayrollEmployeeDeductionsError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getPayrollEmployeeDeduction(action) {
  try {
    const result = yield call(
      getPayrollEmployeeDeductionServiceRequest,
      action.payload
    );
    const data = get(result, "data.data", {});

    yield put(getPayrollEmployeeDeductionSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getPayrollEmployeeDeductionError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putPayrollEmployeeDeduction(action) {
  try {
    yield call(putPayrollEmployeeDeductionServiceRequest, action.payload);
    yield put(putPayrollEmployeeDeductionSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putPayrollEmployeeDeductionError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postPayrollEmployeeDeduction(action) {
  try {
    yield call(postPayrollEmployeeDeductionServiceRequest, action.payload);
    yield put(postPayrollEmployeeDeductionSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postPayrollEmployeeDeductionError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* payrollEmployeeDeductionsSaga() {
  yield takeLatest(
    GET_PAYROLL_EMPLOYEE_DEDUCTIONS_REQUEST,
    getPayrollEmployeeDeductions
  );
  yield takeLatest(
    GET_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST,
    getPayrollEmployeeDeduction
  );
  yield takeLatest(
    PUT_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST,
    putPayrollEmployeeDeduction
  );
  yield takeLatest(
    POST_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST,
    postPayrollEmployeeDeduction
  );
}

export default payrollEmployeeDeductionsSaga;
