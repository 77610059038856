import React from "react";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";

//Customs
import PrivateRoute from "../PrivateRoute";

//Selectors
import { isLogged } from "../../store/auth/selectors";

//* ROUTES *//
//* AREAS
import AreasRoutes from "./Areas";
//* COMPANIES
import CompaniesRoutes from "./Companies";
//* CONCEPT CATEGORIES
import ConceptCategoriesRoutes from "./ConceptCategories";
//* CONCEPT TYPES
import ConceptTypesRoutes from "./ConceptTypes";
//* COST CENTERS
import CostCentersRoutes from "./CostCenters";
//* COUNTRIES
import CountriesRoutes from "./Countries";
//* DOCUMENTS
import DocumentsRoutes from "./Documents";
//* FINANCIAL ENTITIES
import FinancialEntitiesRoutes from "./FinancialEntities";
//* LINEA DE NEGOCIOS
import LineBusinessRoutes from "./LineBusiness";
//* POSITIONS
import PositionsRoutes from "./Positions";
//* REQUIREMENTS
import RequirementsRoutes from "./Requirements";
//* UBICATIONS
import UbicationsRoutes from "./Ubications";
//* PARAMETROS DE SISTEMA
import SettingsRoutes from "./Settings";

const AdministrationRoutes = ({ isLogged }) => {
  return (
    <Switch>
      <PrivateRoute
        path="/administration/areas"
        component={AreasRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/administration/companies"
        component={CompaniesRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/administration/concept-categories"
        component={ConceptCategoriesRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/administration/concept-types"
        component={ConceptTypesRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/administration/costcenters"
        component={CostCentersRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/administration/countries"
        component={CountriesRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/administration/documents"
        component={DocumentsRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/administration/financial-entities"
        component={FinancialEntitiesRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/administration/line-business"
        component={LineBusinessRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/administration/positions"
        component={PositionsRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/administration/requirements"
        component={RequirementsRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/administration/ubications"
        component={UbicationsRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/administration/settings"
        component={SettingsRoutes}
        isAuthenticated={isLogged}
      />
      {/* <Redirect to="/administration/positions" /> */}
      <Redirect to="/" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isLogged: isLogged(state),
});

export default connect(mapStateToProps)(AdministrationRoutes);
