import { lazy } from "react";
import { connect } from "react-redux";

const New = lazy(() => import("./New"));

const mapStateToProps = (state) => {
  return ({
    isLoading: state.administration.settings.postSetting.loading,
    isSuccess: state.administration.settings.postSetting.success,
    isError: state.administration.settings.postSetting.error,
    message: state.administration.settings.postSetting.message,
    auth: state.authentication
  })
};

export default connect(mapStateToProps)(New);
