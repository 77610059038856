//Get Data
import {
  GET_YEARS_REQUEST,
  GET_YEARS_SUCCESS,
  GET_YEARS_ERROR,
} from "../../actions/year/getYear";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_YEARS_REQUEST:
      return {
        data: [],
        error: false,
        loading: true,
      };
    case GET_YEARS_SUCCESS:
      return {
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_YEARS_ERROR:
      return {
        data: [],
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    // -----------------------------------------------------------

    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
