/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { isEmpty, find } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";

import { setRoutes } from "../store/routes/action/route";
import { routesPrivates } from "../utils/sideBarMenu"

const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }) => {
  localStorage.setItem("lastPath", rest.location.pathname);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const modules = useSelector(state => state.authentication.modules)
  const { currentUser } = useSelector(state => state.authentication);

  useEffect(() => {
    dispatch(setRoutes(location.pathname))
  }, [location.pathname])

  useEffect(() => {
    let route = null;

    for (const routePrivate of routesPrivates) {
      if (location.pathname.includes(routePrivate.path)) {
        route = routePrivate;
      }
    }

    if (!!route) {
      /*
        - evalua si la ruta es una de las privadas por modulos.
        Nota: acá solo se evalúa el acceso o nó a la ruta.
        si quiere conocer las opciones del menú debe dirigirse a /src/components/Layouts/Main/Main
      */
      try {
        const idCompany = currentUser?.currentCompany?.id;
        const validModules = modules?.find((e) => e?.id === idCompany)
        const permission = validModules?.module?.find((e) => e?.module_id === route?.module_id)

        if (isEmpty(permission)) {
          console.log("en private 1", permission)
          // => si no tiene permisos al modulo lo manda a home
         history.push("/home") // para pruebas desabilitar esta linea
        } else {
          if (!isEmpty(permission.privileges)) {
            console.log("en private 2", permission)
            // => si no tiene permisos al submodulo lo manda a home
            const validate = find(permission.privileges, (smodule) => location.pathname.includes(smodule.url))
            if (isEmpty(validate)) {
              console.log("home2")
              history.push("/home")
            }
          }
        }
      } catch (error) {
        console.log("home3")
        history.push("/home")
      }
    }
  }, [])



  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/log-in" />
      }
    />
  );
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

export default PrivateRoute;
