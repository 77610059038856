import React from "react";
import { Route, Switch } from "react-router-dom";

import Administration from "../../../modules/PersonnelActions/Administration";

export default () => {
  return (
    <Switch>
      <Route exact path="/personnel-actions/administration" component={Administration} />
    </Switch>
  );
};