import { createSelector } from "reselect";

const payrollEmployeeBenefits = (state) =>
  state.payroll.payrollEmployeeBenefits;

export const loading = createSelector(
  payrollEmployeeBenefits,
  (state) => state.loading
);

export const loadingAction = createSelector(
  payrollEmployeeBenefits,
  (state) => state.loadingAction
);

export const loadingDetail = createSelector(
  payrollEmployeeBenefits,
  (state) => state.loadingDetail
);

export const data = createSelector(
  payrollEmployeeBenefits,
  (state) => state.data
);

export const pagination = createSelector(
  payrollEmployeeBenefits,
  (state) => state.pagination
);

export const detail = createSelector(
  payrollEmployeeBenefits,
  (state) => state.detail
);
