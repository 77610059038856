//All Benefits
import {
  GET_BENEFITS_REQUEST,
  GET_BENEFITS_SUCCESS,
  GET_BENEFITS_ERROR,
} from "../../actions/benefits/getBenefits";

//Benefit
import {
  GET_BENEFIT_REQUEST,
  GET_BENEFIT_SUCCESS,
  GET_BENEFIT_ERROR,
} from "../../actions/benefits/getBenefit";

//Update Benefit
import {
  PUT_BENEFIT_REQUEST,
  PUT_BENEFIT_SUCCESS,
  PUT_BENEFIT_ERROR,
} from "../../actions/benefits/putBenefit";

//Create Benefit
import {
  POST_BENEFIT_REQUEST,
  POST_BENEFIT_SUCCESS,
  POST_BENEFIT_ERROR,
} from "../../actions/benefits/postBenefit";

//All BenefitEmployees
import {
  GET_BENEFIT_EMPLOYEES_REQUEST,
  GET_BENEFIT_EMPLOYEES_SUCCESS,
  GET_BENEFIT_EMPLOYEES_ERROR,
} from "../../actions/benefits/getBenefitEmployees";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
  employees: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BENEFITS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_BENEFITS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_BENEFITS_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case GET_BENEFIT_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_BENEFIT_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loading: false,
      };
    case GET_BENEFIT_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case PUT_BENEFIT_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_BENEFIT_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_BENEFIT_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_BENEFIT_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_BENEFIT_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_BENEFIT_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case GET_BENEFIT_EMPLOYEES_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_BENEFIT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.payload,
        error: false,
        loading: false,
      };
    case GET_BENEFIT_EMPLOYEES_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
