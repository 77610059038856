import request, { Methods, withToken } from "../../../utils/request";
import { getUserCompany } from "../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const APPROVE_PERSONNEL_ACTIONS_DEFAULT = "APPROVE_PERSONNEL_ACTIONS_DEFAULT";
export const APPROVE_PERSONNEL_ACTIONS_REQUEST = "APPROVE_PERSONNEL_ACTIONS_REQUEST";
export const APPROVE_PERSONNEL_ACTIONS_SUCCESS = "APPROVE_PERSONNEL_ACTIONS_SUCCESS";
export const APPROVE_PERSONNEL_ACTIONS_ERROR = "APPROVE_PERSONNEL_ACTIONS_ERROR";

//* ACTIONS ------------------------------------------------
export const approvePersonnelActionsDefault = () => ({
  type: APPROVE_PERSONNEL_ACTIONS_DEFAULT,
});

export const approvePersonnelActionsRequest = (payload = {}, callback) => ({
  type: APPROVE_PERSONNEL_ACTIONS_REQUEST,
  payload,
  callback,
});

export const approvePersonnelActionsSuccess = (data) => ({
  type: APPROVE_PERSONNEL_ACTIONS_SUCCESS,
  payload: data,
});

export const approvePersonnelActionsError = (error) => ({
  type: APPROVE_PERSONNEL_ACTIONS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const approvePersonnelActionsServiceRequest = async ({ personnelActionId, ...formData }) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...formData },
  });
  return request(`personnel_actions/${personnelActionId}/approve`, options);
};
