import { lazy } from "react";
import { connect } from "react-redux";

import { getFetchPayrollsPeriodSnapshot } from "../../../../hooks/Payroll/Payrolls/getFetchPayrollPeriodSnapshot"
import { getHeaderPeriodHistory } from "../../../../hooks/Payroll/Payrolls/getHeaderPeriodHistory"

const Employee = lazy(() => import("./Employee"));

const mapStateToProps = (state) => {
  return ({
    employees: state.payroll.periods.snapshot.data,
    loading: state.payroll.periods.loading,
    error: state.payroll.periods.error,
    pagination: state.payroll.periods.snapshot.pagination,
    loadingPayroll: state.payroll.periods.getHeaderHistory.loading,
    payroll: state.payroll.periods.getHeaderHistory.data,
  })
};

const mapDispatchToProps = dispatch => ({
  getFetchPayrollsPeriodSnapshot: (formData) => dispatch(getFetchPayrollsPeriodSnapshot(formData)),
  getHeaderPeriodHistory: (formData) => dispatch(getHeaderPeriodHistory(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Employee);
