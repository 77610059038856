import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Employee Benefits
import {
  // action types
  GET_PAYROLL_EMPLOYEE_BENEFITS_REQUEST,
  // requests
  getPayrollEmployeeBenefitsServiceRequest,
  // actions
  getPayrollEmployeeBenefitsSuccess,
  getPayrollEmployeeBenefitsError,
} from "../../actions/employeesBenefits/getEmployeeBenefits";

//* Create Employee Benefit
import {
  // action types
  POST_PAYROLL_EMPLOYEE_BENEFIT_REQUEST,
  // requests
  postPayrollEmployeeBenefitServiceRequest,
  // actions
  postPayrollEmployeeBenefitSuccess,
  postPayrollEmployeeBenefitError,
} from "../../actions/employeesBenefits/postEmployeeBenefit";

function* getPayrollEmployeeBenefits(action) {
  try {
    const result = yield call(
      getPayrollEmployeeBenefitsServiceRequest,
      action.payload
    );
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getPayrollEmployeeBenefitsSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getPayrollEmployeeBenefitsError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postPayrollEmployeeBenefit(action) {
  try {
    yield call(postPayrollEmployeeBenefitServiceRequest, action.payload);
    yield put(postPayrollEmployeeBenefitSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postPayrollEmployeeBenefitError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* payrollEmployeeBenefitsSaga() {
  yield takeLatest(
    GET_PAYROLL_EMPLOYEE_BENEFITS_REQUEST,
    getPayrollEmployeeBenefits
  );
  yield takeLatest(
    POST_PAYROLL_EMPLOYEE_BENEFIT_REQUEST,
    postPayrollEmployeeBenefit
  );
}

export default payrollEmployeeBenefitsSaga;
