import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "../../../modules/Home";

export default () => {
  return (
    <Switch>
      <Route exact path="/home" component={Home} />
    </Switch>
  );
};