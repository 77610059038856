import request, { Methods } from "../../../utils/request";

//* ACTIONTYPES --------------------------------------------
export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";

//* ACTIONS ------------------------------------------------
export const refreshTokenRequest = () => ({
  type: REFRESH_TOKEN_REQUEST,
  payload: null,
});

export const refreshTokenSuccess = (data) => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: data,
});

export const refreshTokenError = (error) => ({
  type: REFRESH_TOKEN_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postRefreshTokenRequest = (credentials = {}) => {
  const options = {
    data: Object.assign({}, credentials, {
      grant_type: "refresh_token",
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    }),
    method: Methods.POST,
  };

  return request("/oauth/token", options);
};
