import {
  getLegalAFPRequest,
  getLegalAFPSuccess,
  getLegalAFPError,
  getLegalAFPServiceRequest
} from "../../../store/legal/actions/afp/getAFP";

export const getLegalAFP = (formData) => async (dispatch, getState) => {
  dispatch(getLegalAFPRequest());
  try {
    const response = await getLegalAFPServiceRequest(formData);
    dispatch(getLegalAFPSuccess(response.data));
  } catch (error) {
    dispatch(getLegalAFPError(error))
  }
  return Promise.resolve();
};
