import * as Yup from "yup";

export const Validators = (active) => {

  switch (active) {
    case "Contratacion": return (
      Yup.object().shape({
        personnel_action_type_id: Yup.string().required("Debe seleccionar una AP"),
        payroll_id: Yup.string().required("Nómina es requerido").nullable(),
        employee_id: Yup.string().required("Empleado es requerido"),
        date_governs: Yup.string().required("Fecha es requerida"),
        comment: Yup.string().required("Comentario es requerido"),
      })
    )
    case "Control": return (
      Yup.object().shape({
        personnel_action_type_id: Yup.string().required("Debe seleccionar una AP"),
        payroll_id: Yup.string().required("Nómina es requerido").nullable(),
        employee_id: Yup.string().required("Empleado es requerido"),
        date_governs: Yup.string().required("Fecha es requerida"),
        comment: Yup.string().required("Comentario es requerido"),
      })
    )
    case "Desc_dia": return (
      Yup.object().shape({
        personnel_action_type_id: Yup.string().required("Debe seleccionar una AP"),
        payroll_id: Yup.string().required("Nómina es requerido").nullable(),
        employee_id: Yup.string().required("Empleado es requerido"),
        date_governs: Yup.string().required("Fecha es requerida"),
        comment: Yup.string().required("Comentario es requerido"),
      })
    )
    case "Desc_hora": return (
      Yup.object().shape({
        personnel_action_type_id: Yup.string().required("Debe seleccionar una AP"),
        payroll_id: Yup.string().required("Nómina es requerido").nullable(),
        employee_id: Yup.string().required("Empleado es requerido"),
        date_governs: Yup.string().required("Fecha es requerida"),
        quantity: Yup.number("Debe ingresar un número").positive("Solo se admiten números positivos").integer("Debe ingresar un número entero").required("Cantidad es requerido"),
        comment: Yup.string().required("Comentario es requerido"),
      })
    )
    case "Desc_monto": return (
      Yup.object().shape({
        personnel_action_type_id: Yup.string().required("Debe seleccionar una AP"),
        payroll_id: Yup.string().required("Nómina es requerido").nullable(),
        employee_id: Yup.string().required("Empleado es requerido"),
        date_governs: Yup.string().required("Fecha es requerida"),
        amount: Yup.number().positive("Solo se admiten números positivos").required("Monto es requerido").min(1, "El minimo es 1"),
        comment: Yup.string().required("Comentario es requerido"),
      })
    )
    case "Incapacidad": return (
      Yup.object().shape({
        personnel_action_type_id: Yup.string().required("Debe seleccionar una AP"),
        payroll_id: Yup.string().required("Nómina es requerido").nullable(),
        start_date: Yup.string().required("Fecha es requerida"),
        finish_date: Yup.string().required("Fecha es requerida"),
        comment: Yup.string().required("Comentario es requerido"),
      })
    )
    case "Incapacidad_sin_desc": return (
      Yup.object().shape({
        personnel_action_type_id: Yup.string().required("Debe seleccionar una AP"),
        payroll_id: Yup.string().required("Nómina es requerido").nullable(),
        start_date: Yup.string().required("Fecha es requerida"),
        finish_date: Yup.string().required("Fecha es requerida"),
        comment: Yup.string().required("Comentario es requerido"),
      })
    )
    case "Permiso_sin_pago": return (
      Yup.object().shape({
        personnel_action_type_id: Yup.string().required("Debe seleccionar una AP"),
        payroll_id: Yup.string().required("Nómina es requerido").nullable(),
        start_date: Yup.string().required("Fecha es requerida"),
        finish_date: Yup.string().required("Fecha es requerida"),
        comment: Yup.string().required("Comentario es requerido"),
      })
    )
    case "Retiro": return (
      Yup.object().shape({
        personnel_action_type_id: Yup.string().required("Debe seleccionar una AP"),
        payroll_id: Yup.string().required("Nómina es requerido").nullable(),
        employee_id: Yup.string().required("Empleado es requerido"),
        date_governs: Yup.string().required("Fecha es requerida"),
        comment: Yup.string().required("Comentario es requerido"),
      })
    )
    case "Salario": return (
      Yup.object().shape({
        personnel_action_type_id: Yup.string().required("Debe seleccionar una AP"),
        payroll_id: Yup.string().required("Nómina es requerido").nullable(),
        employee_id: Yup.string().required("Empleado es requerido"),
        date_governs: Yup.string().required("Fecha es requerida"),
        comment: Yup.string().required("Comentario es requerido"),
        new_salary: Yup.string().required("Nuevo salario es requerido"),
      })
    )
    case "Vacacion": return (
      Yup.object().shape({
        personnel_action_type_id: Yup.string().required("Debe seleccionar una AP"),
        payroll_id: Yup.string().required("Nómina es requerido").nullable(),
        start_date: Yup.string().required("Fecha es requerida"),
        finish_date: Yup.string().required("Fecha es requerida"),
        comment: Yup.string().required("Comentario es requerido"),
      })
    )

    default: return (
      Yup.object().shape({
        personnel_action_type_id: Yup.string().required("Debe seleccionar una AP"),
      })
    )
  }
}

//   return (
//     Yup.object().shape({
//       employeeId: Yup.string().required("Campo requerido"),
//       name: Yup.string().required(""),
//       code: Yup.string().required(""),



//       //   switch(active) {
//       //   case "Contratacion": "" ; break;
//       //   case "Control": "" ; break;
//       //   case "Desc_dia": "" ; break;
//       //   case "Incapacidad": "" ; break;
//       //   case "Permiso_sin_pago": "" ; break;
//       //   case "Retiro": "" ; break;
//       //   case "Salario": "" ; break;
//       //   case "Vacacion": "" ; break;
//       //   default: "" ; break;
//       // }
//     })
//   )
// }
