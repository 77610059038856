import { lazy } from "react";
import { connect } from "react-redux";
import { getLegalISR } from "../../../hooks/Legal/ISR/useFetchISR";
import { postLegalSnapshotISR } from "../../../hooks/Legal/ISR/useFetchSnapshotISR";

const ISRMensual = lazy(() => import("./ISRMensual"));

const mapStateToProps = (state) => {
  return ({
    company: state.authentication.currentUser.currentCompany.tradename,
    lastRoutes: state.routes.routes,

    ISR: state.legal.isr.getLegalISR.data,
    isLoading: state.legal.isr.getLegalISR.loading,
    isError: state.legal.isr.getLegalISR.error,
    pagination: state.legal.isr.getLegalISR.pagination,

    snapshotISRData: state.legal.isr.postLegalSnapshotISR.data,
    snapshotISRSuccess: state.legal.isr.postLegalSnapshotISR.success,
    snapshotISRLoading: state.legal.isr.postLegalSnapshotISR.loading,
    snapshotISRError: state.legal.isr.postLegalSnapshotISR.error,
  })
};

const mapDispatchToProps = dispatch => ({
  getLegalISR: (formData) => dispatch(getLegalISR(formData)),
  postLegalSnapshotISR: (formData) => dispatch(postLegalSnapshotISR(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ISRMensual);
