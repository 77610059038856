import { connect } from "react-redux";

import Login from "./Login";

// selectors
import { isLogged, isLoading } from "../../../store/auth/selectors";

const mapStateToProps = (state) => ({
  isLogged: isLogged(state),
  isLoading: isLoading(state),
});

export default connect(mapStateToProps)(Login);
