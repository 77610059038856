import { createSelector } from "reselect";

const payrollEmployeeDeductions = (state) =>
  state.payroll.payrollEmployeeDeductions;

export const loading = createSelector(
  payrollEmployeeDeductions,
  (state) => state.loading
);

export const loadingAction = createSelector(
  payrollEmployeeDeductions,
  (state) => state.loadingAction
);

export const loadingDetail = createSelector(
  payrollEmployeeDeductions,
  (state) => state.loadingDetail
);

export const data = createSelector(
  payrollEmployeeDeductions,
  (state) => state.data
);

export const pagination = createSelector(
  payrollEmployeeDeductions,
  (state) => state.pagination
);

export const detail = createSelector(
  payrollEmployeeDeductions,
  (state) => state.detail
);
