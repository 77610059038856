import { lazy } from "react";
import { connect } from "react-redux";
import { loading } from "../../../../store/administration/selectors/companies";
import {
  loading as isLoadingCountries,
  data as countriesData,
} from "../../../../store/administration/selectors/countries";

const NewCompany = lazy(() => import("./NewCompany"));

const mapStateToProps = (state) => ({
  isLoading: loading(state),
  isLoadingCountries: isLoadingCountries(state),
  countries: countriesData(state),
});

export default connect(mapStateToProps)(NewCompany);
