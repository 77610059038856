import { createSelector } from "reselect";

const benefits = (state) => state.benefits.benefits;

export const loading = createSelector(benefits, (state) => state.loading);

export const loadingAction = createSelector(
  benefits,
  (state) => state.loadingAction
);

export const data = createSelector(
  benefits, 
  (state) => state.data
);

export const pagination = createSelector(
  benefits, 
  (state) => state.pagination
);

export const detail = createSelector(benefits, (state) => state.detail);
