import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';

const Styled = styled.div``;

export default Styled;


export const useStyles = makeStyles((theme) => ({
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        maxWidth: theme.breakpoints.values.sm
    },
    btn: {
        display: "flex",
        alignItems: "flex-end"
    },
    btnMui: {
        "&:focus": {
            outline: "none"
        }
    }
}));