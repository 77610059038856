import React from "react";
import { Route, Switch } from "react-router-dom";

import F910 from "../../../modules/Legal/F910";

export default () => {
  return (
    <Switch>
      <Route exact path="/legal/f910" component={F910} />
    </Switch>
  );
};