import { lazy } from "react";
import { connect } from "react-redux";

import { getFetchRoles } from "../../../../hooks/Security/Users/getFetchRoles";
import { getFetchModules } from "../../../../hooks/Security/Users/getFetchModules";
import { getAllEmployees } from "../../../../hooks/Administration/Employees/getAllEmployees";

const NewUser = lazy(() => import("./NewUser"));

const mapStateToProps = (state) => {
  return ({
    loading: state.security.user.loading,

    loadingModules: state.security.user.modules.loading,
    modules: state.security.user.modules.data,

    loadingRole: state.security.user.roles.loading,
    roles: state.security.user.roles.data,

    loadingEmployess: state.administration.employees.loading,
    employess: state.administration.employees.data,
  })
};

const mapDispatchToProps = dispatch => ({
  getFetchRoles: (formData) => dispatch(getFetchRoles(formData)),
  getFetchModules: (formData) => dispatch(getFetchModules(formData)),
  getAllEmployees: (formData) => dispatch(getAllEmployees(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
