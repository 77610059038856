import {
  getLegalObservationCodeSPURequest,
  getLegalObservationCodeSPUSuccess,
  getLegalObservationCodeSPUError,
  getLegalObservationCodeSPUServiceRequest,
} from "../../../store/legal/actions/spu/getObservationCodeSPU";

export const  getLegalObservationCodeSPU = () => async (dispatch, getState) => {
  dispatch(getLegalObservationCodeSPURequest());
  try {
    const response = await getLegalObservationCodeSPUServiceRequest();
    dispatch(getLegalObservationCodeSPUSuccess(response.data));
  } catch (error) {
    dispatch(getLegalObservationCodeSPUError(error))
  }
  return Promise.resolve();
};
