import React from "react";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";

//Customs
import PrivateRoute from "../PrivateRoute";

//Selectors
import { isLogged } from "../../store/auth/selectors";

//* ROUTES *//
import Employeee from "./Employees";
import Reports from "./Reports";

const LegalRoutes = ({ isLogged }) => {
  return (
    <Switch>
      <PrivateRoute
        path="/employees/employees"
        component={Employeee}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/employees/reports"
        component={Reports}
        isAuthenticated={isLogged}
      />
      <Redirect to="/" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isLogged: isLogged(state),
});

export default connect(mapStateToProps)(LegalRoutes);
