import areas from "./areas";
import companies from "./companies";
import concept_categories from "./concept_categories";
import concept_types from "./concept_types";
import costcenters from "./costcenters";
import countries from "./countries";
import documents from "./documents";
import employees from "./employees";
import financialEntities from "./financial_entities";
import positions from "./positions";
import requirements from "./requirements";
import ubications from "./ubications";

export default [
  areas,
  companies,
  concept_categories,
  concept_types,
  costcenters,
  countries,
  documents,
  employees,
  financialEntities,
  positions,
  requirements,
  ubications,
];
