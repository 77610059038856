import { lazy } from "react";
import { connect } from "react-redux";
import { loading } from "../../../../store/administration/selectors/positions";
import {
  loading as loadingRequirements,
  data as requirements,
} from "../../../../store/administration/selectors/requirements";
import {
  loading as loadingAreas,
  data as areas,
} from "../../../../store/administration/selectors/areas";
import {
  loading as loadingDocuments,
  data as documents,
} from "../../../../store/administration/selectors/documents";
import {
  loading as loadingCostcenters,
  data as costcenters,
} from "../../../../store/administration/selectors/costcenters";

const NewPosition = lazy(() => import("./NewPosition"));

const mapStateToProps = (state) => ({
  loading: loading(state),
  isLoadingAreas: loadingAreas(state),
  areas: areas(state),
  isLoadingRequirements: loadingRequirements(state),
  requirements: requirements(state),
  isLoadingDocuments: loadingDocuments(state),
  documents: documents(state),
  isLoadingCostcenters: loadingCostcenters(state),
  costcenters: costcenters(state),
});

export default connect(mapStateToProps)(NewPosition);
