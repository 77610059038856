import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_LEGAL_ISSS_REQUEST = "POST_LEGAL_ISSS_REQUEST";
export const POST_LEGAL_ISSS_SUCCESS = "POST_LEGAL_ISSS_SUCCESS";
export const POST_LEGAL_ISSS_ERROR = "POST_LEGAL_ISSS_ERROR";

//* ACTIONS ------------------------------------------------
export const postLegalISSSRequest = (payload = {}, callback) => ({
  type: POST_LEGAL_ISSS_REQUEST,
  payload,
  callback,
});

export const postLegalISSSSuccess = (data) => ({
  type: POST_LEGAL_ISSS_SUCCESS,
  payload: data,
});

export const postLegalISSSError = (error) => ({
  type: POST_LEGAL_ISSS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postLegalISSSServiceRequest = async ({ payrollId, periodId, ...data }) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });
  return request(`/payrolls/${payrollId}/periods/${periodId}/isss`, options);
};
