import { lazy } from "react";
import { connect } from "react-redux";
import {
  loadingAction,
  loading,
  detail,
} from "../../../../store/administration/selectors/countries";

const ShowCountry = lazy(() => import("./ShowCountry"));

const mapStateToProps = (state) => ({
  loadingAction: loadingAction(state),
  loading: loading(state),
  country: detail(state),
});

export default connect(mapStateToProps)(ShowCountry);
