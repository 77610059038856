import { getUserCompany } from "./constants";
import { baseUrl } from "./request";
import * as storage from "localforage";
import { isEmpty } from "lodash";
import axios from "axios";


const generatePDF = async ({ path, params = {}, namePDF = "" }) => {
  const token = await storage.getItem("token");
  const formatedToken = `Bearer ${token}`

  const prevParams = []

  for (const key in params) {
    if (Object.hasOwnProperty.call(params, key)) {
      const element = params[key];
      prevParams.push(`filter[${key}]=${element}`)
    }
  }

  const param = `${!isEmpty(params) ? `?${prevParams.join('&')}` : ""}`
  const url = `${baseUrl}${path}${param}`

  const response = await fetch(url, {
    method: "GET",
    headers: {
      CompanyId: await getUserCompany(),
      Authorization: formatedToken,
    },
  })
  const blob = await response.blob();

  const urlObject = window.URL.createObjectURL(blob);
  const link = document.createElement('a')
  link.href = urlObject;
  link.setAttribute('download', namePDF)
  document.body.appendChild(link)
  link.click();
}

export const downloadPost = async ({
  path,
  name = "Reporte",
  method = "POST",
  body = {},
  params = {},
  getLoading = () => { },
  getSusccess = () => { },
  getError = () => { },
}) => {

  const token = await storage.getItem("token");

  getLoading(true)
  axios({
    url: `${baseUrl}${path}`,
    method: method,
    responseType: "blob",
    headers: {
      'Authorization': `Bearer ${token}`,
      CompanyId: await getUserCompany(),
    },
    params,
    data: body
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${name}.pdf`);
    document.body.appendChild(link);
    link.click();
    getLoading(false)
    getSusccess(response)
  })
    .catch((error) => {
      console.log(error.response)
      getError(error)
      getLoading(false)
    });
};

export default generatePDF