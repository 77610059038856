import React from "react";
import { Route, Switch } from "react-router-dom";

import CurrentPeriod from "../../../modules/Vacations/CurrentPeriod";

export default () => {
  return (
    <Switch>
      <Route exact path="/vacations/current-period" component={CurrentPeriod} />
    </Switch>
  );
};