import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

//* ACTIONS ------------------------------------------------
export const getuserRequest = (payload = {}, callback) => ({
  type: GET_USER_REQUEST,
  payload,
  callback,
});

export const getuserSuccess = (data) => ({
  type: GET_USER_SUCCESS,
  payload: data,
});

export const getuserError = (error) => ({
  type: GET_USER_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getuserServiceRequest = async (userId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/users/${userId}`, options);
};
