import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_LEGAL_OBSERVATION_CODE_SPU_REQUEST = "GET_LEGAL_OBSERVATION_CODE_SPU_REQUEST";
export const GET_LEGAL_OBSERVATION_CODE_SPU_SUCCESS = "GET_LEGAL_OBSERVATION_CODE_SPU_SUCCESS";
export const GET_LEGAL_OBSERVATION_CODE_SPU_ERROR = "GET_LEGAL_OBSERVATION_CODE_SPU_ERROR";

//* ACTIONS ------------------------------------------------
export const getLegalObservationCodeSPURequest = (payload = {}, callback) => ({
  type: GET_LEGAL_OBSERVATION_CODE_SPU_REQUEST,
  payload,
  callback,
});

export const getLegalObservationCodeSPUSuccess = (data) => ({
  type: GET_LEGAL_OBSERVATION_CODE_SPU_SUCCESS,
  payload: data,
});

export const getLegalObservationCodeSPUError = (error) => ({
  type: GET_LEGAL_OBSERVATION_CODE_SPU_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getLegalObservationCodeSPUServiceRequest = async () => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/spu/observation_codes`, options);
};
