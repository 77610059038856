import request, { Methods, withToken } from "../../../utils/request";
import { getUserCompany } from "../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const UNAPPLY_PERSONNEL_ACTIONS_DEFAULT = "UNAPPLY_PERSONNEL_ACTIONS_DEFAULT";
export const UNAPPLY_PERSONNEL_ACTIONS_REQUEST = "UNAPPLY_PERSONNEL_ACTIONS_REQUEST";
export const UNAPPLY_PERSONNEL_ACTIONS_SUCCESS = "UNAPPLY_PERSONNEL_ACTIONS_SUCCESS";
export const UNAPPLY_PERSONNEL_ACTIONS_ERROR = "UNAPPLY_PERSONNEL_ACTIONS_ERROR";

//* ACTIONS ------------------------------------------------
export const unapplyPersonnelActionsDefault = () => ({
  type: UNAPPLY_PERSONNEL_ACTIONS_DEFAULT,
});

export const unapplyPersonnelActionsRequest = (payload = {}, callback) => ({
  type: UNAPPLY_PERSONNEL_ACTIONS_REQUEST,
  payload,
  callback,
});

export const unapplyPersonnelActionsSuccess = (data) => ({
  type: UNAPPLY_PERSONNEL_ACTIONS_SUCCESS,
  payload: data,
});

export const unapplyPersonnelActionsError = (error) => ({
  type: UNAPPLY_PERSONNEL_ACTIONS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const unapplyPersonnelActionsServiceRequest = async (id, formData) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...formData },
  });
  return request(`personnel_actions/${id}/deapply`, options);
};
