import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All CostCenters
import {
  // action types
  GET_COSTCENTERS_REQUEST,
  // requests
  getCostCentersServiceRequest,
  // actions
  getCostCentersSuccess,
  getCostCentersError,
} from "../../actions/costcenters/getCostCenters";

//* CostCenter
import {
  // action types
  GET_COSTCENTER_REQUEST,
  // requests
  getCostCenterServiceRequest,
  // actions
  getCostCenterSuccess,
  getCostCenterError,
} from "../../actions/costcenters/getCostCenter";

//* Update CostCenter
import {
  // action types
  PUT_COSTCENTER_REQUEST,
  // requests
  putCostCenterServiceRequest,
  // actions
  putCostCenterSuccess,
  putCostCenterError,
} from "../../actions/costcenters/putCostCenter";

//* Create CostCenter
import {
  // action types
  POST_COSTCENTER_REQUEST,
  // requests
  postCostCenterServiceRequest,
  // actions
  postCostCenterSuccess,
  postCostCenterError,
} from "../../actions/costcenters/postCostCenter";

function* getCostCenters(action) {
  try {
    const result = yield call(getCostCentersServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getCostCentersSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getCostCentersError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getCostCenter(action) {
  try {
    const result = yield call(getCostCenterServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getCostCenterSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getCostCenterError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putCostCenter(action) {
  try {
    yield call(putCostCenterServiceRequest, action.payload);
    yield put(putCostCenterSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putCostCenterError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postCostCenter(action) {
  try {
    yield call(postCostCenterServiceRequest, action.payload);
    yield put(postCostCenterSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postCostCenterError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* costCentersSaga() {
  yield takeLatest(GET_COSTCENTERS_REQUEST, getCostCenters);
  yield takeLatest(GET_COSTCENTER_REQUEST, getCostCenter);
  yield takeLatest(PUT_COSTCENTER_REQUEST, putCostCenter);
  yield takeLatest(POST_COSTCENTER_REQUEST, postCostCenter);
}

export default costCentersSaga;
