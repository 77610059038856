//Get Reports
import {
  GET_PAYROLL_REPORT_REQUEST,
  GET_PAYROLL_REPORT_SUCCESS,
  GET_PAYROLL_REPORT_ERROR,
} from "../../actions/reports/getReports";

//Get Reports PAyment Cuscatlan
import {
  GET_PAYROLL_REPORT_PAYMENT_CUSC_REQUEST,
  GET_PAYROLL_REPORT_PAYMENT_CUSC_SUCCESS,
  GET_PAYROLL_REPORT_PAYMENT_CUSC_ERROR,
} from "../../actions/reports/getReportsPaymentCusc";
import {
  GET_PAYROLL_REPORT_PAYMENT_BAC_REQUEST,
  GET_PAYROLL_REPORT_PAYMENT_BAC_SUCCESS,
  GET_PAYROLL_REPORT_PAYMENT_BAC_ERROR,
} from "../../actions/reports/getReportsPaymentBAC";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  paymentCuscatlan: {
    loading: false,
    error: false,
    data: [],
  },
  paymentBAC: {
    loading: false,
    error: false,
    data: [],
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYROLL_REPORT_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_PAYROLL_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        error: false,
        loading: false,
      };
    case GET_PAYROLL_REPORT_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };

    case GET_PAYROLL_REPORT_PAYMENT_CUSC_REQUEST:
      return {
        ...state,
        paymentCuscatlan: {
          loading: true,
          error: false,
          data: [],
        }
      };
    case GET_PAYROLL_REPORT_PAYMENT_CUSC_SUCCESS:
      return {
        ...state,
        paymentCuscatlan: {
          data: action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_PAYROLL_REPORT_PAYMENT_CUSC_ERROR:
      return {
        ...state,
        paymentCuscatlan: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };

    case GET_PAYROLL_REPORT_PAYMENT_BAC_REQUEST:
      return {
        ...state,
        paymentBAC: {
          loading: true,
          error: false,
          data: [],
        }
      };
    case GET_PAYROLL_REPORT_PAYMENT_BAC_SUCCESS:
      return {
        ...state,
        paymentBAC: {
          data: action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_PAYROLL_REPORT_PAYMENT_BAC_ERROR:
      return {
        ...state,
        paymentBAC: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };

    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
