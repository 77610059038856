import { lazy } from "react";
import { connect } from "react-redux";

import { getFetchBenefitsHistory } from "../../../hooks/Benefits/History/useFetchBenefits";

const History = lazy(() => import("./History"));

const mapStateToProps = (state) => {
  return ({
    history: state.benefits.history.data,
    pagination: state.benefits.history.pagination,
    loading: state.benefits.history.loading,
    error: state.benefits.history.error,
  })
};

const mapDispatchToProps = dispatch => ({
  getFetchBenefitsHistory: (formData) => dispatch(getFetchBenefitsHistory(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
