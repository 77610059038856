import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_POSITION_REQUEST = "PUT_POSITION_REQUEST";
export const PUT_POSITION_SUCCESS = "PUT_POSITION_SUCCESS";
export const PUT_POSITION_ERROR = "PUT_POSITION_ERROR";

//* ACTIONS ------------------------------------------------
export const putPositionRequest = (payload = {}, callback) => ({
  type: PUT_POSITION_REQUEST,
  payload,
  callback,
});

export const putPositionSuccess = () => ({
  type: PUT_POSITION_SUCCESS,
});

export const putPositionError = (error) => ({
  type: PUT_POSITION_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putPositionServiceRequest = async ({ positionId, ...data }) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/positions/${positionId}`, options);
};
