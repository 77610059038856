import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Payroll Types
import {
  // action types
  GET_PAYROLL_TYPES_REQUEST,
  // requests
  getPayrollTypesServiceRequest,
  // actions
  getPayrollTypesSuccess,
  getPayrollTypesError,
} from "../../actions/payrolls/getPayrollTypes";

//* All Payroll
import {
  // action types
  GET_PAYROLLS_REQUEST,
  // requests
  getPayrollsServiceRequest,
  // actions
  getPayrollsSuccess,
  getPayrollsError,
} from "../../actions/payrolls/getPayrolls";

//* Payroll
import {
  // action types
  GET_PAYROLL_REQUEST,
  // requests
  getPayrollServiceRequest,
  // actions
  getPayrollSuccess,
  getPayrollError,
} from "../../actions/payrolls/getPayroll";

//* Update Payroll
import {
  // action types
  PUT_PAYROLL_REQUEST,
  // requests
  putPayrollServiceRequest,
  // actions
  putPayrollSuccess,
  putPayrollError,
} from "../../actions/payrolls/putPayroll";

//* Create Payroll
import {
  // action types
  POST_PAYROLL_REQUEST,
  // requests
  postPayrollServiceRequest,
  // actions
  postPayrollSuccess,
  postPayrollError,
} from "../../actions/payrolls/postPayroll";

function* getPayrollTypes(action) {
  try {
    const result = yield call(getPayrollTypesServiceRequest, action.payload);
    const types = get(result, "data.data", []);

    yield put(getPayrollTypesSuccess(types));

    action.callback && action.callback(null, types);
  } catch (e) {
    yield put(getPayrollTypesError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getPayrolls(action) {
  try {
    const result = yield call(getPayrollsServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getPayrollsSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getPayrollsError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getPayroll(action) {
  try {
    const result = yield call(getPayrollServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getPayrollSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getPayrollError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putPayroll(action) {
  try {
    yield call(putPayrollServiceRequest, action.payload);
    yield put(putPayrollSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putPayrollError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postPayroll(action) {
  try {
    yield call(postPayrollServiceRequest, action.payload);
    yield put(postPayrollSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postPayrollError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* payrollsSaga() {
  yield takeLatest(GET_PAYROLL_TYPES_REQUEST, getPayrollTypes);
  yield takeLatest(GET_PAYROLLS_REQUEST, getPayrolls);
  yield takeLatest(GET_PAYROLL_REQUEST, getPayroll);
  yield takeLatest(PUT_PAYROLL_REQUEST, putPayroll);
  yield takeLatest(POST_PAYROLL_REQUEST, postPayroll);
}

export default payrollsSaga;
