import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_MONTHS_REQUEST = "GET_MONTHS_REQUEST";
export const GET_MONTHS_SUCCESS = "GET_MONTHS_SUCCESS";
export const GET_MONTHS_ERROR = "GET_MONTHS_ERROR";

//* ACTIONS ------------------------------------------------
export const getMonthsRequest = (payload = {}, callback) => ({
  type: GET_MONTHS_REQUEST,
  payload,
  callback,
});

export const getMonthsSuccess = (data) => ({
  type: GET_MONTHS_SUCCESS,
  payload: data,
});

export const getMonthsError = (error) => ({
  type: GET_MONTHS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getMonthsServiceRequest = async (params) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/months`, options);
};
