import React from "react";
import { Route, Switch } from "react-router-dom";

import ConceptTypes from "../../../modules/Administration/ConceptTypes";
import NewConceptType from "../../../modules/Administration/ConceptTypes/new";
import ShowConceptType from "../../../modules/Administration/ConceptTypes/show";

export default () => {
  return (
    <Switch>
      <Route
        exact
        path="/administration/concept-types"
        component={ConceptTypes}
      />
      <Route
        exact
        path="/administration/concept-types/new"
        component={NewConceptType}
      />
      <Route
        exact
        path="/administration/concept-types/:id"
        component={ShowConceptType}
      />
    </Switch>
  );
};
