import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_REQUIREMENTS_REQUEST = "GET_REQUIREMENTS_REQUEST";
export const GET_REQUIREMENTS_SUCCESS = "GET_REQUIREMENTS_SUCCESS";
export const GET_REQUIREMENTS_ERROR = "GET_REQUIREMENTS_ERROR";

//* ACTIONS ------------------------------------------------
export const getRequirementsRequest = (payload = {}, callback) => ({
  type: GET_REQUIREMENTS_REQUEST,
  payload,
  callback,
});

export const getRequirementsSuccess = (data) => ({
  type: GET_REQUIREMENTS_SUCCESS,
  payload: data,
});

export const getRequirementsError = (error) => ({
  type: GET_REQUIREMENTS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getRequirementsServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/requirements`, options);
};
