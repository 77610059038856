import { lazy } from "react";
import { connect } from "react-redux";
import {
  loadingAction,
  loading,
  detail,
} from "../../../../store/administration/selectors/costcenters";

const ShowCostCenter = lazy(() => import("./ShowCostCenter"));

const mapStateToProps = (state) => ({
  loadingAction: loadingAction(state),
  loading: loading(state),
  costcenter: detail(state),
});

export default connect(mapStateToProps)(ShowCostCenter);
