import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Requirements
import {
  // action types
  GET_REQUIREMENTS_REQUEST,
  // requests
  getRequirementsServiceRequest,
  // actions
  getRequirementsSuccess,
  getRequirementsError,
} from "../../actions/requirements/getRequirements";

//* Requirements
import {
  // action types
  GET_REQUIREMENT_REQUEST,
  // requests
  getRequirementServiceRequest,
  // actions
  getRequirementSuccess,
  getRequirementError,
} from "../../actions/requirements/getRequirement";

//* Update Requirements
import {
  // action types
  PUT_REQUIREMENT_REQUEST,
  // requests
  putRequirementServiceRequest,
  // actions
  putRequirementSuccess,
  putRequirementError,
} from "../../actions/requirements/putRequirement";

//* Create Requirements
import {
  // action types
  POST_REQUIREMENT_REQUEST,
  // requests
  postRequirementServiceRequest,
  // actions
  postRequirementSuccess,
  postRequirementError,
} from "../../actions/requirements/postRequirement";

function* getRequirements(action) {
  try {
    const result = yield call(getRequirementsServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getRequirementsSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getRequirementsError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getRequirement(action) {
  try {
    const result = yield call(getRequirementServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getRequirementSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getRequirementError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putRequirement(action) {
  try {
    yield call(putRequirementServiceRequest, action.payload);
    yield put(putRequirementSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putRequirementError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postRequirement(action) {
  try {
    yield call(postRequirementServiceRequest, action.payload);
    yield put(postRequirementSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postRequirementError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* requirementsSaga() {
  yield takeLatest(GET_REQUIREMENTS_REQUEST, getRequirements);
  yield takeLatest(GET_REQUIREMENT_REQUEST, getRequirement);
  yield takeLatest(PUT_REQUIREMENT_REQUEST, putRequirement);
  yield takeLatest(POST_REQUIREMENT_REQUEST, postRequirement);
}

export default requirementsSaga;
