import { lazy } from "react";
import { connect } from "react-redux";
import { getPayrollHistory } from "../../../hooks/Payroll/Histories/useFetchHistoies";

const History = lazy(() => import("./History"));

const mapStateToProps = (state) => {
  return ({
    isLoading: state.payroll.histories.loading,
    periods: state.payroll.histories.data, 
    isError: state.payroll.histories.error, 
    pagination: state.payroll.histories.pagination,
    payrolls: state.payroll.payrolls.data,
  })
};

const mapDispatchToProps = dispatch => ({
  getPayrollHistory: (formData) => dispatch(getPayrollHistory(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
