import { lazy } from "react";
import { connect } from "react-redux";

import { getFetchVacationCurrentPeriod } from "../../../hooks/Vacations/Vacations/getFechVacationCurrentPeriod";

const CurrentPeriod = lazy(() => import("./CurrentPeriod"));

const mapStateToProps = (state) => {
  return ({
    vacations: state.vacations.vacations.getVacationsCurrentPeriod.data,
    pagination: state.vacations.vacations.getVacationsCurrentPeriod.pagination,
    loading: state.vacations.vacations.getVacationsCurrentPeriod.loading,
    error: state.vacations.vacations.getVacationsCurrentPeriod.error,
  })
};

const mapDispatchToProps = dispatch => ({
  getFetchVacationCurrentPeriod: (formData) => dispatch(getFetchVacationCurrentPeriod(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPeriod);
