import React from "react";

import { ReactComponent } from "../../../assets/images/staffi-logo.svg";
import { white } from "../../../utils/colors";

const StaffiLogo = ({ fillColor = white, ...props }) => {
  return <ReactComponent fill={fillColor} {...props} />;
};

export default StaffiLogo;
