import React from "react";
import { Route, Switch } from "react-router-dom";

import FinancialEntities from "../../../modules/Administration/FinancialEntities";
import NewFinancialEntitie from "../../../modules/Administration/FinancialEntities/new";
import ShowFinancialEntitie from "../../../modules/Administration/FinancialEntities/show";

export default () => {
  return (
    <Switch>
      <Route
        exact
        path="/administration/financial-entities"
        component={FinancialEntities}
      />
      <Route
        exact
        path="/administration/financial-entities/new"
        component={NewFinancialEntitie}
      />
      <Route
        exact
        path="/administration/financial-entities/:id"
        component={ShowFinancialEntitie}
      />
    </Switch>
  );
};
