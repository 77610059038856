import { lazy } from "react";
import { connect } from "react-redux";
import {
  loadingAction,
  loading,
  detail,
  data,
} from "../../../../store/administration/selectors/concept_categories";

const ShowConceptCategory = lazy(() => import("./ShowConceptCategory"));

const mapStateToProps = (state) => ({
  loadingAction: loadingAction(state),
  loading: loading(state),
  conceptCategories: data(state),
  conceptCategory: detail(state),
});

export default connect(mapStateToProps)(ShowConceptCategory);
