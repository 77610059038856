import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_AREA_PERIODS_REQUEST = "GET_AREA_PERIODS_REQUEST";
export const GET_AREA_PERIODS_SUCCESS = "GET_AREA_PERIODS_SUCCESS";
export const GET_AREA_PERIODS_ERROR = "GET_AREA_PERIODS_ERROR";

//* ACTIONS ------------------------------------------------
export const getAreaPerdiodsRequest = (payload = {}, callback) => ({
  type: GET_AREA_PERIODS_REQUEST,
  payload,
  callback,
});

export const getAreaPerdiodsSuccess = (data) => ({
  type: GET_AREA_PERIODS_SUCCESS,
  payload: data,
});

export const getAreaPerdiodsError = (error) => ({
  type: GET_AREA_PERIODS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getAreaPerdiodsServiceRequest = async ({periodId, ...params}) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
    params,
  });

  return request(`/areas/period/${periodId}`, options);
};
