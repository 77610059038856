import { lazy } from "react";
import { connect } from "react-redux";
import { getPayrollReport } from "../../../hooks/Payroll/Reports/useFetchReports";
import { getPayrollReportPaymentBAC } from "../../../hooks/Payroll/Reports/useFetchPaymentSalaryBAC";
import { getPayrollReportPaymentCusc } from "../../../hooks/Payroll/Reports/useFetchPaymentSalaryCusc";

const Reports = lazy(() => import("./Reports"));

const mapStateToProps = (state) => {
  return ({
    // index
    isLoading: state.payroll.reports.loading,
    periods: state.payroll.reports.data,
    isError: state.payroll.reports.error,
    pagination: state.payroll.reports.pagination,
    // BAC
    isLoadingBAC: state.payroll.reports.paymentBAC.loading,
    dataBAC: state.payroll.reports.paymentBAC.data,
    isErrorBAC: state.payroll.reports.paymentBAC.error,
    // Cusc
    isLoadingCuscatlan: state.payroll.reports.paymentCuscatlan.loading,
    dataCuscatlan: state.payroll.reports.paymentCuscatlan.data,
    isErrorCuscatlan: state.payroll.reports.paymentCuscatlan.error,

    payrolls: state.payroll.payrolls.data,
  })
};

const mapDispatchToProps = dispatch => ({
  getPayrollReport: (formData) => dispatch(getPayrollReport(formData)),
  getPayrollReportPaymentBAC: (formData) => dispatch(getPayrollReportPaymentBAC(formData)),
  getPayrollReportPaymentCusc: (formData) => dispatch(getPayrollReportPaymentCusc(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
