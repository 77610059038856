//All Ubications
import {
  GET_UBICATIONS_REQUEST,
  GET_UBICATIONS_SUCCESS,
  GET_UBICATIONS_ERROR,
} from "../../actions/ubications/getUbications";

//Concept Ubication
import {
  GET_UBICATION_REQUEST,
  GET_UBICATION_SUCCESS,
  GET_UBICATION_ERROR,
} from "../../actions/ubications/getUbication";

//Update Ubication
import {
  PUT_UBICATION_REQUEST,
  PUT_UBICATION_SUCCESS,
  PUT_UBICATION_ERROR,
} from "../../actions/ubications/putUbication";

//Create Ubication
import {
  POST_UBICATION_REQUEST,
  POST_UBICATION_SUCCESS,
  POST_UBICATION_ERROR,
} from "../../actions/ubications/postUbication";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  detail: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_UBICATIONS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_UBICATIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_UBICATIONS_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case GET_UBICATION_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_UBICATION_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loading: false,
      };
    case GET_UBICATION_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case PUT_UBICATION_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_UBICATION_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_UBICATION_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_UBICATION_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_UBICATION_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_UBICATION_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
