import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_NEXT_CODE_REQUEST = "GET_NEXT_CODE_REQUEST";
export const GET_NEXT_CODE_SUCCESS = "GET_NEXT_CODE_SUCCESS";
export const GET_NEXT_CODE_ERROR = "GET_NEXT_CODE_ERROR";

//* ACTIONS ------------------------------------------------
export const getNextCodeRequest = (payload = {}, callback) => ({
  type: GET_NEXT_CODE_REQUEST,
  payload,
  callback,
});

export const getNextCodeSuccess = (data) => ({
  type: GET_NEXT_CODE_SUCCESS,
  payload: data,
});

export const getNextCodeError = (error) => ({
  type: GET_NEXT_CODE_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getNextCodeServiceRequest = async () => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/employees/next_code`, options);
};
