//All Requirements
import {
  GET_REQUIREMENTS_REQUEST,
  GET_REQUIREMENTS_SUCCESS,
  GET_REQUIREMENTS_ERROR,
} from "../../actions/requirements/getRequirements";

//Requirement
import {
  GET_REQUIREMENT_REQUEST,
  GET_REQUIREMENT_SUCCESS,
  GET_REQUIREMENT_ERROR,
} from "../../actions/requirements/getRequirement";

//Update Requirement
import {
  PUT_REQUIREMENT_REQUEST,
  PUT_REQUIREMENT_SUCCESS,
  PUT_REQUIREMENT_ERROR,
} from "../../actions/requirements/putRequirement";

//Create Requirement
import {
  POST_REQUIREMENT_REQUEST,
  POST_REQUIREMENT_SUCCESS,
  POST_REQUIREMENT_ERROR,
} from "../../actions/requirements/postRequirement";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REQUIREMENTS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_REQUIREMENTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_REQUIREMENTS_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case GET_REQUIREMENT_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_REQUIREMENT_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loading: false,
      };
    case GET_REQUIREMENT_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case PUT_REQUIREMENT_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_REQUIREMENT_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_REQUIREMENT_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_REQUIREMENT_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_REQUIREMENT_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_REQUIREMENT_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
