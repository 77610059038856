import { createSelector } from "reselect";

const payrolls = (state) => state.payroll.payrolls;

export const loading = createSelector(payrolls, (state) => state.loading);

export const loadingAction = createSelector(
  payrolls,
  (state) => state.loadingAction
);

export const data = createSelector(payrolls, (state) => state.data);

export const pagination = createSelector(payrolls, (state) => state.pagination);

export const detail = createSelector(payrolls, (state) => state.detail);

export const types = createSelector(payrolls, (state) => state.types);
