import React from "react";
import { Route, Switch } from "react-router-dom";

import Concepts from "../../../modules/Payroll/Concepts";
import NewConcept from "../../../modules/Payroll/Concepts/new";
import ShowConcept from "../../../modules/Payroll/Concepts/show";

export default () => {
  return (
    <Switch>
      <Route exact path="/payroll/concepts" component={Concepts} />
      <Route exact path="/payroll/concepts/new" component={NewConcept} />
      <Route exact path="/payroll/concepts/:id" component={ShowConcept} />
    </Switch>
  );
};
