//Payroll Period
import {
  GET_PERIOD_REQUEST,
  GET_PERIOD_SUCCESS,
  GET_PERIOD_ERROR,
} from "../../actions/periods/getPeriod";

//Snapshot
import {
  GET_SNAPSHOT_REQUEST,
  GET_SNAPSHOT_SUCCESS,
  GET_SNAPSHOT_ERROR,
} from "../../actions/periods/getSnapshot";

//ActivePeriod
import {
  GET_ACTIVE_PERIODS_REQUEST,
  GET_ACTIVE_PERIODS_SUCCESS,
  GET_ACTIVE_PERIODS_ERROR,
} from "../../actions/periods/getActivePeriods";

//ActivePeriod
import {
  GET_HEADER_PERIOD_HISTORY_REQUEST,
  GET_HEADER_PERIOD_HISTORY_SUCCESS,
  GET_HEADER_PERIOD_HISTORY_ERROR,
} from "../../actions/periods/getHeaderPeriodHistory";

//Snapshot
import {
  GET_SNAPSHOT_EXPORT_REQUEST,
  GET_SNAPSHOT_EXPORT_SUCCESS,
  GET_SNAPSHOT_EXPORT_ERROR,
} from "../../actions/periods/getSnapshotExport";

//Create Payroll Period
import {
  POST_PERIOD_REQUEST,
  POST_PERIOD_SUCCESS,
  POST_PERIOD_ERROR,
} from "../../actions/periods/postPeriod";

//Initialize Payroll Period
import {
  PUT_INITIALIZE_PERIOD_REQUEST,
  PUT_INITIALIZE_PERIOD_SUCCESS,
  PUT_INITIALIZE_PERIOD_ERROR,
} from "../../actions/periods/putInitializePeriod";

//Update Payroll Period
import {
  PUT_UPDATE_PERIOD_REQUEST,
  PUT_UPDATE_PERIOD_SUCCESS,
  PUT_UPDATE_PERIOD_ERROR,
} from "../../actions/periods/putUpdatePeriod";

//Approve Payroll Period
import {
  PUT_APPROVE_PERIOD_REQUEST,
  PUT_APPROVE_PERIOD_SUCCESS,
  PUT_APPROVE_PERIOD_ERROR,
} from "../../actions/periods/putApprovePeriod";

//Apply Payroll Period
import {
  PUT_APPLY_PERIOD_REQUEST,
  PUT_APPLY_PERIOD_SUCCESS,
  PUT_APPLY_PERIOD_ERROR,
} from "../../actions/periods/putApplyPeriod";

//Calculate Payroll Period
import {
  PUT_CALCULATE_PERIOD_REQUEST,
  PUT_CALCULATE_PERIOD_SUCCESS,
  PUT_CALCULATE_PERIOD_ERROR,
} from "../../actions/periods/putCalculatePeriod";

//Create Payroll Period
import {
  POST_CONCEPT_TO_PERIOD_REQUEST,
  POST_CONCEPT_TO_PERIOD_SUCCESS,
  POST_CONCEPT_TO_PERIOD_ERROR,
} from "../../actions/periods/postConceptToPeriod";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  snapshot: {
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  activePeriod: {
    loading: false,
    error: false,
    data: [],
  },
  getHeaderHistory: {
    loading: false,
    error: false,
    data: [],
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PERIOD_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_PERIOD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_PERIOD_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    // -------------------------------
    case GET_SNAPSHOT_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_SNAPSHOT_SUCCESS:
      return {
        ...state,
        snapshot: {
          ...action.payload,
        },
        error: false,
        loading: false,
      };
    case GET_SNAPSHOT_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    // -------------------------------
    case GET_ACTIVE_PERIODS_REQUEST:
      return {
        ...state,
        activePeriod: {
          loading: false,
          error: false,
          data: [],
        },
      };
    case GET_ACTIVE_PERIODS_SUCCESS:
      return {
        ...state,
        activePeriod: {
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case GET_ACTIVE_PERIODS_ERROR:
      return {
        ...state,
        activePeriod: {
          loading: false,
          error: action.payload || action.payload.status || 401,
          data: [],
        },
      };
    // -------------------------------
    case GET_HEADER_PERIOD_HISTORY_REQUEST:
      return {
        ...state,
        getHeaderHistory: {
          loading: false,
          error: false,
          data: [],
        },
      };
    case GET_HEADER_PERIOD_HISTORY_SUCCESS:
      return {
        ...state,
        getHeaderHistory: {
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case GET_HEADER_PERIOD_HISTORY_ERROR:
      return {
        ...state,
        getHeaderHistory: {
          loading: false,
          error: action.payload || action.payload.status || 401,
          data: [],
        },
      };
    // -------------------------------
    case PUT_INITIALIZE_PERIOD_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_INITIALIZE_PERIOD_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_INITIALIZE_PERIOD_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case PUT_UPDATE_PERIOD_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_UPDATE_PERIOD_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_UPDATE_PERIOD_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case PUT_APPROVE_PERIOD_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_APPROVE_PERIOD_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_APPROVE_PERIOD_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case PUT_APPLY_PERIOD_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_APPLY_PERIOD_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_APPLY_PERIOD_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case PUT_CALCULATE_PERIOD_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_CALCULATE_PERIOD_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_CALCULATE_PERIOD_ERROR:
      return {
        ...state,
        error: action?.payload || action.payload?.status || 401,
        loadingAction: false,
      };
    case POST_PERIOD_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_PERIOD_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_PERIOD_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case POST_CONCEPT_TO_PERIOD_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case POST_CONCEPT_TO_PERIOD_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case POST_CONCEPT_TO_PERIOD_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case GET_SNAPSHOT_EXPORT_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case GET_SNAPSHOT_EXPORT_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case GET_SNAPSHOT_EXPORT_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
