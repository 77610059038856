import styled from "styled-components";

import { catskillWhite } from "../../../../utils/colors";

const Styled = styled.div`
  input {
    border-radius: 0.3rem;
    &:disabled {
      background: ${catskillWhite};
      cursor: not-allowed;
    }
  }
`;

export default Styled;
