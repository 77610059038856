import React, { useState } from "react";
import { ErrorMessage, getIn } from "formik";
import Skeleton from "react-loading-skeleton";
import cx from "classnames";

import Styled from "./styled";

export default ({
  field = { name: "" },
  form = { touched: false, errors: false },
  iconRight = null,
  isTextArea = false,
  rows = 3,
  containerClass,
  loading,
  type,
  ...props
}) => {
  const { touched, errors } = form;
  const { label } = props;
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  const [show, setShow] = useState(false);
  const [customType, setCustomType] = useState(type || "text");

  const showHidden = (state) => {
    setShow(!state)
    setCustomType(state ? 'password' : 'text')
  }

  return (
    <Styled
      className={cx("relative", {
        [containerClass]: containerClass,
      })}
    >
      <label
        htmlFor={field.name}
        className="block text-gray-700 text-sm font-bold mb-2 ml-4"
      >
        {loading ? <Skeleton width="50%" /> : label}
      </label>
      {loading ? (
        <Skeleton height="3rem" />
      ) : (
        <div className="relative">
          <input
            autoComplete="off"
            type={customType}
            id={field.name}
            className={cx(
              "appearance-none border border-gray-400 focus:border-black bg-transparent w-full text-gray-700 py-3 px-2 leading-tight focus:outline-none truncate",
              {
                "border-red-700": touch && error,
                "border-green-300": touch && !error,
                "border-gray-400": !touch && !error,
              }
            )}
            {...field}
            {...props}
          />
          {type === 'password' &&
            <div role="button" onClick={() => showHidden(show)} className="absolute bottom-0 right-0 h-10 m-1 hover:bg-gray-100 active:bg-gray-200 flex flex-row items-center px-2 rounded-full cursor-pointer">
              {show
                ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                </svg>
                : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
              }
            </div>
          }
        </div>
      )}
      <ErrorMessage
        name={field.name}
        render={() => {
          return touch && error ? (
            <p className="text-xs text-red-700 absolute">{error}</p>
          ) : null;
        }}
      />
    </Styled>
  );
};
