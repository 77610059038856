import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  loadingAction,
  detail,
} from "../../../../store/administration/selectors/companies";
import {
  loading as isLoadingCountries,
  data as countriesData,
} from "../../../../store/administration/selectors/countries";

const ShowCompany = lazy(() => import("./ShowCompany"));

const mapStateToProps = (state) => ({
  isLoading: loading(state),
  isLoadingAction: loadingAction(state),
  company: detail(state),
  isLoadingCountries: isLoadingCountries(state),
  countries: countriesData(state),
});

export default connect(mapStateToProps)(ShowCompany);
