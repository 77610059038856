import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_AREA_REQUEST = "POST_AREA_REQUEST";
export const POST_AREA_SUCCESS = "POST_AREA_SUCCESS";
export const POST_AREA_ERROR = "POST_AREA_ERROR";

//* ACTIONS ------------------------------------------------
export const postAreaRequest = (payload = {}, callback) => ({
  type: POST_AREA_REQUEST,
  payload,
  callback,
});

export const postAreaSuccess = () => ({
  type: POST_AREA_SUCCESS,
});

export const postAreaError = (error) => ({
  type: POST_AREA_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postAreaServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request("/areas", options);
};
