import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_CONCEPT_REQUEST = "GET_CONCEPT_REQUEST";
export const GET_CONCEPT_SUCCESS = "GET_CONCEPT_SUCCESS";
export const GET_CONCEPT_ERROR = "GET_CONCEPT_ERROR";

//* ACTIONS ------------------------------------------------
export const getConceptRequest = (payload = {}, callback) => ({
  type: GET_CONCEPT_REQUEST,
  payload,
  callback,
});

export const getConceptSuccess = (data) => ({
  type: GET_CONCEPT_SUCCESS,
  payload: data,
});

export const getConceptError = (error) => ({
  type: GET_CONCEPT_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getConceptServiceRequest = async (conceptId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/concepts/${conceptId}`, options);
};
