import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_LEGAL_AFP_ALL_REQUEST = "GET_LEGAL_AFP_ALL_REQUEST";
export const GET_LEGAL_AFP_ALL_SUCCESS = "GET_LEGAL_AFP_ALL_SUCCESS";
export const GET_LEGAL_AFP_ALL_ERROR = "GET_LEGAL_AFP_ALL_ERROR";

//* ACTIONS ------------------------------------------------
export const getLegalAFPAllRequest = (payload = {}, callback) => ({
  type: GET_LEGAL_AFP_ALL_REQUEST,
  payload,
  callback,
});

export const getLegalAFPAllSuccess = (data) => ({
  type: GET_LEGAL_AFP_ALL_SUCCESS,
  payload: data,
});

export const getLegalAFPAllError = (error) => ({
  type: GET_LEGAL_AFP_ALL_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getLegalAFPAllServiceRequest = async ({ payrollId, periodId, ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/payrolls/${payrollId}/periods/${periodId}/afp`, options);
};
