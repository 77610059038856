import { lazy } from "react";
import { connect } from "react-redux";
import {
  loadingAction,
  loading,
  detail,
} from "../../../../store/administration/selectors/financial_entities";

import { currentUser } from "../../../../store/auth/selectors";

const ShowFinancialEntitie = lazy(() => import("./ShowFinancialEntitie"));

const mapStateToProps = (state) => ({
  loadingAction: loadingAction(state),
  loading: loading(state),
  detail: detail(state),
  currentUser: currentUser(state),
});

export default connect(mapStateToProps)(ShowFinancialEntitie);
