import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Concept Types
import {
  // action types
  GET_CONCEPT_TYPES_REQUEST,
  // requests
  getConceptTypesServiceRequest,
  // actions
  getConceptTypesSuccess,
  getConceptTypesError,
} from "../../actions/concept_types/getConceptTypes";

//* Concept Type
import {
  // action types
  GET_CONCEPT_TYPE_REQUEST,
  // requests
  getConceptTypeServiceRequest,
  // actions
  getConceptTypeSuccess,
  getConceptTypeError,
} from "../../actions/concept_types/getConceptType";

//* Update Concept Type
import {
  // action types
  PUT_CONCEPT_TYPE_REQUEST,
  // requests
  putConceptTypeServiceRequest,
  // actions
  putConceptTypeSuccess,
  putConceptTypeError,
} from "../../actions/concept_types/putConceptType";

//* Create Concept Type
import {
  // action types
  POST_CONCEPT_TYPE_REQUEST,
  // requests
  postConceptTypeServiceRequest,
  // actions
  postConceptTypeSuccess,
  postConceptTypeError,
} from "../../actions/concept_types/postConceptType";

function* getConceptTypes(action) {
  try {
    const result = yield call(getConceptTypesServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getConceptTypesSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getConceptTypesError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getConceptType(action) {
  try {
    const result = yield call(getConceptTypeServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getConceptTypeSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getConceptTypeError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putConceptType(action) {
  try {
    yield call(putConceptTypeServiceRequest, action.payload);
    yield put(putConceptTypeSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putConceptTypeError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postConceptType(action) {
  try {
    yield call(postConceptTypeServiceRequest, action.payload);
    yield put(postConceptTypeSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postConceptTypeError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* conceptTypesSaga() {
  yield takeLatest(GET_CONCEPT_TYPES_REQUEST, getConceptTypes);
  yield takeLatest(GET_CONCEPT_TYPE_REQUEST, getConceptType);
  yield takeLatest(PUT_CONCEPT_TYPE_REQUEST, putConceptType);
  yield takeLatest(POST_CONCEPT_TYPE_REQUEST, postConceptType);
}

export default conceptTypesSaga;
