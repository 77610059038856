import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_PAYROLL_EMPLOYEE_BENEFIT_REQUEST =
  "POST_PAYROLL_EMPLOYEE_BENEFIT_REQUEST";
export const POST_PAYROLL_EMPLOYEE_BENEFIT_SUCCESS =
  "POST_PAYROLL_EMPLOYEE_BENEFIT_SUCCESS";
export const POST_PAYROLL_EMPLOYEE_BENEFIT_ERROR =
  "POST_PAYROLL_EMPLOYEE_BENEFIT_ERROR";

//* ACTIONS ------------------------------------------------
export const postPayrollEmployeeBenefitRequest = (payload = {}, callback) => ({
  type: POST_PAYROLL_EMPLOYEE_BENEFIT_REQUEST,
  payload,
  callback,
});

export const postPayrollEmployeeBenefitSuccess = () => ({
  type: POST_PAYROLL_EMPLOYEE_BENEFIT_SUCCESS,
});

export const postPayrollEmployeeBenefitError = (error) => ({
  type: POST_PAYROLL_EMPLOYEE_BENEFIT_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postPayrollEmployeeBenefitServiceRequest = async ({
  payrollId,
  benefitId,
  ...data
}) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request(
    `/payrolls/${payrollId}/benefits/${benefitId}/employee`,
    options
  );
};
