import {
  getLegalISRRequest,
  getLegalISRSuccess,
  getLegalISRError,
  getLegalISRServiceRequest,
} from "../../../store/legal/actions/isr/getISR";

export const getLegalISR = (formData) => async (dispatch, getState) => {

  dispatch(getLegalISRRequest());
  try {
    const response = await getLegalISRServiceRequest(formData);
    dispatch(getLegalISRSuccess(response.data));
  } catch (error) {
    dispatch(getLegalISRError(error))
  }
  return Promise.resolve();
};
