import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_ERROR = "GET_COMPANY_ERROR";

//* ACTIONS ------------------------------------------------
export const getCompanyRequest = (payload = {}, callback) => ({
  type: GET_COMPANY_REQUEST,
  payload,
  callback,
});

export const getCompanySuccess = (data) => ({
  type: GET_COMPANY_SUCCESS,
  payload: data,
});

export const getCompanyError = (error) => ({
  type: GET_COMPANY_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getCompanyServiceRequest = async (companyId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/companies/${companyId}`, options);
};
