import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_CONCEPTS_REQUEST = "GET_CONCEPTS_REQUEST";
export const GET_CONCEPTS_SUCCESS = "GET_CONCEPTS_SUCCESS";
export const GET_CONCEPTS_ERROR = "GET_CONCEPTS_ERROR";

//* ACTIONS ------------------------------------------------
export const getConceptsRequest = (payload = {}, callback) => ({
  type: GET_CONCEPTS_REQUEST,
  payload,
  callback,
});

export const getConceptsSuccess = (data) => ({
  type: GET_CONCEPTS_SUCCESS,
  payload: data,
});

export const getConceptsError = (error) => ({
  type: GET_CONCEPTS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getConceptsServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/concepts`, options);
};
