import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_PAYROLL_EMPLOYEE_CONCEPT_REQUEST = "GET_PAYROLL_EMPLOYEE_CONCEPT_REQUEST";
export const GET_PAYROLL_EMPLOYEE_CONCEPT_SUCCESS = "GET_PAYROLL_EMPLOYEE_CONCEPT_SUCCESS";
export const GET_PAYROLL_EMPLOYEE_CONCEPT_ERROR = "GET_PAYROLL_EMPLOYEE_CONCEPT_ERROR";

//* ACTIONS ------------------------------------------------
export const getPayrollEmployeConceptsRequest = (payload = {}, callback) => ({
  type: GET_PAYROLL_EMPLOYEE_CONCEPT_REQUEST,
  payload,
  callback,
});

export const getPayrollEmployeConceptsSuccess = (data) => ({
  type: GET_PAYROLL_EMPLOYEE_CONCEPT_SUCCESS,
  payload: data,
});

export const getPayrollEmployeConceptsError = (error) => ({
  type: GET_PAYROLL_EMPLOYEE_CONCEPT_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getPayrollEmployeConceptsServiceRequest = async (formData) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/periods/${formData.periodId}/employees/${formData.employeeId}`, options);
};
