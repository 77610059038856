import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_DEDUCTION_REQUEST = "GET_DEDUCTION_REQUEST";
export const GET_DEDUCTION_SUCCESS = "GET_DEDUCTION_SUCCESS";
export const GET_DEDUCTION_ERROR = "GET_DEDUCTION_ERROR";

//* ACTIONS ------------------------------------------------
export const getDeductionRequest = (payload = {}, callback) => ({
  type: GET_DEDUCTION_REQUEST,
  payload,
  callback,
});

export const getDeductionSuccess = (data) => ({
  type: GET_DEDUCTION_SUCCESS,
  payload: data,
});

export const getDeductionError = (error) => ({
  type: GET_DEDUCTION_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getDeductionServiceRequest = async (deductionId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/deductions/${deductionId}`, options);
};
