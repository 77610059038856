import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_REQUIREMENT_REQUEST = "POST_REQUIREMENT_REQUEST";
export const POST_REQUIREMENT_SUCCESS = "POST_REQUIREMENT_SUCCESS";
export const POST_REQUIREMENT_ERROR = "POST_REQUIREMENT_ERROR";

//* ACTIONS ------------------------------------------------
export const postRequirementRequest = (payload = {}, callback) => ({
  type: POST_REQUIREMENT_REQUEST,
  payload,
  callback,
});

export const postRequirementSuccess = () => ({
  type: POST_REQUIREMENT_SUCCESS,
});

export const postRequirementError = (error) => ({
  type: POST_REQUIREMENT_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postRequirementServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request("/requirements", options);
};
