import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_EMPLOYEES_REQUEST = "GET_EMPLOYEES_REQUEST";
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_ERROR = "GET_EMPLOYEES_ERROR";

//* ACTIONS ------------------------------------------------
export const getEmployeesRequest = (payload = {}, callback) => ({
  type: GET_EMPLOYEES_REQUEST,
  payload,
  callback,
});

export const getEmployeesSuccess = (data) => ({
  type: GET_EMPLOYEES_SUCCESS,
  payload: data,
});

export const getEmployeesError = (error) => ({
  type: GET_EMPLOYEES_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getAllEmployeesRequest = async ({ small = false, ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  if (small) {
    return request(`/employees/summary`, options);
  } else {
    return request(`/employees`, options);
  }
};
