import {
  getLegalAFPAllRequest,
  getLegalAFPAllSuccess,
  getLegalAFPAllError,
  getLegalAFPAllServiceRequest
} from "../../../store/legal/actions/afp/getAFPAll";

export const getLegalAFPAll = (formData) => async (dispatch, getState) => {
  dispatch(getLegalAFPAllRequest());
  try {
    const response = await getLegalAFPAllServiceRequest(formData);
    dispatch(getLegalAFPAllSuccess(response.data));
  } catch (error) {
    dispatch(getLegalAFPAllError(error))
  }
  return Promise.resolve();
};
