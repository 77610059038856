import React from 'react'
import { map } from "lodash";
import { Field } from "formik";

//customs
import SelectAutocomplete from "../../../../components/Form/SelectAutocomplete";
import Input from "../../../../components/Form/InputText";
import InputDatePicker from "../../../../components/Form/InputDatePicker";

const DescuentoDia = ({ payrolls, formik, loading, employees, onlySee, isUpdating }) => {
    const {
        setFieldValue,
        values,
    } = formik
    return (
        <>
            <div className="mb-3" style={{ borderBottom: '1px solid #e2e8f0', width: '100%' }} />
            <div className="grid grid-cols-2 gap-4 w-full mb-4 relative">
            <Field
                    name="employee_id"
                    label="Empleado"
                    placeholder="Escoge un empleado"
                    component={SelectAutocomplete}
                    options={map(employees.data, (item) => ({
                        ...item,
                        label: `${item.code} - ${item.name} ${item.lastname}`,
                        value: item.id,
                        salary: item.salary
                    }))}
                    clearable={false}
                    loading={employees.loading}
                    disabled={
                        employees.loading
                        || onlySee
                        || isUpdating
                        || loading
                    }
                />
                <Field
                    name="payroll_id"
                    label="Nómina"
                    placeholder="Escoge una nómina"
                    component={SelectAutocomplete}
                    options={map(payrolls.data, (item) => ({
                        label: item.name,
                        value: item.id,
                    }))}
                    isMultiple
                    clearable={false}
                    loading={payrolls.loading}
                    disabled={
                        payrolls.loading 
                        || values.employee_id === ""
                        || onlySee 
                        || isUpdating
                        || loading
                    }
                />
                <Field
                    name="date_governs"
                    label="Fecha Rige"
                    placeholderText="Defina una fecha rige"
                    component={InputDatePicker}
                    selected={values.date_governs}
                    loading={false}
                    onChange={(date) => {
                        setFieldValue("date_governs", date);
                    }}
                    disabled={(onlySee && !isUpdating)}
                    maxDate={null}
                    minDate={null}
                />
                <Field
                    name="comment"
                    label="Comentario"
                    placeholder="Escriba un comentario"
                    component={Input}
                    disabled={(onlySee && !isUpdating)}
                    maxLength={250}
                />
            </div>
        </>
    )
}

export default DescuentoDia
