import {
  getBenefitsHistoryRequest,
  getBenefitsHistorySuccess,
  getBenefitsHistoryError,
  getBenefitsHistoryServiceRequest,
} from "../../../store/benefits/actions/history/getBenefitsHistory";

export const getFetchBenefitsHistory = (formData) => async (dispatch, getState) => {
  dispatch(getBenefitsHistoryRequest());
  try {
    const response = await getBenefitsHistoryServiceRequest(formData);
    dispatch(getBenefitsHistorySuccess(response.data));
  } catch (error) {
    dispatch(getBenefitsHistoryError(error))
  }
  return Promise.resolve();
};

