//Get Data
import {
  GET_LEGAL_AFP_REQUEST,
  GET_LEGAL_AFP_SUCCESS,
  GET_LEGAL_AFP_ERROR,
} from "../../actions/afp/getAFP";

//Get Data all
import {
  GET_LEGAL_AFP_ALL_REQUEST,
  GET_LEGAL_AFP_ALL_SUCCESS,
  GET_LEGAL_AFP_ALL_ERROR,
} from "../../actions/afp/getAFPAll";

//Get Observation Code
import {
  GET_LEGAL_OBSERVATION_CODE_AFP_REQUEST,
  GET_LEGAL_OBSERVATION_CODE_AFP_SUCCESS,
  GET_LEGAL_OBSERVATION_CODE_AFP_ERROR,
} from "../../actions/afp/getObservationCodeAFP";

//Post Legal
import {
  POST_LEGAL_AFP_REQUEST,
  POST_LEGAL_AFP_SUCCESS,
  POST_LEGAL_AFP_ERROR,
} from "../../actions/afp/postSnapshotAFP";

//Put Employee
import {
  PUT_LEGAL_EMPLOYEE_AFP_REQUEST,
  PUT_LEGAL_EMPLOYEE_AFP_SUCCESS,
  PUT_LEGAL_EMPLOYEE_AFP_ERROR,
} from "../../actions/afp/putEmployeAFP";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  getLegalAFP: {
    loading: false,
    error: false,
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  getLegalAFPAll: {
    loading: false,
    error: false,
    data: [],
  },
  getLegalObservationAFP: {
    loading: false,
    error: false,
    data: [],
  },
  postLegalSnapshotAFP: {
    loading: false,
    error: false,
    data: [],
    success: false,
  },
  putLegalEmployeeAFP: {
    loading: false,
    error: false,
    data: [],
  },

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LEGAL_AFP_REQUEST:
      return {
        ...state,
        getLegalAFP: {
          error: false,
          loading: true,
        }
      };
    case GET_LEGAL_AFP_SUCCESS:
      return {
        ...state,
        getLegalAFP: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: false,
          loading: false,
        }
      };
    case GET_LEGAL_AFP_ERROR:
      return {
        ...state,
        getLegalAFP: {
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -----------------------------------------------------------
    case GET_LEGAL_AFP_ALL_REQUEST:
      return {
        ...state,
        getLegalAFPAll: {
          error: false,
          loading: true,
        }
      };
    case GET_LEGAL_AFP_ALL_SUCCESS:
      return {
        ...state,
        getLegalAFPAll: {
          data: action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_LEGAL_AFP_ALL_ERROR:
      return {
        ...state,
        getLegalAFPAll: {
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -----------------------------------------------------------
    case GET_LEGAL_OBSERVATION_CODE_AFP_REQUEST:
      return {
        ...state,
        getLegalObservationAFP: {
          ...state.getLegalObservationAFP,
          error: false,
          loading: true,
        }
      };
    case GET_LEGAL_OBSERVATION_CODE_AFP_SUCCESS:
      return {
        ...state,
        getLegalObservationAFP: {
          ...state.getLegalObservationAFP,
          data: action.payload.data,
          error: false,
          loading: false,
        }
      };
    case GET_LEGAL_OBSERVATION_CODE_AFP_ERROR:
      return {
        ...state,
        getLegalObservationAFP: {
          ...state.getLegalObservationAFP,
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -----------------------------------------------------------
    case POST_LEGAL_AFP_REQUEST:
      return {
        ...state,
        postLegalSnapshotAFP: {
          data: [],
          error: false,
          loading: true,
          success: false,
        }
      };
    case POST_LEGAL_AFP_SUCCESS:
      return {
        ...state,
        postLegalSnapshotAFP: {
          data: action.payload.data,
          error: false,
          loading: false,
          success: true,
        }
      };
    case POST_LEGAL_AFP_ERROR:
      return {
        ...state,
        postLegalSnapshotAFP: {
          error: action.payload || action.payload.status || 401,
          loading: false,
          success: false,
        }
      };
    // -----------------------------------------------------------
    case PUT_LEGAL_EMPLOYEE_AFP_REQUEST:
      return {
        ...state,
        putLegalEmployeeAFP: {
          ...state.putLegalEmployeeAFP,
          error: false,
          loading: true,
        }
      };
    case PUT_LEGAL_EMPLOYEE_AFP_SUCCESS:
      return {
        ...state,
        putLegalEmployeeAFP: {
          ...state.putLegalEmployeeAFP,
          data: action.payload,
          error: false,
          loading: false,
        }
      };
    case PUT_LEGAL_EMPLOYEE_AFP_ERROR:
      return {
        ...state,
        putLegalEmployeeAFP: {
          ...state.putLegalEmployeeAFP,
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };

    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
