import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

// reducers
import rootReducer from "./reducers";

// sagas
import sagas from "./sagas";

export default (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    initialState,
    // compose(applyMiddleware(thunk)),
    composeWithDevTools(applyMiddleware(sagaMiddleware), applyMiddleware(thunk))
  );
  const persistor = persistStore(store);

  sagas.forEach(sagaMiddleware.run);

  return { store, persistor };
};