import React from "react";

const NotFoundPage = () => {
  return (
    <section className="w-screen h-screen flex items-center justify-center">
      <h1 className="font-bold text-6xl mr-5">404</h1>
      <h1>¡Lo sentimos, pagina no encontrada!</h1>
    </section>
  );
};

export default NotFoundPage;
