//All Areas
import {
  GET_AREAS_REQUEST,
  GET_AREAS_SUCCESS,
  GET_AREAS_ERROR,
} from "../../actions/areas/getAreas";

//All Areas
import {
  GET_AREA_PERIODS_REQUEST,
  GET_AREA_PERIODS_SUCCESS,
  GET_AREA_PERIODS_ERROR,
} from "../../actions/areas/getAreaByPeriods";

//Area
import {
  GET_AREA_REQUEST,
  GET_AREA_SUCCESS,
  GET_AREA_ERROR,
} from "../../actions/areas/getArea";

//Update Area
import {
  PUT_AREA_REQUEST,
  PUT_AREA_SUCCESS,
  PUT_AREA_ERROR,
} from "../../actions/areas/putArea";

//Create Area
import {
  POST_AREA_REQUEST,
  POST_AREA_SUCCESS,
  POST_AREA_ERROR,
} from "../../actions/areas/postArea";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
  areaByPeriods: {
    loading: false,
    loadingAction: false,
    error: false,
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_AREAS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_AREAS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_AREAS_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    // ----------------------------------------
    case GET_AREA_PERIODS_REQUEST:
      return {
        ...state,
        areaByPeriods: {
          ...state.areaByPeriods,
          error: false,
          loading: true,
        }
      };
    case GET_AREA_PERIODS_SUCCESS:
      return {
        ...state,
        areaByPeriods: {
          ...state.areaByPeriods,
          ...action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_AREA_PERIODS_ERROR:
      return {
        ...state,
        areaByPeriods: {
          ...state.areaByPeriods,
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // ----------------------------------------
    case GET_AREA_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_AREA_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loading: false,
      };
    case GET_AREA_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    // ----------------------------------------
    case PUT_AREA_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_AREA_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_AREA_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_AREA_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_AREA_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_AREA_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
