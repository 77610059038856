import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_MODULES_BY_USER_REQUEST = "GET_MODULES_BY_USER_REQUEST";
export const GET_MODULES_BY_USER_SUCCESS = "GET_MODULES_BY_USER_SUCCESS";
export const GET_MODULES_BY_USER_ERROR = "GET_MODULES_BY_USER_ERROR";

//* ACTIONS ------------------------------------------------
export const getModulesByUserRequest = (payload = {}, callback) => ({
  type: GET_MODULES_BY_USER_REQUEST,
  payload,
  callback,
});

export const getModulesByUserSuccess = (data) => ({
  type: GET_MODULES_BY_USER_SUCCESS,
  payload: data,
});

export const getModulesByUserError = (error) => ({
  type: GET_MODULES_BY_USER_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getModulesByUserServiceRequest = async (userId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`modules/get_modules/user/${userId}`, options);
};
