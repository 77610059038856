import { lazy } from "react";
import { connect } from "react-redux";

import { getFetchDeductionsHistory } from "../../../hooks/Deductions/History/useFetchDeductions";

const History = lazy(() => import("./History"));

const mapStateToProps = (state) => {
  return ({
    history: state.deductions.history.data,
    pagination: state.deductions.history.pagination,
    loading: state.deductions.history.loading,
    error: state.deductions.history.error,
  })
};

const mapDispatchToProps = dispatch => ({
  getFetchDeductionsHistory: (formData) => dispatch(getFetchDeductionsHistory(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
