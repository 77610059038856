import React from "react";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";

//Customs
import PrivateRoute from "../PrivateRoute";

//Selectors
import { isLogged } from "../../store/auth/selectors";

//* ROUTES *//
import ScheduleAdminRoutes from "./administration";
import ScheduleDashboardRoutes from "./dashboard";

const LegalRoutes = ({ isLogged }) => {
  return (
    <Switch>
      <PrivateRoute
        path="/schedule/administration"
        component={ScheduleAdminRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/schedule/dashboard"
        component={ScheduleDashboardRoutes}
        isAuthenticated={isLogged}
      />
      <Redirect to="/" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isLogged: isLogged(state),
});

export default connect(mapStateToProps)(LegalRoutes);
