import React from "react";
import cx from "classnames";
import Skeleton from "react-loading-skeleton";

// styles
import Styled from "./styled";

export default ({
  onClick = () => {},
  text = "Aceptar",
  isLoading,
  className,
  type = "button",
  disabled = false,
  loader,
}) => {
  return (
    <Styled
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={cx(
        "hover:opacity-75 text-white font-bold py-2 px-4 rounded focus:outline-none",
        {
          "is-loading": isLoading,
          [className]: className,
          "w-full": !className,
        }
      )}
    >
      {loader ? <Skeleton width="5rem" /> : text}
    </Styled>
  );
};
