
//All PayrollsEmployeeConcepts
import {
  GET_PAYROLL_EMPLOYEE_CONCEPT_REQUEST,
  GET_PAYROLL_EMPLOYEE_CONCEPT_SUCCESS,
  GET_PAYROLL_EMPLOYEE_CONCEPT_ERROR,
} from "../../actions/payrollsEmployeeconcepts/getPayrollEmployeeConcepts";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
  types: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYROLL_EMPLOYEE_CONCEPT_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_PAYROLL_EMPLOYEE_CONCEPT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_PAYROLL_EMPLOYEE_CONCEPT_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    default:
      return state;
  }
};
