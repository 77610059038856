import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_CONCEPT_TYPES_REQUEST = "GET_CONCEPT_TYPES_REQUEST";
export const GET_CONCEPT_TYPES_SUCCESS = "GET_CONCEPT_TYPES_SUCCESS";
export const GET_CONCEPT_TYPES_ERROR = "GET_CONCEPT_TYPES_ERROR";

//* ACTIONS ------------------------------------------------
export const getConceptTypesRequest = (payload = {}, callback) => ({
  type: GET_CONCEPT_TYPES_REQUEST,
  payload,
  callback,
});

export const getConceptTypesSuccess = (data) => ({
  type: GET_CONCEPT_TYPES_SUCCESS,
  payload: data,
});

export const getConceptTypesError = (error) => ({
  type: GET_CONCEPT_TYPES_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getConceptTypesServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/concept_types`, options);
};
