import {
  getPayrollHistoryRequest,
  getPayrollHistorySuccess,
  getPayrollHistoryError,
  getPayrollHistoryServiceRequest
} from "../../../store/payroll/actions/histories/getHistory";

export const getPayrollHistory = (formData) => async (dispatch, getState) => {
  dispatch(getPayrollHistoryRequest());
  try {
    const response = await getPayrollHistoryServiceRequest(formData);
    dispatch(getPayrollHistorySuccess(response.data));
  } catch (error) {
    dispatch(getPayrollHistoryError(error))
  }
  return Promise.resolve();
};
