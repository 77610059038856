import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Employee Deductions
import {
  // action types
  GET_DEDUCTIONS_REQUEST,      ////////wwwwwww
  // requests
  getDeductionsServiceRequest,  ////////wwwwwww
  // actions
  getDeductionsSuccess,
  getDeductionsError,
} from "../../actions/deductions/getDeductions";

//* Employee Deduction
import {
  // action types
  GET_DEDUCTION_REQUEST,        ////////wwwwwww
  // requests
  getDeductionServiceRequest,   ////////wwwwwww
  // actions
  getDeductionSuccess,
  getDeductionError,
} from "../../actions/deductions/getDeduction";

//* All Employee Deductions (payroll)
import {
  // action types
  GET_DEDUCTIONS_PAYROLL_REQUEST,
  // requests
  getDeductionsPayrollServiceRequest,
  // actions
  getDeductionsPayrollSuccess,
  getDeductionsPayrollError,
} from "../../actions/deductions/getDeductionsPayrolls";

//* Employee Deduction (payroll)
import {
  // action types
  GET_DEDUCTION_PAYROLL_REQUEST,
  // requests
  getDeductionPayrollServiceRequest,
  // actions
  getDeductionPayrollSuccess,
  getDeductionPayrollError,
} from "../../actions/deductions/getDeductionPayrolls";

//* Update Employee Deduction
import {
  // action types
  PUT_DEDUCTION_REQUEST,
  // requests
  putDeductionServiceRequest,
  // actions
  putDeductionSuccess,
  putDeductionError,
} from "../../actions/deductions/putDeduction";

//* Create Employee Deduction
import {
  // action types
  POST_DEDUCTION_REQUEST,
  // requests
  postDeductionServiceRequest,
  // actions
  postDeductionSuccess,
  postDeductionError,
} from "../../actions/deductions/postDeduction";


function* getDeductions(action) {
  try {
    const result = yield call(getDeductionsServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getDeductionsSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getDeductionsError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getDeduction(action) {
  try {
    const result = yield call(getDeductionServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getDeductionSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getDeductionError(e.response));

    action.callback && action.callback(e.response);
  }
}




function* getDeductionsPayroll(action) {
  try {
    const result = yield call(getDeductionsPayrollServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getDeductionsPayrollSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getDeductionsPayrollError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getDeductionPayroll(action) {
  try {
    const result = yield call(getDeductionPayrollServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getDeductionPayrollSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getDeductionPayrollError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putDeduction(action) {
  try {
    yield call(putDeductionServiceRequest, action.payload);
    yield put(putDeductionSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putDeductionError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postDeduction(action) {
  try {
    yield call(postDeductionServiceRequest, action.payload);
    yield put(postDeductionSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postDeductionError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* deductionsSaga() {
  yield takeLatest(GET_DEDUCTIONS_REQUEST, getDeductions);
  yield takeLatest(GET_DEDUCTION_REQUEST, getDeduction);
  yield takeLatest(GET_DEDUCTIONS_PAYROLL_REQUEST, getDeductionsPayroll);
  yield takeLatest(GET_DEDUCTION_PAYROLL_REQUEST, getDeductionPayroll);
  yield takeLatest(PUT_DEDUCTION_REQUEST, putDeduction);
  yield takeLatest(POST_DEDUCTION_REQUEST, postDeduction);
}

export default deductionsSaga;
