import React from "react";
import { Route, Switch } from "react-router-dom";

import Voucher from "../../../modules/Payroll/Voucher";
import VoucherArea from "../../../modules/Payroll/Voucher/Area";
import VoucherEmployee from "../../../modules/Payroll/Voucher/Employee";

export default () => {
  return (
    <Switch>
      <Route exact path="/payroll/voucher" component={Voucher} />
      <Route exact path="/payroll/voucher/area/periods/:periodId" component={VoucherArea} />
      <Route exact path="/payroll/voucher/employee/periods/:periodId" component={VoucherEmployee} />
    </Switch>
  );
};