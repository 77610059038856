import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_COSTCENTERS_REQUEST = "GET_COSTCENTERS_REQUEST";
export const GET_COSTCENTERS_SUCCESS = "GET_COSTCENTERS_SUCCESS";
export const GET_COSTCENTERS_ERROR = "GET_COSTCENTERS_ERROR";

//* ACTIONS ------------------------------------------------
export const getCostCentersRequest = (payload = {}, callback) => ({
  type: GET_COSTCENTERS_REQUEST,
  payload,
  callback,
});

export const getCostCentersSuccess = (data) => ({
  type: GET_COSTCENTERS_SUCCESS,
  payload: data,
});

export const getCostCentersError = (error) => ({
  type: GET_COSTCENTERS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getCostCentersServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/costcenters`, options);
};
