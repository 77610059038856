import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_DOCUMENTS_REQUEST = "GET_DOCUMENTS_REQUEST";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_ERROR = "GET_DOCUMENTS_ERROR";

//* ACTIONS ------------------------------------------------
export const getDocumentsRequest = (payload = {}, callback) => ({
  type: GET_DOCUMENTS_REQUEST,
  payload,
  callback,
});

export const getDocumentsSuccess = (data) => ({
  type: GET_DOCUMENTS_SUCCESS,
  payload: data,
});

export const getDocumentsError = (error) => ({
  type: GET_DOCUMENTS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getDocumentsServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/documents`, options);
};
