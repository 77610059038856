import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_LINE_BUSINESS_DEFAULT = "POST_LINE_BUSINESS_DEFAULT";
export const POST_LINE_BUSINESS_REQUEST = "POST_LINE_BUSINESS_REQUEST";
export const POST_LINE_BUSINESS_SUCCESS = "POST_LINE_BUSINESS_SUCCESS";
export const POST_LINE_BUSINESS_ERROR = "POST_LINE_BUSINESS_ERROR";

//* ACTIONS ------------------------------------------------
export const postLineBusinessDefault = () => ({
  type: POST_LINE_BUSINESS_DEFAULT,
});

export const postLineBusinessRequest = (payload = {}, callback) => ({
  type: POST_LINE_BUSINESS_REQUEST,
  payload,
  callback,
});

export const postLineBusinessSuccess = () => ({
  type: POST_LINE_BUSINESS_SUCCESS,
});

export const postLineBusinessError = (error) => ({
  type: POST_LINE_BUSINESS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postLineBusinessServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request("/lines", options);
};
