import {
  getRolesRequest,
  getRolesSuccess,
  getRolesError,
  getRolesServiceRequest,
} from "../../../store/security/actions/users/getRoles";

export const getFetchRoles = (formData) => async (dispatch, getState) => {
  dispatch(getRolesRequest());
  try {
    const response = await getRolesServiceRequest(formData);
    dispatch(getRolesSuccess(response.data));
  } catch (error) {
    dispatch(getRolesError(error))
  }
  return Promise.resolve();
};

