import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_UBICATION_REQUEST = "POST_UBICATION_REQUEST";
export const POST_UBICATION_SUCCESS = "POST_UBICATION_SUCCESS";
export const POST_UBICATION_ERROR = "POST_UBICATION_ERROR";

//* ACTIONS ------------------------------------------------
export const postUbicationRequest = (payload = {}, callback) => ({
  type: POST_UBICATION_REQUEST,
  payload,
  callback,
});

export const postUbicationSuccess = () => ({
  type: POST_UBICATION_SUCCESS,
});

export const postUbicationError = (error) => ({
  type: POST_UBICATION_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postUbicationServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request("/ubications", options);
};
