import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_POSITIONS_REQUEST = "GET_POSITIONS_REQUEST";
export const GET_POSITIONS_SUCCESS = "GET_POSITIONS_SUCCESS";
export const GET_POSITIONS_ERROR = "GET_POSITIONS_ERROR";

//* ACTIONS ------------------------------------------------
export const getPositionsRequest = (payload = {}, callback) => ({
  type: GET_POSITIONS_REQUEST,
  payload,
  callback,
});

export const getPositionsSuccess = (data) => ({
  type: GET_POSITIONS_SUCCESS,
  payload: data,
});

export const getPositionsError = (error) => ({
  type: GET_POSITIONS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getPositionsServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/positions`, options);
};
