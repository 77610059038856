import React from "react";
import { Route, Switch } from "react-router-dom";

import Benefits from "../../../modules/Benefits/Benefits";
import NewBenefit from "../../../modules/Benefits/Benefits/new";
import ShowBenefit from "../../../modules/Benefits/Benefits/show";

export default () => {
  return (
    <Switch>
      <Route exact path="/benefits/administration" component={Benefits} />
      <Route exact path="/benefits/administration/new" component={NewBenefit} />
      <Route
        exact
        path="/benefits/administration/:id"
        component={ShowBenefit}
      />
    </Switch>
  );
};
