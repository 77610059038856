import React from "react";
import { Form, Field, Formik } from "formik";

//custom
import Modal from "../../PortalModal/PortalModal";
import SelectAutocomplete from "../../Form/SelectAutocomplete";
import Button from "../../Buttons/Button";
import OutlineButton from "../../Buttons/OutlineButton";

const ChooseCompanyModal = ({
  isActive,
  onSubmit,
  onCancel,
  initialValues,
  companies,
  loadingOptions,
}) => {
  return (
    <Modal isActive={isActive}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {() => {
          return (
            <Form className="w-2/5 bg-white shadow-lg p-5 text-center">
              <h3 className="mb-4 font-bold">
                Seleccione empresa a la que desea cambiar
              </h3>

              <Field
                name="company"
                component={SelectAutocomplete}
                options={companies}
                clearable={false}
                loadingOptions={loadingOptions}
              />
              <div className="w-full flex flex-items-center justify-end pt-5 pb-2">
                <OutlineButton
                  text="Cancelar"
                  className="w-1/4 mr-4"
                  onClick={onCancel}
                />
                <Button
                  type="submit"
                  className="w-1/4"
                  // isLoading={isSubmitting || isLoading}
                  text="Aceptar "
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ChooseCompanyModal;
