import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_LEGAL_EMPLOYEE_SPU_REQUEST =
  "PUT_LEGAL_EMPLOYEE_SPU_REQUEST";
export const PUT_LEGAL_EMPLOYEE_SPU_SUCCESS =
  "PUT_LEGAL_EMPLOYEE_SPU_SUCCESS";
export const PUT_LEGAL_EMPLOYEE_SPU_ERROR =
  "PUT_LEGAL_EMPLOYEE_SPU_ERROR";

//* ACTIONS ------------------------------------------------
export const putLegalEmployeeSPURequest = (payload = {}, callback) => ({
  type: PUT_LEGAL_EMPLOYEE_SPU_REQUEST,
  payload,
  callback,
});

export const putLegalEmployeeSPUSuccess = () => ({
  type: PUT_LEGAL_EMPLOYEE_SPU_SUCCESS,
});

export const putLegalEmployeeSPUError = (error) => ({
  type: PUT_LEGAL_EMPLOYEE_SPU_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putLegalEmployeeSPUServiceRequest = async ({
  employeeId,
  periodId,
  spuId,
  ...data
}) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/payrolls/periods/${periodId}/employee/${employeeId}/spu/${spuId}`, options);
};
