import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_ACTIVE_PERIODS_REQUEST = "GET_ACTIVE_PERIODS_REQUEST";
export const GET_ACTIVE_PERIODS_SUCCESS = "GET_ACTIVE_PERIODS_SUCCESS";
export const GET_ACTIVE_PERIODS_ERROR = "GET_ACTIVE_PERIODS_ERROR";

//* ACTIONS ------------------------------------------------
export const getActivePeriodsRequest = (payload = {}, callback) => ({
  type: GET_ACTIVE_PERIODS_REQUEST,
  payload,
  callback,
});

export const getActivePeriodsSuccess = (data) => ({
  type: GET_ACTIVE_PERIODS_SUCCESS,
  payload: data,
});

export const getActivePeriodsError = (error) => ({
  type: GET_ACTIVE_PERIODS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getActivePeriodsServiceRequest = async ({ payrollId, ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
    params,
  });

  return request(`/payrolls/${payrollId}/periods/current`, options);
};
