//All Employee Deductions
import {
  GET_PAYROLL_EMPLOYEE_DEDUCTIONS_REQUEST,
  GET_PAYROLL_EMPLOYEE_DEDUCTIONS_SUCCESS,
  GET_PAYROLL_EMPLOYEE_DEDUCTIONS_ERROR,
} from "../../actions/employeesDeductions/getEmployeeDeductions";

//Employee Deduction
import {
  GET_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST,
  GET_PAYROLL_EMPLOYEE_DEDUCTION_SUCCESS,
  GET_PAYROLL_EMPLOYEE_DEDUCTION_ERROR,
} from "../../actions/employeesDeductions/getEmployeeDeduction";

//Update Employee Deduction
import {
  PUT_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST,
  PUT_PAYROLL_EMPLOYEE_DEDUCTION_SUCCESS,
  PUT_PAYROLL_EMPLOYEE_DEDUCTION_ERROR,
} from "../../actions/employeesDeductions/putEmployeeDeduction";

//Create Employee Deduction
import {
  POST_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST,
  POST_PAYROLL_EMPLOYEE_DEDUCTION_SUCCESS,
  POST_PAYROLL_EMPLOYEE_DEDUCTION_ERROR,
} from "../../actions/employeesDeductions/postEmployeeDeduction";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  loadingDetail: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYROLL_EMPLOYEE_DEDUCTIONS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_PAYROLL_EMPLOYEE_DEDUCTIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_PAYROLL_EMPLOYEE_DEDUCTIONS_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case GET_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST:
      return {
        ...state,
        error: false,
        loadingDetail: true,
      };
    case GET_PAYROLL_EMPLOYEE_DEDUCTION_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loadingDetail: false,
      };
    case GET_PAYROLL_EMPLOYEE_DEDUCTION_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingDetail: false,
      };
    case PUT_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_PAYROLL_EMPLOYEE_DEDUCTION_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_PAYROLL_EMPLOYEE_DEDUCTION_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_PAYROLL_EMPLOYEE_DEDUCTION_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case POST_PAYROLL_EMPLOYEE_DEDUCTION_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case POST_PAYROLL_EMPLOYEE_DEDUCTION_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
