import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_DEDUCTIONS_HISTORY_REQUEST = "GET_DEDUCTIONS_HISTORY_REQUEST";
export const GET_DEDUCTIONS_HISTORY_SUCCESS = "GET_DEDUCTIONS_HISTORY_SUCCESS";
export const GET_DEDUCTIONS_HISTORY_ERROR = "GET_DEDUCTIONS_HISTORY_ERROR";

//* ACTIONS ------------------------------------------------
export const getDeductionsHistoryRequest = (payload = {}, callback) => ({
  type: GET_DEDUCTIONS_HISTORY_REQUEST,
  payload,
  callback,
});

export const getDeductionsHistorySuccess = (data) => ({
  type: GET_DEDUCTIONS_HISTORY_SUCCESS,
  payload: data,
});

export const getDeductionsHistoryError = (error) => ({
  type: GET_DEDUCTIONS_HISTORY_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getDeductionsHistoryServiceRequest = async ({payrollId, ...params}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/deductions/payroll/${payrollId}/history`, options);
};
