import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_ERROR = "GET_ROLES_ERROR";

//* ACTIONS ------------------------------------------------
export const getRolesRequest = (payload = {}, callback) => ({
  type: GET_ROLES_REQUEST,
  payload,
  callback,
});

export const getRolesSuccess = (data) => ({
  type: GET_ROLES_SUCCESS,
  payload: data,
});

export const getRolesError = (error) => ({
  type: GET_ROLES_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getRolesServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/roles`, options);
};
