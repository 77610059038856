import { 
  getAreaPerdiodsRequest,
  getAreaPerdiodsSuccess,
  getAreaPerdiodsError,
  getAreaPerdiodsServiceRequest,
 } from "../../../store/administration/actions/areas/getAreaByPeriods";

 export const getFetchAreasByPeriods = (formData) => async (dispatch, getState) => {
  dispatch(getAreaPerdiodsRequest());
  try {
    const response = await getAreaPerdiodsServiceRequest(formData);
    dispatch(getAreaPerdiodsSuccess(response.data));
  } catch (error) {
    dispatch(getAreaPerdiodsError(error))
  }
  return Promise.resolve();
};
