import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_APPROVE_PERIOD_REQUEST = "PUT_APPROVE_PERIOD_REQUEST";
export const PUT_APPROVE_PERIOD_SUCCESS = "PUT_APPROVE_PERIOD_SUCCESS";
export const PUT_APPROVE_PERIOD_ERROR =   "PUT_APPROVE_PERIOD_ERROR";

//* ACTIONS ------------------------------------------------
export const putApprovePeriodRequest = (payload = {}, callback) => ({
  type: PUT_APPROVE_PERIOD_REQUEST,
  payload,
  callback,
});

export const putApprovePeriodSuccess = () => ({
  type: PUT_APPROVE_PERIOD_SUCCESS,
});

export const putApprovePeriodError = (error) => ({
  type: PUT_APPROVE_PERIOD_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putApprovePeriodServiceRequest = async (periodId) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/payrolls/periods/${periodId}/approve`, options);
};
