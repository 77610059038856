import React from "react";

import Modal from "../../PortalModal/PortalModal";
import Button from "../../Buttons/Button";
import OutlineButton from "../../Buttons/OutlineButton";

const QuestionModal = ({
  isActive,
  icon: Icon,
  text = "¿Está seguro que desea?",
  onAccept,
  onAcceptTextButton = "Aceptar",
  onCancel,
  onCancelTextButton = "Cancelar",
  isLoading,
}) => {
  return (
    <Modal isActive={isActive}>
      <div className="w-1/4 bg-white shadow p-5 text-center">
        {Icon && <Icon size="3rem" className="mx-auto" />}
        <h3 className="mb-5 text-center">
          <b>{text}</b>
        </h3>
        <Button
          text={onAcceptTextButton}
          className="mb-5 w-3/4"
          onClick={onAccept}
          isLoading={isLoading}
        />
        <OutlineButton
          text={onCancelTextButton}
          className="w-3/4"
          onClick={onCancel}
          disabled={isLoading}
        />
      </div>
    </Modal>
  );
};

export default QuestionModal;
