import {
  getAllPersonnelActionsRequest,
  getAllPersonnelActionsSuccess,
  getAllPersonnelActionsError,
  getAllPersonnelActionsServiceRequest,
} from "../../store/personnelActions/actions/getAllPersonnelActions";

export const getAllPersonnelActions = (formData) => async (dispatch, getState) => {
  dispatch(getAllPersonnelActionsRequest());
  try {
    const response = await getAllPersonnelActionsServiceRequest(formData);
    dispatch(getAllPersonnelActionsSuccess(response.data));
  } catch (error) {
    dispatch(getAllPersonnelActionsError(error))
  }
  return Promise.resolve();
};
