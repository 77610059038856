import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_LEGAL_ISR_REQUEST = "GET_LEGAL_ISR_REQUEST";
export const GET_LEGAL_ISR_SUCCESS = "GET_LEGAL_ISR_SUCCESS";
export const GET_LEGAL_ISR_ERROR = "GET_LEGAL_ISR_ERROR";

//* ACTIONS ------------------------------------------------
export const getLegalISRRequest = (payload = {}, callback) => ({
  type: GET_LEGAL_ISR_REQUEST,
  payload,
  callback,
});

export const getLegalISRSuccess = (data) => ({
  type: GET_LEGAL_ISR_SUCCESS,
  payload: data,
});

export const getLegalISRError = (error) => ({
  type: GET_LEGAL_ISR_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getLegalISRServiceRequest = async ({ months, years, ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/payrolls/isr/months/${months}/years/${years}`, options);
};
