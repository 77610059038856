import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading as loadingEmployees,
  data as employees,
} from "../../../../store/administration/selectors/employees";

import {
  loading as loadingPayrollEmployees,
  data as payrollEmployees,
  pagination,
  loadingAction as loadingActionPayrollEmployees,
} from "../../../../store/payroll/selectors/employees";

import {
  loadingAction as loadingEmployeesDeductionsActions,
  loading as loadingEmployeesDeductions,
  data as employeesDeductions,
  detail as EmployeesDeductionDetail,
  loadingDetail as loadingEmployeesDeductionDetail,
} from "../../../../store/payroll/selectors/employeesDeductions";

import {
  loadingAction as loadingEmployeesBenefitsActions,
  loading as loadingEmployeesBenefits,
  data as employeesBenefits,
  detail as EmployeesBenefitDetail,
  loadingDetail as loadingEmployeesBenefitDetail,
} from "../../../../store/payroll/selectors/employeesBenefits";

import {
  loading as loadingBenefits,
  data as benefits,
} from "../../../../store/benefits/selectors/benefits";

import {
  data as financialEntities,
  loading as loadingFinancialEntities,
} from "../../../../store/administration/selectors/financial_entities";

import {
  data as concepts,
  loading as loadingConcepts,
} from "../../../../store/payroll/selectors/concepts";

const Employees = lazy(() => import("./Employees"));

const mapStateToProps = (state) => ({
  loadingActionPayrollEmployees: loadingActionPayrollEmployees(state),
  isLoadingPayrollEmployees: loadingPayrollEmployees(state),
  payrollEmployees: payrollEmployees(state),
  pagination: pagination(state),
  isLoadingEmployees: loadingEmployees(state),
  employees: employees(state),
  financialEntities: financialEntities(state),
  loadingFinancialEntities: loadingFinancialEntities(state),
  loadingEmployeesDeductions: loadingEmployeesDeductions(state),
  loadingEmployeesDeductionsActions: loadingEmployeesDeductionsActions(state),
  employeesDeductions: employeesDeductions(state),
  concepts: concepts(state),
  loadingConcepts: loadingConcepts(state),
  loadingEmployeesDeductionDetail: loadingEmployeesDeductionDetail(state),
  EmployeesDeductionDetail: EmployeesDeductionDetail(state),
  loadingEmployeesBenefitsActions: loadingEmployeesBenefitsActions(state),
  loadingEmployeesBenefits: loadingEmployeesBenefits(state),
  employeesBenefits: employeesBenefits(state),
  EmployeesBenefitDetail: EmployeesBenefitDetail(state),
  loadingEmployeesBenefitDetail: loadingEmployeesBenefitDetail(state),
  loadingBenefits: loadingBenefits(state),
  benefits: benefits(state),
});

export default connect(mapStateToProps)(Employees);
