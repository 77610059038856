import { lazy } from "react";
import { connect } from "react-redux";
import { getPayrollReport } from "../../../hooks/Payroll/Reports/useFetchReports";
import { getPayrollReportPaymentBAC } from "../../../hooks/Payroll/Reports/useFetchPaymentSalaryBAC";
import { getPayrollReportPaymentCusc } from "../../../hooks/Payroll/Reports/useFetchPaymentSalaryCusc";

const Voucher = lazy(() => import("./Voucher"));

const mapStateToProps = (state) => {
  return ({
    isLoading: state.payroll.reports.loading,
    periods: state.payroll.reports.data,
    isError: state.payroll.reports.error,
    pagination: state.payroll.reports.pagination,
    
    payrolls: state.payroll.payrolls.data,
  })
};

const mapDispatchToProps = dispatch => ({
  getPayrollReport: (formData) => dispatch(getPayrollReport(formData)),
  getPayrollReportPaymentBAC: (formData) => dispatch(getPayrollReportPaymentBAC(formData)),
  getPayrollReportPaymentCusc: (formData) => dispatch(getPayrollReportPaymentCusc(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Voucher);
