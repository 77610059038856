//All CostCenters
import {
  GET_COSTCENTERS_REQUEST,
  GET_COSTCENTERS_SUCCESS,
  GET_COSTCENTERS_ERROR,
} from "../../actions/costcenters/getCostCenters";

//CostCenters
import {
  GET_COSTCENTER_REQUEST,
  GET_COSTCENTER_SUCCESS,
  GET_COSTCENTER_ERROR,
} from "../../actions/costcenters/getCostCenter";

//CostCenters
import {
  GET_COSTCENTER_DETAILS_REQUEST,
  GET_COSTCENTER_DETAILS_SUCCESS,
  GET_COSTCENTER_DETAILS_ERROR,
} from "../../actions/costcenters/getCostCenterDetails";

//Update CostCenters
import {
  PUT_COSTCENTER_REQUEST,
  PUT_COSTCENTER_SUCCESS,
  PUT_COSTCENTER_ERROR,
} from "../../actions/costcenters/putCostCenter";

//Create CostCenters
import {
  POST_COSTCENTER_REQUEST,
  POST_COSTCENTER_SUCCESS,
  POST_COSTCENTER_ERROR,
} from "../../actions/costcenters/postCostCenter";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
  details: {
    loading: false,
    error: false,
    data: {},
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COSTCENTERS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_COSTCENTERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_COSTCENTERS_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case GET_COSTCENTER_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_COSTCENTER_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loading: false,
      };
    case GET_COSTCENTER_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    // get_costcenter_details
    case GET_COSTCENTER_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          error: false,
          loading: true,
        }
      };
    case GET_COSTCENTER_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_COSTCENTER_DETAILS_ERROR:
      return {
        ...state,
        details: {
          data: {},
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // ---------
    case PUT_COSTCENTER_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_COSTCENTER_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_COSTCENTER_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_COSTCENTER_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_COSTCENTER_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_COSTCENTER_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
