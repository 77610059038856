import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_PAYROLLS_REQUEST = "GET_PAYROLLS_REQUEST";
export const GET_PAYROLLS_SUCCESS = "GET_PAYROLLS_SUCCESS";
export const GET_PAYROLLS_ERROR = "GET_PAYROLLS_ERROR";

//* ACTIONS ------------------------------------------------
export const getPayrollsRequest = (payload = {}, callback) => ({
  type: GET_PAYROLLS_REQUEST,
  payload,
  callback,
});

export const getPayrollsSuccess = (data) => ({
  type: GET_PAYROLLS_SUCCESS,
  payload: data,
});

export const getPayrollsError = (error) => ({
  type: GET_PAYROLLS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getPayrollsServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/payrolls`, options);
};
