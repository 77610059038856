import { lazy } from "react";
import { connect } from "react-redux";

const Dashboard = lazy(() => import("./Dashboard"));

const mapStateToProps = (state) => {
  return ({
    isLoading: state.schedule.dashboard.loading,
    dashboard: state.schedule.dashboard.data,
    pagination: state.schedule.dashboard.pagination,
  })
};

export default connect(mapStateToProps)(Dashboard);
