import { lazy } from "react";
import { connect } from "react-redux";
import {
  loadingAction,
  loading,
  detail,
  types,
} from "../../../../store/payroll/selectors/payrolls";

const ShowPayroll = lazy(() => import("./ShowPayroll"));

const mapStateToProps = (state) => ({
  loadingAction: loadingAction(state),
  loading: loading(state),
  payroll: detail(state),
  payrollTypes: types(state),
});

export default connect(mapStateToProps)(ShowPayroll);
