import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_COUNTRY_REQUEST = "PUT_COUNTRY_REQUEST";
export const PUT_COUNTRY_SUCCESS = "PUT_COUNTRY_SUCCESS";
export const PUT_COUNTRY_ERROR = "PUT_COUNTRY_ERROR";

//* ACTIONS ------------------------------------------------
export const putCountryRequest = (payload = {}, callback) => ({
  type: PUT_COUNTRY_REQUEST,
  payload,
  callback,
});

export const putCountrySuccess = () => ({
  type: PUT_COUNTRY_SUCCESS,
});

export const putCountryError = (error) => ({
  type: PUT_COUNTRY_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putCountryServiceRequest = async ({ countryId, ...data }) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/countries/${countryId}`, options);
};
