import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_LINE_BUSINESS_REQUEST = "GET_LINE_BUSINESS_REQUEST";
export const GET_LINE_BUSINESS_SUCCESS = "GET_LINE_BUSINESS_SUCCESS";
export const GET_LINE_BUSINESS_ERROR = "GET_LINE_BUSINESS_ERROR";

//* ACTIONS ------------------------------------------------
export const getLineBusinessRequest = (payload = {}, callback) => ({
  type: GET_LINE_BUSINESS_REQUEST,
  payload,
  callback,
});

export const getLineBusinessSuccess = (data) => ({
  type: GET_LINE_BUSINESS_SUCCESS,
  payload: data,
});

export const getLineBusinessError = (error) => ({
  type: GET_LINE_BUSINESS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getLineBusinessServiceRequest = async (lineBusinessId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/lines/${lineBusinessId}`, options);
};
