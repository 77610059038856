import { lazy } from "react";
import { connect } from "react-redux";
import { getLegalSPU } from "../../../hooks/Legal/SPU/useFetchSPU";
import { getLegalSPUAll } from "../../../hooks/Legal/SPU/useFetchSPUAll";
import { postLegalSPU } from "../../../hooks/Legal/SPU/useFetchSnapshotSPU";
import { putLegalEmployeeSPU } from "../../../hooks/Legal/SPU/useFetchEmployeeSPU";
import { getLegalObservationCodeSPU } from "../../../hooks/Legal/SPU/useFetchObservationCodeSPU";
import { getPayrollHistory } from "../../../hooks/Payroll/Histories/useFetchHistoies";

const SPU = lazy(() => import("./SPU"));

const mapStateToProps = (state) => {
  return({
  dataSPU: state.legal.spu.getLegalSPU.data,
  isLoading: state.legal.spu.getLegalSPU.loading,
  isError: state.legal.spu.getLegalSPU.error,
  pagination: state.legal.spu.getLegalSPU.pagination,
  
  snapshotSPUSuccess: state.legal.spu.postLegalSnapshotSPU.success,
  snapshotSPULoading: state.legal.spu.postLegalSnapshotSPU.loading,
  snapshotSPUError: state.legal.spu.postLegalSnapshotSPU.error,

  dataCodes: state.legal.spu.getLegalObservationSPU.data,
  isLoadingCodes: state.legal.spu.getLegalObservationSPU.loading,
  isErrorCodes: state.legal.spu.getLegalObservationSPU.error,

  isLoadingPut: state.legal.spu.putLegalEmployeeSPU.loading,
  isErrorPut: state.legal.spu.putLegalEmployeeSPU.error,

  payrolls: state.payroll.payrolls.data,
  periods: state.payroll.histories.data,
  periodsLoading: state.payroll.histories.loading,
  companyId: state.authentication?.currentUser?.currentCompany?.id
})};

const mapDispatchToProps = dispatch => ({
  getLegalSPU: (formData) => dispatch(getLegalSPU(formData)),
  getLegalSPUAll: (formData) => dispatch(getLegalSPUAll(formData)),
  postLegalSPU: (formData) => dispatch(postLegalSPU(formData)),
  putLegalEmployeeSPU: (formData) => dispatch(putLegalEmployeeSPU(formData)),
  getLegalObservationCodeSPU: (formData) => dispatch(getLegalObservationCodeSPU(formData)),
  getPayrollHistory: (formData) => dispatch(getPayrollHistory(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SPU);
