import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  data,
  pagination,
  types,
} from "../../../store/payroll/selectors/payrolls";
import { loading as loadingPeriod } from "../../../store/payroll/selectors/periods";

const Payrolls = lazy(() => import("./Payrolls"));

const mapStateToProps = (state) => ({
  isLoading: loading(state),
  payrolls: data(state),
  pagination: pagination(state),
  payrollTypes: types(state),
  loadingPeriod: loadingPeriod(state),
});

export default connect(mapStateToProps)(Payrolls);
