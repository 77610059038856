import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_PAYROLL_REPORT_REQUEST = "GET_PAYROLL_REPORT_REQUEST";
export const GET_PAYROLL_REPORT_SUCCESS = "GET_PAYROLL_REPORT_SUCCESS";
export const GET_PAYROLL_REPORT_ERROR = "GET_PAYROLL_REPORT_ERROR";

//* ACTIONS ------------------------------------------------
export const getPayrollReportRequest = (payload = {}, callback) => ({
  type: GET_PAYROLL_REPORT_REQUEST,
  payload,
  callback,
});

export const getPayrollReportSuccess = (data) => ({
  type: GET_PAYROLL_REPORT_SUCCESS,
  payload: data,
});

export const getPayrollReportError = (error) => ({
  type: GET_PAYROLL_REPORT_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getPayrollReportServiceRequest = async ({ payrollId = 0, ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/payrolls/${payrollId}/reports`, options);
};
