import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_FINANCIAL_SQUARE_TOTAL_REQUEST = "GET_FINANCIAL_SQUARE_TOTAL_REQUEST";
export const GET_FINANCIAL_SQUARE_TOTAL_SUCCESS = "GET_FINANCIAL_SQUARE_TOTAL_SUCCESS";
export const GET_FINANCIAL_SQUARE_TOTAL_ERROR = "GET_FINANCIAL_SQUARE_TOTAL_ERROR";

//* ACTIONS ------------------------------------------------
export const getFinancialSquareTotalRequest = (payload = {}, callback) => ({
  type: GET_FINANCIAL_SQUARE_TOTAL_REQUEST,
  payload,
  callback,
});

export const getFinancialSquareTotalSuccess = (data) => ({
  type: GET_FINANCIAL_SQUARE_TOTAL_SUCCESS,
  payload: data,
});

export const getFinancialSquareTotalError = (error) => ({
  type: GET_FINANCIAL_SQUARE_TOTAL_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getFinancialSquareTotalServiceRequest = async ({ payrollId, ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/deductions/payroll/${payrollId}/financial_square_total`, options);
};
