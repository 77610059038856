import { lazy } from "react";
import { connect } from "react-redux";
import {
  loadingAction,
  loading,
  data,
  pagination,
} from "../../../../store/payroll/selectors/periods";
import {
  loading as loadingPayroll,
  detail as payroll,
} from "../../../../store/payroll/selectors/payrolls";
import { loadingAction as loadingActionPayrollEmployees } from "../../../../store/payroll/selectors/employees";

const Periods = lazy(() => import("./Periods"));

const mapStateToProps = (state) => ({
  employeesConcepts: state.payroll.payrollsEmployeeConcepts.data,
  employeesConceptsLoading: state.payroll.payrollsEmployeeConcepts.loading,
  loading: loading(state),
  loadingAction: loadingAction(state),
  snapshot: data(state),
  pagination: pagination(state),
  loadingPayroll: loadingPayroll(state),
  payroll: payroll(state),
  loadingActionPayrollEmployees: loadingActionPayrollEmployees(state),
});

export default connect(mapStateToProps)(Periods);
