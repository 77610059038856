import { lazy } from "react";
import { connect } from "react-redux";
import { loading } from "../../../../store/payroll/selectors/concepts";
import {
  loading as loadingConceptCategories,
  data as conceptCategories,
} from "../../../../store/administration/selectors/concept_categories";
import {
  loading as loadingConceptTypes,
  data as conceptTypes,
} from "../../../../store/administration/selectors/concept_types";

const NewConcept = lazy(() => import("./NewConcept"));

const mapStateToProps = (state) => ({
  loading: loading(state),
  loadingConceptCategories: loadingConceptCategories(state),
  conceptCategories: conceptCategories(state),
  loadingConceptTypes: loadingConceptTypes(state),
  conceptTypes: conceptTypes(state),
});

export default connect(mapStateToProps)(NewConcept);
