//Get Data
import {
  GET_MONTHS_REQUEST,
  GET_MONTHS_SUCCESS,
  GET_MONTHS_ERROR,
} from "../../actions/months/getMonths";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MONTHS_REQUEST:
      return {
        data: [],
        error: false,
        loading: true,
      };
    case GET_MONTHS_SUCCESS:
      return {
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_MONTHS_ERROR:
      return {
        data: [],
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    // -----------------------------------------------------------

    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
