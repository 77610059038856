import { lazy } from "react";
import { connect } from "react-redux";

import {
  loading as loadingConcepts,
  data as concepts,
} from "../../../../store/payroll/selectors/concepts";
import {
  loading as loadingPayrolls,
  data as payrolls,
} from "../../../../store/payroll/selectors/payrolls";
import {
  loading as loadingEmployees,
  data as employees,
} from "../../../../store/payroll/selectors/employees";
import {
  loading,
  loadingAction,
  detail,
} from "../../../../store/benefits/selectors/benefits";

const ShowBenefit = lazy(() => import("./ShowBenefit"));

const mapStateToProps = (state) => ({
  loadingConcepts: loadingConcepts(state),
  concepts: concepts(state),
  loadingPayrolls: loadingPayrolls(state),
  payrolls: payrolls(state),
  loadingEmployees: loadingEmployees(state),
  employees: employees(state),

  loadingAction: loadingAction(state),
  loading: loading(state),
  detail: detail(state),
});

export default connect(mapStateToProps)(ShowBenefit);
