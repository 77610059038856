import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";

//Customs
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

//Selectors
import { isLogged } from "../store/auth/selectors";

//* ROUTES *//
//* ERRORS *//
import NotFoundPage from "../modules/Auth/Not-Found";
import ForbiddenAccessPage from "../modules/Auth/Forbidden-Access";
import ServerErrorPage from "../modules/Auth/Server-Error";
//* AUTH *//
import LogOutPage from "../modules/Auth/Log-Out";
import AuthRoutes from "./AuthRoutes";
//* DASHBOARD *//
import DashboardRoutes from "./DashboardRoutes";

const Router = ({ isLogged }) => {
  return (
    <BrowserRouter>
      <Switch>
        {/* LOGOUT */}
        <Route exact path="/log-out" component={LogOutPage} />
        {/* ERRORS */}
        <Route exact path="/404" component={NotFoundPage} />
        <Route exact path="/403" component={ForbiddenAccessPage} />
        <Route exact path="/500" component={ServerErrorPage} />
        <PublicRoute
          path="/log-in"
          component={AuthRoutes}
          isAuthenticated={isLogged}
        />
        <PrivateRoute
          path="/"
          component={DashboardRoutes}
          isAuthenticated={isLogged}
        />
      </Switch>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  isLogged: isLogged(state),
});

export default connect(mapStateToProps)(Router);
