import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_FINANCIAL_ENTITIE_REQUEST = "PUT_FINANCIAL_ENTITIE_REQUEST";
export const PUT_FINANCIAL_ENTITIE_SUCCESS = "PUT_FINANCIAL_ENTITIE_SUCCESS";
export const PUT_FINANCIAL_ENTITIE_ERROR = "PUT_FINANCIAL_ENTITIE_ERROR";

//* ACTIONS ------------------------------------------------
export const putFinancialEntitieRequest = (payload = {}, callback) => ({
  type: PUT_FINANCIAL_ENTITIE_REQUEST,
  payload,
  callback,
});

export const putFinancialEntitieSuccess = () => ({
  type: PUT_FINANCIAL_ENTITIE_SUCCESS,
});

export const putFinancialEntitieError = (error) => ({
  type: PUT_FINANCIAL_ENTITIE_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putFinancialEntitieServiceRequest = async ({
  financialEntitieId,
  ...data
}) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/financial_entities/${financialEntitieId}`, options);
};
