/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { get, map, trim } from "lodash";
import { Form, Field, Formik } from "formik";
import cx from "classnames";
import { useSelector, useDispatch } from "react-redux";
import parseISO from "date-fns/parseISO";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { GrDocumentPdf } from "react-icons/gr";
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

// GrDocumentPdf

//customs
import Styled, { useStyles } from "./styled";
import ButtonWithIcon from "../../../components/Buttons/ButtonWithIcon";
import CreateGoBackButton from "../../../components/Buttons/CreateGoBackCustom";
import SelectAutocomplete from "../../../components/Form/SelectAutocomplete";
import Button from "../../../components/Buttons/Button";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import FormikEffect from "../../../utils/FormikEffect";
import ErrorActionMessageModal from "../../../components/Modals/ErrorActionMessage";
import SuccessActionMessage from "../../../components/Modals/SuccessActionMessage";
import QuestionModal from "../../../components/Modals/QuestionModal";
import requestPDF from "../../../utils/requestPDF"

// actions
import { getTypesPersonnelActions } from "../../../hooks/PersonnelActions/getTypesPersonnelActions";
import { getAllEmployees } from "../../../hooks/Administration/Employees/getAllEmployees";
import { postPersonnelActions } from "../../../hooks/PersonnelActions/postPersonnelActions";
// import { putPersonnelActions } from "../../../hooks/PersonnelActions/putPersonnelActions";

//Validators
import { Validators } from "./Validators";

// Formulario segun tipo de acción
import Contratacion from "./grupedAP/Contratacion"
import Control from "./grupedAP/Control"
import DescuentoAdministrativo from "./grupedAP/DescuentoAdministrativo"
import DescDdia from "./grupedAP/DescuentoDia"
import DescuentoHora from "./grupedAP/DescuentoHora"
import Incapacidad from "./grupedAP/Incapacidad"
import PermisoSinPago from "./grupedAP/PermisoSinPago"
import Retiro from "./grupedAP/Retiro"
import Salario from "./grupedAP/Salario"
import Vacacion from "./grupedAP/Vacacion"

const ModalEmployeeAP = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedAP, setSelectedAP] = useState("");
  const [onlySee] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [loadingPDF, setLoadingPDF] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openQuestionModal, setOpenQuestionModal] = useState({ open: false, values: null });

  const [payrolls, setPayrolls] = useState({ data: [], loading: false });
  const user_id = useSelector(state => state.authentication.user_id)
  const typesAP = useSelector(state => state.personnelActions.typesPersonnelActions);
  const employees = useSelector(state => state.administration.employees);
  const post = useSelector(state => state.personnelActions.postPersonnelActions);
  const put = useSelector(state => state.personnelActions.putPersonnelActions);
  const loading = post.loading || put.loading;

  useEffect(() => {
    dispatch(getTypesPersonnelActions())
    dispatch(getAllEmployees({
      small: true,
      filter: {
        status: 1,
        chief: "S", // este filtro solo es en crear.
      }
    }))
  }, [])

  useEffect(() => {
    if (post.success) {
      setCurrentData(post.data)
    }
    if (put.success) {
      setCurrentData(put.data)
    }
  }, [post.success, put.success])

  const onSubmitCreated = async (values) => {
    const employee = {
      personnel_action_type_id: get(values.personnel_action_type_id, "value", ""),
      payroll_ids: values.payroll_id.map((item) => item.value),
      employee_id: get(values.employee_id, "value", ""),
      date_governs: values.date_governs ? moment(values.date_governs).format("YYYY-MM-DD") : '',
      start_date: values.start_date ? moment(values.start_date).format("YYYY-MM-DD") : '',
      finish_date: values.finish_date ? moment(values.finish_date).format("YYYY-MM-DD") : '',
      comment: trim(values.comment),
      user_id: trim(values.user_id),
      current_salary: trim(values.current_salary),
      new_salary: trim(values.new_salary),
      quantity: Number(trim(values.quantity)),
      amount: Number(trim(values.amount)),
    }

    setOpenQuestionModal({ open: false, values: null })
    await dispatch(postPersonnelActions(employee))
    setIsUpdating(true)
  }

  // const onSubmitUpdate = async (id, values) => {
  //   const employee = {
  //     personnel_action_type_id: get(values.personnel_action_type_id, "value", ""),
  //     payroll_id: get(values.payroll_id, "value", ""),
  //     employee_id: get(values.employee_id, "value", ""),
  //     date_governs: values.date_governs ? moment(values.date_governs).format("YYYY-MM-DD") : '',
  //     start_date: values.start_date ? moment(values.start_date).format("YYYY-MM-DD") : '',
  //     finish_date: values.finish_date ? moment(values.finish_date).format("YYYY-MM-DD") : '',
  //     comment: trim(values.comment),
  //     user_id: trim(values.user_id),
  //     current_salary: trim(values.current_salary),
  //     new_salary: trim(values.new_salary),
  //     quantity: Number(trim(values.quantity)),
  //     amount: Number(trim(values.amount)),
  //   }
  //   await dispatch(putPersonnelActions(id, employee));
  // }

  const onCancel = () => {
    history.push("/home")
  }

  const onSubmit = (values) => {
    setOpenQuestionModal({ open: true, values })
  }

  const onChange = (values, setFieldValue) => {
    const { nextValues, prevValues } = values

    if (nextValues?.personnel_action_type_id?.value !== prevValues?.personnel_action_type_id?.value) {
      setSelectedAP(nextValues?.personnel_action_type_id);

      setFieldValue("payroll_id", "")
      setFieldValue("employee_id", "")
      setFieldValue("date_governs", "")
      setFieldValue("start_date", "")
      setFieldValue("finish_date", "")
      setFieldValue("comment", "")
      setFieldValue("new_salary", "")
      setFieldValue("quantity", "")
      setFieldValue("amount", "")
    }
    if (nextValues?.employee_id?.value !== prevValues?.employee_id?.value) {
      if (!!(nextValues?.employee_id?.value)) {

        setFieldValue("payroll_id", "")
        setPayrolls((state) => ({ ...state, data: nextValues?.employee_id?.payrolls || [] }))
      }
    }
    if (nextValues?.employee_id?.value !== prevValues?.employee_id?.value) {
      if (selectedAP?.grouped === "Salario") {
      } else {
      }
    }
  }

  const getCorrespondendtForm = (key, formik) => {
    switch (key) {
      case "Contratacion": return <Contratacion payrolls={payrolls} employees={employees} formik={formik} loading={loading} onlySee={onlySee} isUpdating={isUpdating} />;
      case "Control": return <Control payrolls={payrolls} employees={employees} formik={formik} loading={loading} onlySee={onlySee} isUpdating={isUpdating} />;
      case "Desc_dia": return <DescDdia payrolls={payrolls} employees={employees} formik={formik} loading={loading} onlySee={onlySee} isUpdating={isUpdating} />;
      case "Desc_hora": return <DescuentoHora payrolls={payrolls} employees={employees} formik={formik} loading={loading} onlySee={onlySee} isUpdating={isUpdating} />;
      case "Desc_monto": return <DescuentoAdministrativo payrolls={payrolls} employees={employees} formik={formik} loading={loading} onlySee={onlySee} isUpdating={isUpdating} />;
      case "Incapacidad": return <Incapacidad payrolls={payrolls} employees={employees} formik={formik} loading={loading} onlySee={onlySee} isUpdating={isUpdating} />;
      case "Incapacidad_sin_desc": return <Incapacidad payrolls={payrolls} employees={employees} formik={formik} loading={loading} onlySee={onlySee} isUpdating={isUpdating} />;
      case "Permiso_sin_pago": return <PermisoSinPago payrolls={payrolls} employees={employees} formik={formik} loading={loading} onlySee={onlySee} isUpdating={isUpdating} />;
      case "Retiro": return <Retiro payrolls={payrolls} employees={employees} formik={formik} loading={loading} onlySee={onlySee} isUpdating={isUpdating} />;
      case "Salario": return <Salario payrolls={payrolls} employees={employees} formik={formik} loading={loading} onlySee={onlySee} isUpdating={isUpdating} />;
      case "Vacacion": return <Vacacion payrolls={payrolls} employees={employees} formik={formik} loading={loading} onlySee={onlySee} isUpdating={isUpdating} />;
      default: return null;
    }
  }

  const generatePDF = async (current, load) => {
    setLoadingPDF(load)
    try {
      await requestPDF({
        path: `/personnel_actions/${current?.id}/reportPDF`,
        namePDF: `AP - empleado ${current?.employee?.code} - ${current?.employee?.name} ${current?.employee?.lastname}.pdf`,
      })
      setLoadingPDF(0)
    } catch (err) {
      console.log(err)
      setLoadingPDF(0)
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAcceptQuestion = () => {
    onSubmitCreated(openQuestionModal.values)
  }
  const onCancelQuestion = () => {
    setOpenQuestionModal((question) => ({ ...question, open: false }))
  }

  return (
    <>
      <Styled className="w-full h-full bg-white rounded p-5">
        <div className="w-full grid grid-cols-3 py-4">
          <CreateGoBackButton onClick={onCancel} />
          <div>
            <h3 className="font-bold text-center">{`${isUpdating ? "Editar" : "Crear"} Acción de personal`}</h3>
          </div>
        </div>
        <Formik
          validationSchema={Validators(selectedAP?.grouped)}
          initialValues={{
            personnel_action_type_id: onlySee || isUpdating ? ({
              value: get(currentData, "personnel_action_type.id", ""),
              label: get(currentData, "personnel_action_type.name", ""),
            }) : '',
            payroll_id: onlySee || isUpdating ? ({
              value: get(currentData, "payroll.id", ""),
              label: get(currentData, "payroll.name", ""),
            }) : '',
            employee_id: onlySee || isUpdating ? ({
              value: get(currentData, "employee.id", ""),
              label: `${get(currentData, "employee.code", "")} - ${get(currentData, "employee.name", "")} ${get(currentData, "employee.lastname", "")}`,
            }) : '',
            date_governs: onlySee || isUpdating ? (currentData.date_governs ? parseISO(currentData.date_governs) : "") : '',
            start_date: onlySee || isUpdating ? (currentData.start_date ? parseISO(currentData.start_date) : "") : '',
            finish_date: onlySee || isUpdating ? (currentData.finish_date ? parseISO(currentData.finish_date) : "") : '',
            comment: onlySee || isUpdating ? get(currentData, "comment", "") : '',
            new_salary: onlySee || isUpdating ? get(currentData, "new_salary", "") : '',
            quantity: onlySee || isUpdating ? get(currentData, "quantity", "") : '',
            amount: onlySee || isUpdating ? get(currentData, "amount", "") : '',

            user_id: user_id.toString(),
          }}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values);
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return (
              <Form className="w-full flex flex-wrap flex-col justify-around items-end mt-4">
                {!isUpdating &&
                  <div className="grid grid-cols-2 gap-4 w-full mb-4 relative">
                    <FormikEffect onChange={(e) => onChange(e, formik.setFieldValue)} />
                    <Field
                      name="personnel_action_type_id"
                      label="Tipos de acción de personal"
                      placeholder="Escoge un tipo de acción de personal"
                      component={SelectAutocomplete}
                      options={map(typesAP.data, (item) => ({
                        ...item,
                        label: item.name,
                        value: item.id,
                        grouped: item.grouped,
                      }))}
                      clearable={false}
                      loading={typesAP.loading}
                      disabled={typesAP.loading || onlySee || isUpdating}
                    />
                    <Box className={classes.btn}>
                      <Tooltip title={!selectedAP ? "Seleccione una AP" : "Descripción de la AP"} placement="top">
                        <Box>
                          <IconButton
                            classes={{ root: classes.btnMui }}
                            aria-describedby={id}
                            onClick={handleClick}
                            type="button"
                            disabled={!selectedAP}
                          >
                            <HelpOutlineIcon />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </Box>
                  </div>
                }

                {!isUpdating && getCorrespondendtForm(selectedAP?.grouped, formik)}

                {isUpdating &&
                  <Box mr="auto">
                    <table>
                      <thead>
                        <tr>
                          <th>Empleado</th>
                          <th>Nómina</th>
                          <th>Descargar PDF</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.map((item, i) => (
                          <tr>
                            <td className="my-1 pr-5">{`${get(item, "employee.code")} - ${get(item, "employee.name")} ${get(item, "employee.lastname")}`}</td>
                            <td className="my-1 px-5">{get(item, "payroll.name")}</td>
                            <td className="my-1 pl-5" style={{ display: "flex", justifyContent: "center" }}>
                              <ButtonWithIcon
                                title="Generar reporte"
                                onClick={() => generatePDF(item, (i + 1))}
                                icon={<GrDocumentPdf size="1.50rem" style={{ filter: "invert(100%)" }} />}
                                isLoading={loadingPDF === (i + 1)}
                                className="bg-green ml-3 "
                                disabled={loadingPDF > 0}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                }
                <div className="w-full flex items-center justify-end py-5">
                  <OutlineButton
                    text="Salir"
                    onClick={onCancel}
                    className="ml-3 w-24"
                    disabled={loadingPDF > 0}
                  />
                  {!isUpdating &&
                    <Button
                      type="submit"
                      text={"Crear"}
                      className={cx("ml-3 w-24", {
                        "is-loading": loading,
                      })}
                    />
                  }
                </div>
              </Form>
            );
          }}
        </Formik>
      </Styled>
      <QuestionModal
        isActive={openQuestionModal.open}
        text="La Acción de personal creada no podra ser editada, ¿desea continuar?"
        onAccept={onAcceptQuestion}
        onCancel={onCancelQuestion}
      />
      <SuccessActionMessage isActive={post.success || put.success} text="Operación realizada con éxito" />
      <ErrorActionMessageModal
        isActive={post.error || put.error}
        text={post?.message?.response?.data?.error || put?.message?.response?.data?.error || "Surgió un error"}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.paper}>
          {selectedAP.description}
        </div>
      </Popover>
    </>
  );
};

export default ModalEmployeeAP;
