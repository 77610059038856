import React from "react";
import { FiLogOut } from "react-icons/fi";

import Modal from "../../PortalModal/PortalModal";
import Button from "../../Buttons/Button";
import OutlineButton from "../../Buttons/OutlineButton";

const CloseSesionModal = ({ isActive, onAccept, onCancel }) => {
  return (
    <Modal isActive={isActive} onClose={onCancel}>
      <div className="w-1/4 bg-white shadow p-5 text-center">
        <FiLogOut size="3rem" className="mx-auto" />
        <h3 className="mb-5 text-center">
          <b>¿Está seguro que desea Cerrar Sesión?</b>
        </h3>
        <Button text="Aceptar" className="mb-5 w-3/4" onClick={onAccept} />
        <OutlineButton text="Cancelar" className="w-3/4" onClick={onCancel} />
      </div>
    </Modal>
  );
};

export default CloseSesionModal;
