import { combineReducers } from "redux";

import deductions from "./deductions";
import financialSquare from "./financialSquare";
import history from "./history";

export default combineReducers({
  deductions,
  financialSquare,
  history,
});
