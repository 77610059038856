import React from "react";
import cx from "classnames";

// styles
import Styled from "./styled";

export default ({
  onClick = () => {},
  text = "Aceptar",
  isLoading,
  className,
  type = "button",
  ...props
}) => {
  return (
    <Styled
      type={type}
      onClick={onClick}
      className={cx(
        "bg-transparent  text-gray-600 font-semibold py-2 px-4 focus:outline-none rounded",
        {
          "is-loading": isLoading,
          [className]: className,
          "w-full": !className,
        }
      )}
    >
      {text}
    </Styled>
  );
};
