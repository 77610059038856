import { lazy } from "react";
import { connect } from "react-redux";
import {
  loadingAction,
  loading,
  data,
  pagination,
} from "../../../../store/payroll/selectors/periods";
import { loadingAction as loadingActionPayrollEmployees } from "../../../../store/payroll/selectors/employees";

import { getHeaderPeriodHistory } from "../../../../hooks/Payroll/Payrolls/getHeaderPeriodHistory"

const Periods = lazy(() => import("./Periods"));

const mapStateToProps = (state) => {
  return ({
    employeesConcepts: state.payroll.payrollsEmployeeConcepts.data,
    employeesConceptsLoading: state.payroll.payrollsEmployeeConcepts.loading,
    loading: loading(state),
    loadingAction: loadingAction(state),
    snapshot: data(state),
    pagination: pagination(state),
    loadingPayroll: state.payroll.periods.getHeaderHistory.loading,
    payroll: state.payroll.periods.getHeaderHistory.data,
    loadingActionPayrollEmployees: loadingActionPayrollEmployees(state),
  })
};

const mapDispatchToProps = dispatch => ({
  getHeaderPeriodHistory: (formData) => dispatch(getHeaderPeriodHistory(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Periods);
