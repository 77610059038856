import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_YEARS_REQUEST = "GET_YEARS_REQUEST";
export const GET_YEARS_SUCCESS = "GET_YEARS_SUCCESS";
export const GET_YEARS_ERROR = "GET_YEARS_ERROR";

//* ACTIONS ------------------------------------------------
export const getYearsRequest = (payload = {}, callback) => ({
  type: GET_YEARS_REQUEST,
  payload,
  callback,
});

export const getYearsSuccess = (data) => ({
  type: GET_YEARS_SUCCESS,
  payload: data,
});

export const getYearsError = (error) => ({
  type: GET_YEARS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getYearsServiceRequest = async (params) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/years`, options);
};
