import { lazy } from "react";
import { connect } from "react-redux";

import { currentUser } from "../../../../store/auth/selectors";

const ShowScheduleControl = lazy(() => import("./ShowScheduleControl"));

const mapStateToProps = (state) => {
  return ({
    employees: state.administration.employees,
    getByEmployee: state.schedule.schedule_control.getByEmployee,
    postSchedule: state.schedule.schedule_control.postSchedule,
    currentUser: currentUser(state),
  })
};

export default connect(mapStateToProps)(ShowScheduleControl);
