import React from "react";

import { FaArrowCircleLeft } from "react-icons/fa";

const CreateGoBackButton = ({ onClick }) => {

  return (
    <button
      onClick={onClick}
      className="flex items-center hover:opacity-75 bg-transparent focus:outline-none"
    >
      <FaArrowCircleLeft size="1.5rem" className="mr-2" />
      <p>Regresar</p>
    </button>
  );
};

export default CreateGoBackButton;
