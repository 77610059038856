import React from "react";
import { Link } from "react-router-dom";
import { map, get, sortBy } from "lodash";
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const MenuItem = ({
  submodules = [],
  active,
}) => (
  <Collapse in={active} timeout="auto" className="duration-300 bg-gray-300" unmountOnExit>
    <List component="div" disablePadding dense >
      {map(sortBy(submodules, "name"), (submodule, index) => (
        <ListItem
          button
          dense
          component={Link}
          to={submodule.path}
          key={index}
        >
          <ListItemText primary={get(submodule, "name", "--")} />
        </ListItem>
      )
      )}
    </List>
  </Collapse>
);

export default MenuItem;
