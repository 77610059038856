import {
  getLegalISSSAllRequest,
  getLegalISSSAllSuccess,
  getLegalISSSAllError,
  getLegalISSSAllServiceRequest
} from "../../../store/legal/actions/isss/getISSSAll";

export const getLegalISSSAll = (formData) => async (dispatch, getState) => {
  dispatch(getLegalISSSAllRequest());
  try {
    const response = await getLegalISSSAllServiceRequest(formData);
    dispatch(getLegalISSSAllSuccess(response.data));
  } catch (error) {
    dispatch(getLegalISSSAllError(error))
  }
  return Promise.resolve();
};
