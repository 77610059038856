import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_ALL_LINE_BUSINESS_REQUEST = "GET_ALL_LINE_BUSINESS_REQUEST";
export const GET_ALL_LINE_BUSINESS_SUCCESS = "GET_ALL_LINE_BUSINESS_SUCCESS";
export const GET_ALL_LINE_BUSINESS_ERROR = "GET_ALL_LINE_BUSINESS_ERROR";

//* ACTIONS ------------------------------------------------
export const getAllLineBusinessRequest = (payload = {}, callback) => ({
  type: GET_ALL_LINE_BUSINESS_REQUEST,
  payload,
  callback,
});

export const getAllLineBusinessSuccess = (data) => ({
  type: GET_ALL_LINE_BUSINESS_SUCCESS,
  payload: data,
});

export const getAllLineBusinessError = (error) => ({
  type: GET_ALL_LINE_BUSINESS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getAllLineBusinessServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/lines`, options);
};
