import request, { Methods, withToken } from "../../../utils/request";
import { getUserCompany } from "../../../utils/constants";

export const PUT_PERSONNEL_ACTIONS_DEFAULT = "PUT_PERSONNEL_ACTIONS_DEFAULT";
export const PUT_PERSONNEL_ACTIONS_REQUEST = "PUT_PERSONNEL_ACTIONS_REQUEST";
export const PUT_PERSONNEL_ACTIONS_SUCCESS = "PUT_PERSONNEL_ACTIONS_SUCCESS";
export const PUT_PERSONNEL_ACTIONS_ERROR = "PUT_PERSONNEL_ACTIONS_ERROR";

//* ACTIONS ------------------------------------------------
export const putPersonnelActionsDefault = () => ({
  type: PUT_PERSONNEL_ACTIONS_DEFAULT,
});

export const putPersonnelActionsRequest = (payload = {}, callback) => ({
  type: PUT_PERSONNEL_ACTIONS_REQUEST,
  payload,
  callback,
});

export const putPersonnelActionsSuccess = (data) => ({
  type: PUT_PERSONNEL_ACTIONS_SUCCESS,
  payload: data,
});

export const putPersonnelActionsError = (error) => ({
  type: PUT_PERSONNEL_ACTIONS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putPersonnelActionsServiceRequest = async (id, formData) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: formData,
  });
  return request(`/personnel_actions/${id}`, options);
};
