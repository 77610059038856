import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_LEGAL_SPU_REQUEST = "POST_LEGAL_SPU_REQUEST";
export const POST_LEGAL_SPU_SUCCESS = "POST_LEGAL_SPU_SUCCESS";
export const POST_LEGAL_SPU_ERROR = "POST_LEGAL_SPU_ERROR";

//* ACTIONS ------------------------------------------------
export const postLegalSPURequest = (payload = {}, callback) => ({
  type: POST_LEGAL_SPU_REQUEST,
  payload,
  callback,
});

export const postLegalSPUSuccess = (data) => ({
  type: POST_LEGAL_SPU_SUCCESS,
  payload: data,
});

export const postLegalSPUError = (error) => ({
  type: POST_LEGAL_SPU_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postLegalSPUServiceRequest = async ({ payrollId, periodId, ...data }) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });
  return request(`/payrolls/${payrollId}/periods/${periodId}/spu`, options);
};
