import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_DEDUCTION_REQUEST = "PUT_DEDUCTION_REQUEST";
export const PUT_DEDUCTION_SUCCESS = "PUT_DEDUCTION_SUCCESS";
export const PUT_DEDUCTION_ERROR = "PUT_DEDUCTION_ERROR";

//* ACTIONS ------------------------------------------------
export const putDeductionRequest = (payload = {}, callback) => ({
  type: PUT_DEDUCTION_REQUEST,
  payload,
  callback,
});

export const putDeductionSuccess = () => ({
  type: PUT_DEDUCTION_SUCCESS,
});

export const putDeductionError = (error) => ({
  type: PUT_DEDUCTION_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putDeductionServiceRequest = async ({ deductionId, ...data }) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/employee/deductions/${deductionId}`, options);
};
