import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_CONCEPT_TYPE_REQUEST = "PUT_CONCEPT_TYPE_REQUEST";
export const PUT_CONCEPT_TYPE_SUCCESS = "PUT_CONCEPT_TYPE_SUCCESS";
export const PUT_CONCEPT_TYPE_ERROR = "PUT_CONCEPT_TYPE_ERROR";

//* ACTIONS ------------------------------------------------
export const putConceptTypeRequest = (payload = {}, callback) => ({
  type: PUT_CONCEPT_TYPE_REQUEST,
  payload,
  callback,
});

export const putConceptTypeSuccess = () => ({
  type: PUT_CONCEPT_TYPE_SUCCESS,
});

export const putConceptTypeError = (error) => ({
  type: PUT_CONCEPT_TYPE_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putConceptTypeServiceRequest = async ({
  conceptTypeId,
  ...data
}) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/concept_types/${conceptTypeId}`, options);
};
