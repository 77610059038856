/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createRef, useEffect, Fragment } from "react";
import { FaCaretDown } from "react-icons/fa";
import { map, get, find, isEmpty } from "lodash";
import { useSelector } from "react-redux"
import * as storage from "localforage";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';

// custom
import MenuItem from "../MenuItem/MenuItem";
import CloseSesionModal from "../../Modals/CloseSesion";
import ChooseCompanyModal from "../../Modals/ChooseCompany";
import SideBarMenu from "../../../utils/sideBarMenu";
import ChooseCompanyQuestion from "../../Modals/ChooseCompanyQuestion";
import RefreshTokenPage from "../../../modules/Auth/Refresh-Token";
import SmallLogo from '../../logos/SmallLogo'
import Staffi from '../../logos/staffi'

import { getCompaniesRequest } from "../../../store/administration/actions/companies/getCompanies";
import {
  setUserCompanyRequest,
  setUserCompanySuccess,
} from "../../../store/auth/actions/setUserCompany";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',

    "& .c-bg-primary": {
      backgroundColor: theme.palette.primary.main
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#efeff6'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  activeOpen: {
    "& .MuiListItemText-root": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF"
    },
    "& .MuiListItemIcon-root": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF"
    }
  },
  activeClosed: {
    "& .MuiListItemText-root": {
      backgroundColor: 'transparent',
      color: "#FFFFFF"
    },
    "& .MuiListItemIcon-root": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF"
    }
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& .MuiListItem-root": {
      minHeight: 56
    },
    "& .MuiListItemText-root": {
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      paddingLeft: 10,
      whiteSpace: 'nowrap'
    },
    "& .MuiListItemIcon-root": {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    }
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    "& .MuiListItem-root": {
      minHeight: 56
    },
    "& .MuiListItemText-root": {
      paddingLeft: 10,
      whiteSpace: 'nowrap'
    },
    "& .MuiListItemIcon-root": {
      borderRadius: '5px',
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    gap: '16px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listItemIcon: {
    minWidth: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  staffiIcon: {
    minWidth: 130
  },

}));

export default function TemporaryDrawer({
  isLogged,
  isRefreshingToken,
  children,
  history,
  location,
  dispatch,
  isLoadingCompanies,
  companies,
  currentUser,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const { name, user_id } = useSelector(state => state.authentication);
  const modules = useSelector(state => state.authentication.modules)
  const ff = useSelector(state => state.authentication)

  const [modal, setModal] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);
  const [companyModal, setCompanyModal] = useState(false);
  const [companyQuestionModal, setCompanyQuestionModal] = useState(false);
  const [companySelected, setCompanySelected] = useState(null);
  const [sideBar, setSideBar] = useState([])

  useEffect(() => {
    /*
      - elimina del menú los menú  a los que no tiene acceso
      Nota: acá solo se evalúa si aparece o nó en el  menú.
      si quiere conocer si tiene o no permiso a la ruta debe dirigirse a /src/routes/PrivateRoute
    */
    const primaryMenu = []
    try {
      const idCompany = currentUser?.currentCompany?.id;
      const validModules = modules?.find((e) => e?.id === idCompany)

      for (const module of get(validModules, "module", [])) {
        if (isEmpty(module.privileges)) {
          // evalua los menú que no tienen privegios designados y da acceso a todos los submodulos
          const currentMenu = SideBarMenu.find((menu) => menu.name === module.name)
          primaryMenu.push(currentMenu)
        } else {
          // evalua los menú que si tienen privilegios designados y da acceso solo a los designados
          const currentMenu = SideBarMenu.find((menu) => menu.name === module.name)
          const secondaryMenu = []
          for (const smenu of get(currentMenu, "submodules", [])) {
            for (const smodule of get(module, "privileges", [])) {
              if (smenu.path.includes(smodule.url)) {
                secondaryMenu.push(smenu)
              }
            }
          }
          currentMenu.submodules = secondaryMenu
          primaryMenu.push(currentMenu)
        }
      }
      setSideBar(primaryMenu)
      // setSideBar(SideBarMenu) // para pruebas habilite este
    } catch (error) {
      setSideBar(primaryMenu)
    }
  }, [modules])

  useEffect(() => {
    if (currentUser?.currentCompany?.id) {
      if (JSON.parse(localStorage.getItem("idC")) !== currentUser?.currentCompany?.id) {
        localStorage.setItem("idC", currentUser?.currentCompany?.id)
        if (location.pathname !== "/home") {
          setTimeout(() => {
            window.location.replace("/home")
          }, 1000);
        }
      }
    }
  }, [localStorage.getItem("idC")])

  const onLogout = () => history.push("/log-out");

  const handleOpenCompanyModal = async () => {
    setCompanyModal(true);
    if (companies.length === 0) {
      return await new Promise((resolve, reject) => {
        dispatch(
          getCompaniesRequest({ userId: user_id }, (error, response) => {
            if (error) {
              return reject(error);
            }
            return resolve();
          })
        );
      });
    }
  };

  const onChangeCompany = async (values) => {
    const {
      company: { value },
    } = values;

    const currentUserCompany = Number(get(currentUser, "currentCompany.id"));
    const currentCompanySelected = Number(value);

    if (currentCompanySelected === currentUserCompany) {
      return setCompanyModal(false);
    } else {
      setCompanySelected(currentCompanySelected);
      setCompanyQuestionModal(true);
    }
  };

  const handleCompanyQuestionModal = async () => {

    try {

      setCompanyModal(false);
      setCompanyQuestionModal(false);
      const currentCompany = find(companies, ["id", companySelected]);

      dispatch(setUserCompanyRequest())
      dispatch(setUserCompanySuccess({currentCompany: currentCompany}))

      await storage.setItem("companyId", get(currentCompany, "id"));
      await storage.setItem("userId", user_id);

      // return await new Promise((resolve, reject) => {
      //   console.log("se ejecuto la promesa")
      //   dispatch(
      //     setUserCompanyRequest(currentCompany, (error, response) => {
      //       if (error) {
      //         return reject(error);
      //       }
      //       console.log(response)
      //       // return history.push("/");
      //       // return window.location.replace("/home")
      //       resolve()
      //     })
      //   );
      // });
    } catch (error) {
      console.log(error)
      dispatch(setUserCompanyRequest(error))
    }
  };

  if (!isLogged) return null;

  if (isRefreshingToken) {
    return <RefreshTokenPage />;
  }

  const proccessName = (nameUser) => {
    let userName = {
      firstName: nameUser,
      capitalLetter: ""
    }

    try {
      const index = nameUser.indexOf(" ")
      userName.firstName = nameUser.substr(0, index)
    } catch (error) { }
    try {
      userName.capitalLetter = nameUser.substr(0, 1)
    } catch (error) { }


    return userName;
  }
  // -----------------------------


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickAway = () => {
    setOptionSelected(null)
  };

  const setActive = ({ name }) => () => {
    handleDrawerOpen()
    setOptionSelected(state => state === name ? null : name)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <SmallLogo />
          </IconButton>
          <div className="flex items-center justify-between w-full text-black">
            <div className="flex items-center text-black">
              <h2>Staffi -</h2>
              <button
                className="ml-1 bg-transparent text-gray-900 focus:outline-none hover:opacity-50"
                onClick={handleOpenCompanyModal}
              >
                <h2>{get(currentUser, "currentCompany.name", "")}</h2>
              </button>
            </div>
            <div
              onClick={() => setModal(true)}
              className="flex items-center mr-5 hover:opacity-75 cursor-pointer"
            >
              <p>
                <b>{proccessName(name).firstName}</b>
              </p>
              <div className="w-10 h-10 c-bg-primary rounded-full flex items-center justify-center text-white ml-3">
                <p>
                  <b>{proccessName(name).capitalLetter}</b>
                </p>
              </div>
              <FaCaretDown size="1.2rem" className="text-primary ml-3" />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <Staffi className={classes.staffiIcon} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <ClickAwayListener onClickAway={handleClickAway}>
          <List>
            {map(sideBar, (option, index) => (
              <Fragment key={option?.name} >
                <ListItem
                  className={document?.location?.pathname?.includes(`${option.path}/`) ? open ? classes.activeOpen : classes.activeClosed : ""}
                  button
                  onClick={() => setOptionSelected(setActive(option))}
                >
                  <ListItemIcon
                    className="iconMenu"
                    title={option?.name}
                    classes={{ root: classes.listItemIcon }}
                  >
                    {option?.icon}
                  </ListItemIcon>
                  <ListItemText primary={option?.name} />
                </ListItem>
                <MenuItem submodules={option?.submodules} active={option?.name === optionSelected} />
              </Fragment>
            ))}
          </List>
        </ClickAwayListener>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div>
          <div className="layout-content">{children}</div>
          <CloseSesionModal
            isActive={modal}
            onAccept={onLogout}
            onCancel={() => setModal(false)}
          />
          <ChooseCompanyModal
            isActive={companyModal}
            onCancel={() => setCompanyModal(false)}
            initialValues={{
              company: {
                value: get(currentUser, "currentCompany.id"),
                label: get(currentUser, "currentCompany.name"),
              },
            }}
            companies={map(companies, (company) => ({
              value: company.id,
              label: company.name,
            }))}
            loadingOptions={isLoadingCompanies}
            onSubmit={onChangeCompany}
          />
          <ChooseCompanyQuestion
            isActive={companyQuestionModal}
            onAccept={handleCompanyQuestionModal}
            onCancel={() => setCompanyQuestionModal(false)}
          />
        </div>
      </main>
    </div>
  );
}
