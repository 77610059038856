import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_CONCEPT_TO_PERIOD_REQUEST = "POST_CONCEPT_TO_PERIOD_REQUEST";
export const POST_CONCEPT_TO_PERIOD_SUCCESS = "POST_CONCEPT_TO_PERIOD_SUCCESS";
export const POST_CONCEPT_TO_PERIOD_ERROR = "POST_CONCEPT_TO_PERIOD_ERROR";

//* ACTIONS ------------------------------------------------
export const postConceptToPeriodRequest = (payload = {}, callback) => ({
  type: POST_CONCEPT_TO_PERIOD_REQUEST,
  payload,
  callback,
});

export const postConceptToPeriodSuccess = () => ({
  type: POST_CONCEPT_TO_PERIOD_SUCCESS,
});

export const postConceptToPeriodError = (error) => ({
  type: POST_CONCEPT_TO_PERIOD_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postConceptToPeriodServiceRequest = async ({
  periodId,
  ...data
}) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request(`/payrolls/periods/${periodId}/concepts`, options);
};
