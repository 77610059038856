import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_CONCEPT_CATEGORY_REQUEST = "GET_CONCEPT_CATEGORY_REQUEST";
export const GET_CONCEPT_CATEGORY_SUCCESS = "GET_CONCEPT_CATEGORY_SUCCESS";
export const GET_CONCEPT_CATEGORY_ERROR = "GET_CONCEPT_CATEGORY_ERROR";

//* ACTIONS ------------------------------------------------
export const getConceptCategoryRequest = (payload = {}, callback) => ({
  type: GET_CONCEPT_CATEGORY_REQUEST,
  payload,
  callback,
});

export const getConceptCategorySuccess = (data) => ({
  type: GET_CONCEPT_CATEGORY_SUCCESS,
  payload: data,
});

export const getConceptCategoryError = (error) => ({
  type: GET_CONCEPT_CATEGORY_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getConceptCategoryServiceRequest = async (conceptCategoryId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/concept_categories/${conceptCategoryId}`, options);
};
