import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_ERROR = "GET_CITIES_ERROR";

//* ACTIONS ------------------------------------------------
export const getCitiesRequest = (payload = {}, callback) => ({
  type: GET_CITIES_REQUEST,
  payload,
  callback,
});

export const getCitiesSuccess = (data) => ({
  type: GET_CITIES_SUCCESS,
  payload: data,
});

export const getCitiesError = (error) => ({
  type: GET_CITIES_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getCitiesServiceRequest = async ({ stateId, ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/countries/states/${stateId}/cities`, options);
};
