import request, { Methods, withToken } from "../../../utils/request";

//* ACTIONTYPES --------------------------------------------
export const GET_CURRENT_USER_REQUEST = "GET_CURRENT_USER_REQUEST";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_ERROR = "GET_CURRENT_USER_ERROR";

//* ACTIONS ------------------------------------------------
export const getCurrentUserRequest = (callback) => ({
  type: GET_CURRENT_USER_REQUEST,
  payload: null,
  callback,
});

export const getCurrentUserSuccess = (data) => ({
  type: GET_CURRENT_USER_SUCCESS,
  payload: data,
});

export const getCurrentUserError = (error) => ({
  type: GET_CURRENT_USER_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const currentUserRequest = async () => {
  const options = await withToken({ method: Methods.GET });

  return request("/oauth/me", options);
};
