//Employees
import {
  GET_PAYROLL_EMPLOYEES_REQUEST,
  GET_PAYROLL_EMPLOYEES_SUCCESS,
  GET_PAYROLL_EMPLOYEES_ERROR,
} from "../../actions/employees/getEmployees";

//Add employee to payroll
import {
  POST_PAYROLL_EMPLOYEE_REQUEST,
  POST_PAYROLL_EMPLOYEE_SUCCESS,
  POST_PAYROLL_EMPLOYEE_ERROR,
} from "../../actions/employees/postEmployee";

//Delete employee from payroll
import {
  DELETE_PAYROLL_EMPLOYEE_REQUEST,
  DELETE_PAYROLL_EMPLOYEE_SUCCESS,
  DELETE_PAYROLL_EMPLOYEE_ERROR,
} from "../../actions/employees/deleteEmployee";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYROLL_EMPLOYEES_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_PAYROLL_EMPLOYEES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_PAYROLL_EMPLOYEES_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case POST_PAYROLL_EMPLOYEE_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case POST_PAYROLL_EMPLOYEE_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case POST_PAYROLL_EMPLOYEE_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case DELETE_PAYROLL_EMPLOYEE_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case DELETE_PAYROLL_EMPLOYEE_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case DELETE_PAYROLL_EMPLOYEE_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
