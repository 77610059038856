import React from "react";
import { Route, Switch } from "react-router-dom";

import SPU from "../../../modules/Legal/SPU";

export default () => {
  return (
    <Switch>
      <Route exact path="/legal/spu" component={SPU} />
    </Switch>
  );
};