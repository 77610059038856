import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_FINANCIAL_ENTITIE_REQUEST = "GET_FINANCIAL_ENTITIE_REQUEST";
export const GET_FINANCIAL_ENTITIE_SUCCESS = "GET_FINANCIAL_ENTITIE_SUCCESS";
export const GET_FINANCIAL_ENTITIE_ERROR = "GET_FINANCIAL_ENTITIE_ERROR";

//* ACTIONS ------------------------------------------------
export const getFinancialEntitieRequest = (payload = {}, callback) => ({
  type: GET_FINANCIAL_ENTITIE_REQUEST,
  payload,
  callback,
});

export const getFinancialEntitieSuccess = (data) => ({
  type: GET_FINANCIAL_ENTITIE_SUCCESS,
  payload: data,
});

export const getFinancialEntitieError = (error) => ({
  type: GET_FINANCIAL_ENTITIE_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getFinancialEntitieServiceRequest = async (financialEntitieId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/financial_entities/${financialEntitieId}`, options);
};
