import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_LINE_BUSINESS_DEFAULT = "PUT_LINE_BUSINESS_DEFAULT";
export const PUT_LINE_BUSINESS_REQUEST = "PUT_LINE_BUSINESS_REQUEST";
export const PUT_LINE_BUSINESS_SUCCESS = "PUT_LINE_BUSINESS_SUCCESS";
export const PUT_LINE_BUSINESS_ERROR = "PUT_LINE_BUSINESS_ERROR";

//* ACTIONS ------------------------------------------------
export const putLineBusinessDefault = () => ({
  type: PUT_LINE_BUSINESS_DEFAULT,
});

export const putLineBusinessRequest = (payload = {}, callback) => ({
  type: PUT_LINE_BUSINESS_REQUEST,
  payload,
  callback,
});

export const putLineBusinessSuccess = () => ({
  type: PUT_LINE_BUSINESS_SUCCESS,
});

export const putLineBusinessError = (error) => ({
  type: PUT_LINE_BUSINESS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putLineBusinessServiceRequest = async ({
  lineBusinessId,
  ...data
}) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/lines/${lineBusinessId}`, options);
};
