import { lazy } from "react";
import { connect } from "react-redux";

const Show = lazy(() => import("./Show"));

const mapStateToProps = (state) => {
  return({
  loading: state.administration.settings.loading,
  detail: state.administration.settings.detail,
  loadingAction: state.administration.settings.putSetting.loading,
  isSuccess: state.administration.settings.putSetting.success,
  isError: state.administration.settings.putSetting.error,
  message: state.administration.settings.putSetting.message,
  auth: state.authentication
})};

export default connect(mapStateToProps)(Show);
