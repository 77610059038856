import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Companies
import {
  // action types
  GET_COMPANIES_REQUEST,
  // requests
  getCompaniesServiceRequest,
  // actions
  getCompaniesSuccess,
  getCompaniesError,
} from "../../actions/companies/getCompanies";

//* Employee
import {
  // action types
  GET_COMPANY_REQUEST,
  // requests
  getCompanyServiceRequest,
  // actions
  getCompanySuccess,
  getCompanyError,
} from "../../actions/companies/getCompany";

//* Update Employee
import {
  // action types
  PUT_COMPANY_REQUEST,
  // requests
  putCompanyServiceRequest,
  // actions
  putCompanySuccess,
  putCompanyError,
} from "../../actions/companies/putCompany";

//* Create Employee
import {
  // action types
  POST_COMPANY_REQUEST,
  // requests
  postCompanyServiceRequest,
  // actions
  postCompanySuccess,
  postCompanyError,
} from "../../actions/companies/postCompany";

function* getCompanies(action) {
  try {
    const result = yield call(getCompaniesServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getCompaniesSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getCompaniesError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getCompany(action) {
  try {
    const result = yield call(getCompanyServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getCompanySuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getCompanyError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putCompany(action) {
  try {
    yield call(putCompanyServiceRequest, action.payload);
    yield put(putCompanySuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putCompanyError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postCompany(action) {
  try {
    yield call(postCompanyServiceRequest, action.payload);
    yield put(postCompanySuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postCompanyError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* companiesSaga() {
  yield takeLatest(GET_COMPANIES_REQUEST, getCompanies);
  yield takeLatest(GET_COMPANY_REQUEST, getCompany);
  yield takeLatest(PUT_COMPANY_REQUEST, putCompany);
  yield takeLatest(POST_COMPANY_REQUEST, postCompany);
}

export default companiesSaga;
