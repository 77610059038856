import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_COSTCENTER_DETAILS_REQUEST = "GET_COSTCENTER_DETAILS_REQUEST";
export const GET_COSTCENTER_DETAILS_SUCCESS = "GET_COSTCENTER_DETAILS_SUCCESS";
export const GET_COSTCENTER_DETAILS_ERROR = "GET_COSTCENTER_DETAILS_ERROR";

//* ACTIONS ------------------------------------------------
export const getCostCenterDetailsRequest = (payload = {}, callback) => ({
  type: GET_COSTCENTER_DETAILS_REQUEST,
  payload,
  callback,
});

export const getCostCenterDetailsSuccess = (data) => ({
  type: GET_COSTCENTER_DETAILS_SUCCESS,
  payload: data,
});

export const getCostCenterDetailsError = (error) => ({
  type: GET_COSTCENTER_DETAILS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getCostCenterDetailsServiceRequest = async (costCenterId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/costcenters/${costCenterId}/details`, options);
};
