import React from "react";
import { Route, Switch } from "react-router-dom";

import Aprove from "../../../modules/PersonnelActions/Aprove";

export default () => {
  return (
    <Switch>
      <Route exact path="/personnel-actions/approval" component={Aprove} />
    </Switch>
  );
};