import React from "react";
import { Route, Switch } from "react-router-dom";

import Dasboard from "../../../modules/Schedule/Dasboard";

export default () => {
  return (
    <Switch>
      <Route exact path="/schedule/dashboard" component={Dasboard} />
    </Switch>
  );
};
