import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import LogInPage from "../modules/Auth/Log-In";
import ForgotPasswordPage from "../modules/Auth/Forgot-Password";

const AuthRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/log-in" component={LogInPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Redirect to="/log-in" />
        {/* <Redirect from="*" to="/404" push={false} /> */}
      </Switch>
    </>
  );
};

export default AuthRoutes;
