import { createSelector } from "reselect";

const countries = (state) => state.administration.countries;

export const loading = createSelector(countries, (state) => state.loading);

export const loadingAction = createSelector(
  countries,
  (state) => state.loadingAction
);

export const data = createSelector(countries, (state) => state.data);

export const pagination = createSelector(
  countries,
  (state) => state.pagination
);

export const detail = createSelector(countries, (state) => state.detail);
