import React from "react";
import { Route, Switch } from "react-router-dom";

import History from "../../../modules/Deductions/History";

export default () => {
  return (
    <Switch>
      <Route exact path="/deductions/historical" component={History} />
    </Switch>
  );
};
