import {
  getEmployeesRequest,
  getEmployeesSuccess,
  getEmployeesError,
  getAllEmployeesRequest
} from "../../../store/administration/actions/employees/getEmployees";

export const getAllEmployees = (formData) => async (dispatch, getState) => {
  dispatch(getEmployeesRequest());
  try {
    const response = await getAllEmployeesRequest(formData);
    dispatch(getEmployeesSuccess(response.data));
  } catch (error) {
    dispatch(getEmployeesError(error))
  }
  return Promise.resolve();
};
