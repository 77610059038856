import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  loadingAction,
  data,
  pagination,
} from "../../../store/administration/selectors/employees";

const Report = lazy(() => import("./Report"));

const mapStateToProps = (state) => {
  return ({
    lastRoutes: state.routes.routes,
    isLoading: loading(state),
    loadingAction: loadingAction(state),
    employees: data(state),
    pagination: pagination(state),
    unsuscribeSuccess: state.administration.employees.unsuscribe.success,
    unsuscribeError: state.administration.employees.unsuscribe.error,
    unsuscribeLoading: state.administration.employees.unsuscribe.loading,
    currentUserId: state.authentication.user_id,
  })
};

export default connect(mapStateToProps)(Report);
