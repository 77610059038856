import { lazy } from "react";
import { connect } from "react-redux";

import { currentUser } from "../../../../store/auth/selectors";

const ShowLineBusiness = lazy(() => import("./ShowLineBusiness"));

const mapStateToProps = (state) => ({
  loading: state.administration.line_business.loading,
  detail: state.administration.line_business.detail,
  loadingAction: state.administration.line_business.putLineBusiness.loading,
  isSuccess: state.administration.line_business.putLineBusiness.success,
  isError: state.administration.line_business.putLineBusiness.error,
  message: state.administration.line_business.putLineBusiness.message,
  currentUser: currentUser(state),
});

export default connect(mapStateToProps)(ShowLineBusiness);
