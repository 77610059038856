import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_COMPANY_REQUEST = "POST_COMPANY_REQUEST";
export const POST_COMPANY_SUCCESS = "POST_COMPANY_SUCCESS";
export const POST_COMPANY_ERROR = "POST_COMPANY_ERROR";

//* ACTIONS ------------------------------------------------
export const postCompanyRequest = (payload = {}, callback) => ({
  type: POST_COMPANY_REQUEST,
  payload,
  callback,
});

export const postCompanySuccess = () => ({
  type: POST_COMPANY_SUCCESS,
});

export const postCompanyError = (error) => ({
  type: POST_COMPANY_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postCompanyServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request("/companies", options);
};
