import { lazy } from "react";
import { connect } from "react-redux";
import { getLegalAFP } from "../../../hooks/Legal/AFP/useFetchAFP";
import { getLegalAFPAll } from "../../../hooks/Legal/AFP/useFetchAFPAll";
import { postLegalAFP } from "../../../hooks/Legal/AFP/useFetchSnapshotAFP";
import { putLegalEmployeeAFP } from "../../../hooks/Legal/AFP/useFetchEmployeeAFP";
import { getLegalObservationCodeAFP } from "../../../hooks/Legal/AFP/useFetchObservationCodeAFP";
import { getPayrollHistory } from "../../../hooks/Payroll/Histories/useFetchHistoies";

const AFP = lazy(() => import("./AFP"));

const mapStateToProps = (state) => {
  return({
  dataAFP: state.legal.afp.getLegalAFP.data,
  isLoading: state.legal.afp.getLegalAFP.loading,
  isError: state.legal.afp.getLegalAFP.error,
  pagination: state.legal.afp.getLegalAFP.pagination,
  
  dataAFPAll: state.legal.afp.getLegalAFPAll.data,
  isLoadingAll: state.legal.afp.getLegalAFPAll.loading,
  isErrorAll: state.legal.afp.getLegalAFPAll.error,

  snapshotAFPData: state.legal.afp.postLegalSnapshotAFP.data,
  snapshotAFPSuccess: state.legal.afp.postLegalSnapshotAFP.success,
  snapshotAFPLoading: state.legal.afp.postLegalSnapshotAFP.loading,
  snapshotAFPError: state.legal.afp.postLegalSnapshotAFP.error,

  dataCodes: state.legal.afp.getLegalObservationAFP.data,
  isLoadingCodes: state.legal.afp.getLegalObservationAFP.loading,
  isErrorCodes: state.legal.afp.getLegalObservationAFP.error,

  isLoadingPut: state.legal.afp.putLegalEmployeeAFP.loading,
  isErrorPut: state.legal.afp.putLegalEmployeeAFP.error,

  payrolls: state.payroll.payrolls.data,
  periods: state.payroll.histories.data,
  periodsLoading: state.payroll.histories.loading,
})};

const mapDispatchToProps = dispatch => ({
  getLegalAFP: (formData) => dispatch(getLegalAFP(formData)),
  getLegalAFPAll: (formData) => dispatch(getLegalAFPAll(formData)),
  postLegalAFP: (formData) => dispatch(postLegalAFP(formData)),
  putLegalEmployeeAFP: (formData) => dispatch(putLegalEmployeeAFP(formData)),
  getLegalObservationCodeAFP: (formData) => dispatch(getLegalObservationCodeAFP(formData)),
  getPayrollHistory: (formData) => dispatch(getPayrollHistory(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AFP);
