import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_CONCEPT_TYPE_REQUEST = "POST_CONCEPT_TYPE_REQUEST";
export const POST_CONCEPT_TYPE_SUCCESS = "POST_CONCEPT_TYPE_SUCCESS";
export const POST_CONCEPT_TYPE_ERROR = "POST_CONCEPT_TYPE_ERROR";

//* ACTIONS ------------------------------------------------
export const postConceptTypeRequest = (payload = {}, callback) => ({
  type: POST_CONCEPT_TYPE_REQUEST,
  payload,
  callback,
});

export const postConceptTypeSuccess = () => ({
  type: POST_CONCEPT_TYPE_SUCCESS,
});

export const postConceptTypeError = (error) => ({
  type: POST_CONCEPT_TYPE_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postConceptTypeServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request("/concept_types", options);
};
