import {
  getuserRequest,
  getuserSuccess,
  getuserError,
  getuserServiceRequest,
} from "../../../store/security/actions/users/getUser";

export const getFetchUsers = (formData) => async (dispatch, getState) => {

  dispatch(getuserRequest());
  try {
    const response = await getuserServiceRequest(formData);
    dispatch(getuserSuccess(response.data));
  } catch (error) {
    dispatch(getuserError(error))
  }
  return Promise.resolve();
};

