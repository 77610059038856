import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_CONCEPT_CATEGORY_REQUEST = "POST_CONCEPT_CATEGORY_REQUEST";
export const POST_CONCEPT_CATEGORY_SUCCESS = "POST_CONCEPT_CATEGORY_SUCCESS";
export const POST_CONCEPT_CATEGORY_ERROR = "POST_CONCEPT_CATEGORY_ERROR";

//* ACTIONS ------------------------------------------------
export const postConceptCategoryRequest = (payload = {}, callback) => ({
  type: POST_CONCEPT_CATEGORY_REQUEST,
  payload,
  callback,
});

export const postConceptCategorySuccess = () => ({
  type: POST_CONCEPT_CATEGORY_SUCCESS,
});

export const postConceptCategoryError = (error) => ({
  type: POST_CONCEPT_CATEGORY_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postConceptCategoryServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request("/concept_categories", options);
};
