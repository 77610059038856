import { createSelector } from "reselect";

const concepts = (state) => state.payroll.concepts;

export const loading = createSelector(concepts, (state) => state.loading);

export const loadingAction = createSelector(
  concepts,
  (state) => state.loadingAction
);

export const data = createSelector(concepts, (state) => state.data);

export const pagination = createSelector(concepts, (state) => state.pagination);

export const detail = createSelector(concepts, (state) => state.detail);
