import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_CONCEPT_CATEGORIES_REQUEST = "GET_CONCEPT_CATEGORIES_REQUEST";
export const GET_CONCEPT_CATEGORIES_SUCCESS = "GET_CONCEPT_CATEGORIES_SUCCESS";
export const GET_CONCEPT_CATEGORIES_ERROR = "GET_CONCEPT_CATEGORIES_ERROR";

//* ACTIONS ------------------------------------------------
export const getConceptCategoriesRequest = (payload = {}, callback) => ({
  type: GET_CONCEPT_CATEGORIES_REQUEST,
  payload,
  callback,
});

export const getConceptCategoriesSuccess = (data) => ({
  type: GET_CONCEPT_CATEGORIES_SUCCESS,
  payload: data,
});

export const getConceptCategoriesError = (error) => ({
  type: GET_CONCEPT_CATEGORIES_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getConceptCategoriesServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/concept_categories`, options);
};
