//All Concept Types
import {
  GET_CONCEPT_TYPES_REQUEST,
  GET_CONCEPT_TYPES_SUCCESS,
  GET_CONCEPT_TYPES_ERROR,
} from "../../actions/concept_types/getConceptTypes";

//Concept Type
import {
  GET_CONCEPT_TYPE_REQUEST,
  GET_CONCEPT_TYPE_SUCCESS,
  GET_CONCEPT_TYPE_ERROR,
} from "../../actions/concept_types/getConceptType";

//Update Concept Type
import {
  PUT_CONCEPT_TYPE_REQUEST,
  PUT_CONCEPT_TYPE_SUCCESS,
  PUT_CONCEPT_TYPE_ERROR,
} from "../../actions/concept_types/putConceptType";

//Create Concept Type
import {
  POST_CONCEPT_TYPE_REQUEST,
  POST_CONCEPT_TYPE_SUCCESS,
  POST_CONCEPT_TYPE_ERROR,
} from "../../actions/concept_types/postConceptType";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONCEPT_TYPES_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_CONCEPT_TYPES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_CONCEPT_TYPES_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case GET_CONCEPT_TYPE_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_CONCEPT_TYPE_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loading: false,
      };
    case GET_CONCEPT_TYPE_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case PUT_CONCEPT_TYPE_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_CONCEPT_TYPE_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_CONCEPT_TYPE_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_CONCEPT_TYPE_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_CONCEPT_TYPE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_CONCEPT_TYPE_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
