import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_SETTINGS_REQUEST = "GET_SETTINGS_REQUEST";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR";

//* ACTIONS ------------------------------------------------
export const getSettingsRequest = (payload = {}, callback) => ({
  type: GET_SETTINGS_REQUEST,
  payload,
  callback,
});

export const getSettingsSuccess = (data) => ({
  type: GET_SETTINGS_SUCCESS,
  payload: data,
});

export const getSettingsError = (error) => ({
  type: GET_SETTINGS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getSettingsServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/settings`, options);
};
