import React from "react";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";

//Customs
import PrivateRoute from "../PrivateRoute";

//Selectors
import { isLogged } from "../../store/auth/selectors";

//* ROUTES *//
//* Concepts
import ConceptsRoutes from "./Concepts";
//* Administration(Payrolls)
import AdministrationRoutes from "./Administration";
import History from "./History";
import Report from "./Report";
import Voucher from "./Voucher";

const PayrollRoutes = ({ isLogged }) => {
  return (
    <Switch>
      <PrivateRoute
        path="/payroll/concepts"
        component={ConceptsRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/payroll/administration"
        component={AdministrationRoutes}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/payroll/history"
        component={History}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/payroll/reports"
        component={Report}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/payroll/voucher"
        component={Voucher}
        isAuthenticated={isLogged}
      />
      <Redirect to="/" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isLogged: isLogged(state),
});

export default connect(mapStateToProps)(PayrollRoutes);
