import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Employees
import {
  // action types
  GET_EMPLOYEES_REQUEST,
  // requests
  getAllEmployeesRequest,
  // actions
  getEmployeesSuccess,
  getEmployeesError,
} from "../../actions/employees/getEmployees";

//* Employee
import {
  // action types
  GET_EMPLOYEE_REQUEST,
  // requests
  employeeRequest,
  // actions
  getEmployeeSuccess,
  getEmployeeError,
} from "../../actions/employees/getEmployee";

//* Update Employee
import {
  // action types
  PUT_EMPLOYEE_REQUEST,
  // requests
  putEmployeeServiceRequest,
  // actions
  putEmployeeSuccess,
  putEmployeeError,
} from "../../actions/employees/putEmployee";

//* Create Employee
import {
  // action types
  POST_EMPLOYEE_REQUEST,
  // requests
  postEmployeeServiceRequest,
  // actions
  postEmployeeSuccess,
  postEmployeeError,
} from "../../actions/employees/postEmployee";

function* postEmployee(action) {
  try {
    yield call(postEmployeeServiceRequest, action.payload);
    yield put(postEmployeeSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postEmployeeError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getEmployees(action) {
  try {
    const result = yield call(getAllEmployeesRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getEmployeesSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getEmployeesError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getEmployee(action) {
  try {
    const result = yield call(employeeRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getEmployeeSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getEmployeeError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putEmployee(action) {
  try {
    yield call(putEmployeeServiceRequest, action.payload);
    yield put(putEmployeeSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putEmployeeError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* employeesSaga() {
  yield takeLatest(POST_EMPLOYEE_REQUEST, postEmployee);
  yield takeLatest(GET_EMPLOYEES_REQUEST, getEmployees);
  yield takeLatest(GET_EMPLOYEE_REQUEST, getEmployee);
  yield takeLatest(PUT_EMPLOYEE_REQUEST, putEmployee);
}

export default employeesSaga;
