import { lazy } from "react";
import { connect } from "react-redux";

import { getFetchVacationEnjoy } from "../../../hooks/Vacations/Vacations/getFetchVacationEnjoy";

const EnjoyVacation = lazy(() => import("./EnjoyVacation"));

const mapStateToProps = (state) => {
  return ({
    vacations: state.vacations.vacations.getVacationsEnjoy.data,
    pagination: state.vacations.vacations.getVacationsEnjoy.pagination,
    loading: state.vacations.vacations.getVacationsEnjoy.loading,
    error: state.vacations.vacations.getVacationsEnjoy.error,
  })
};

const mapDispatchToProps = dispatch => ({
  getFetchVacationEnjoy: (formData) => dispatch(getFetchVacationEnjoy(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnjoyVacation);
