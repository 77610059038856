import { createSelector } from "reselect";

const financialEntities = (state) => state.administration.financialEntities;

export const loading = createSelector(
  financialEntities,
  (state) => state.loading
);

export const loadingAction = createSelector(
  financialEntities,
  (state) => state.loadingAction
);

export const data = createSelector(financialEntities, (state) => state.data);

export const pagination = createSelector(
  financialEntities,
  (state) => state.pagination
);

export const detail = createSelector(
  financialEntities,
  (state) => state.detail
);
