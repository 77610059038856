import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_LEGAL_OBSERVATION_CODE_ISSS_REQUEST = "GET_LEGAL_OBSERVATION_CODE_ISSS_REQUEST";
export const GET_LEGAL_OBSERVATION_CODE_ISSS_SUCCESS = "GET_LEGAL_OBSERVATION_CODE_ISSS_SUCCESS";
export const GET_LEGAL_OBSERVATION_CODE_ISSS_ERROR = "GET_LEGAL_OBSERVATION_CODE_ISSS_ERROR";

//* ACTIONS ------------------------------------------------
export const getLegalObservationCodeISSSRequest = (payload = {}, callback) => ({
  type: GET_LEGAL_OBSERVATION_CODE_ISSS_REQUEST,
  payload,
  callback,
});

export const getLegalObservationCodeISSSSuccess = (data) => ({
  type: GET_LEGAL_OBSERVATION_CODE_ISSS_SUCCESS,
  payload: data,
});

export const getLegalObservationCodeISSSError = (error) => ({
  type: GET_LEGAL_OBSERVATION_CODE_ISSS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getLegalObservationCodeISSSServiceRequest = async () => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/isss/observation_codes`, options);
};
