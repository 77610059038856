import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_COSTCENTER_REQUEST = "POST_COSTCENTER_REQUEST";
export const POST_COSTCENTER_SUCCESS = "POST_COSTCENTER_SUCCESS";
export const POST_COSTCENTER_ERROR = "POST_COSTCENTER_ERROR";

//* ACTIONS ------------------------------------------------
export const postCostCenterRequest = (payload = {}, callback) => ({
  type: POST_COSTCENTER_REQUEST,
  payload,
  callback,
});

export const postCostCenterSuccess = () => ({
  type: POST_COSTCENTER_SUCCESS,
});

export const postCostCenterError = (error) => ({
  type: POST_COSTCENTER_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postCostCenterServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data,
  });

  return request("/costcenters", options);
};
