import { createSelector } from "reselect";

const conceptTypes = (state) => state.administration.concept_types;

export const loading = createSelector(conceptTypes, (state) => state.loading);

export const loadingAction = createSelector(
  conceptTypes,
  (state) => state.loadingAction
);

export const data = createSelector(conceptTypes, (state) => state.data);

export const pagination = createSelector(
  conceptTypes,
  (state) => state.pagination
);

export const detail = createSelector(conceptTypes, (state) => state.detail);
