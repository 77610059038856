import React from "react";
import { Route, Switch } from "react-router-dom";

import Documents from "../../../modules/Administration/Documents";
import NewDocument from "../../../modules/Administration/Documents/new";
import ShowDocument from "../../../modules/Administration/Documents/show";

export default () => {
  return (
    <Switch>
      <Route exact path="/administration/documents" component={Documents} />
      <Route
        exact
        path="/administration/documents/new"
        component={NewDocument}
      />
      <Route
        exact
        path="/administration/documents/:id"
        component={ShowDocument}
      />
    </Switch>
  );
};
