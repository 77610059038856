import React from "react";
import { Route, Switch } from "react-router-dom";

import Areas from "../../../modules/Administration/Areas";
import NewArea from "../../../modules/Administration/Areas/new";
import ShowArea from "../../../modules/Administration/Areas/show";

export default () => {
  return (
    <Switch>
      <Route exact path="/administration/areas" component={Areas} />
      <Route exact path="/administration/areas/new" component={NewArea} />
      <Route exact path="/administration/areas/:id" component={ShowArea} />
    </Switch>
  );
};
