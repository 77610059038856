import React from "react";
import { Route, Switch } from "react-router-dom";

import Positions from "../../../modules/Administration/Positions";
import NewPosition from "../../../modules/Administration/Positions/new";
import ShowPosition from "../../../modules/Administration/Positions/show";

export default () => {
  return (
    <Switch>
      <Route exact path="/administration/positions" component={Positions} />
      <Route
        exact
        path="/administration/positions/new"
        component={NewPosition}
      />
      <Route
        exact
        path="/administration/positions/:id"
        component={ShowPosition}
      />
    </Switch>
  );
};
