import React from "react";
import { Route, Switch } from "react-router-dom";

import Reports from "../../../modules/Payroll/Reports";
import ReportsPeriods from "../../../modules/Payroll/Reports/Periods";

export default () => {
  return (
    <Switch>
      <Route exact path="/payroll/reports" component={Reports} />
      <Route exact path="/payroll/reports/:id/periods/:periodId" component={ReportsPeriods} />
    </Switch>
  );
};