import { createSelector } from "reselect";

const documents = (state) => state.administration.documents;

export const loading = createSelector(documents, (state) => state.loading);

export const loadingAction = createSelector(
  documents,
  (state) => state.loadingAction
);

export const data = createSelector(documents, (state) => state.data);

export const pagination = createSelector(
  documents,
  (state) => state.pagination
);

export const detail = createSelector(documents, (state) => state.detail);
