import styled from "styled-components";

import { persianRed, catskillWhite } from "../../../../utils/colors";

const Styled = styled.button`
  background: ${persianRed};
  height: 34px;
  border-radius: 6px;
  &:active {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.8;
    background-color: ${catskillWhite};
    cursor: not-allowed;
  }
`;

export default Styled;
