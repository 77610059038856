import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const POST_LEGAL_AFP_REQUEST = "POST_LEGAL_AFP_REQUEST";
export const POST_LEGAL_AFP_SUCCESS = "POST_LEGAL_AFP_SUCCESS";
export const POST_LEGAL_AFP_ERROR = "POST_LEGAL_AFP_ERROR";

//* ACTIONS ------------------------------------------------
export const postLegalAFPRequest = (payload = {}, callback) => ({
  type: POST_LEGAL_AFP_REQUEST,
  payload,
  callback,
});

export const postLegalAFPSuccess = (data) => ({
  type: POST_LEGAL_AFP_SUCCESS,
  payload: data,
});

export const postLegalAFPError = (error) => ({
  type: POST_LEGAL_AFP_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postLegalAFPServiceRequest = async ({ payrollId, periodId, ...data }) => {
  const options = await withToken({
    method: Methods.POST,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });
  return request(`/payrolls/${payrollId}/periods/${periodId}/afp`, options);
};
