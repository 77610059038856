import { lazy } from "react";
import { connect } from "react-redux";
import { loading } from "../../../../store/administration/selectors/documents";

const NewDocument = lazy(() => import("./NewDocument"));

const mapStateToProps = (state) => ({
  loading: loading(state),
});

export default connect(mapStateToProps)(NewDocument);
