import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  data,
  pagination,
} from "../../../store/deductions/selectors/deductions";
import {
  loading as loadingPayrolls,
  data as payrolls,
} from "../../../store/payroll/selectors/payrolls";

const Deductions = lazy(() => import("./Deductions"));

const mapStateToProps = (state) => ({
  isLoading: loading(state),
  deductions: data(state),
  pagination: pagination(state),
  loadingPayrolls: loadingPayrolls(state),
  payrolls: payrolls(state),
});

export default connect(mapStateToProps)(Deductions);
