import { lazy } from "react";
import { connect } from "react-redux";
import {
  loadingAction,
  loading,
  detail,
} from "../../../../store/payroll/selectors/concepts";
import {
  loading as loadingConceptCategories,
  data as conceptCategories,
} from "../../../../store/administration/selectors/concept_categories";
import {
  loading as loadingConceptTypes,
  data as conceptTypes,
} from "../../../../store/administration/selectors/concept_types";

const ShowConcept = lazy(() => import("./ShowConcept"));

const mapStateToProps = (state) => ({
  loadingAction: loadingAction(state),
  loading: loading(state),
  concept: detail(state),
  loadingConceptCategories: loadingConceptCategories(state),
  conceptCategories: conceptCategories(state),
  loadingConceptTypes: loadingConceptTypes(state),
  conceptTypes: conceptTypes(state),
});

export default connect(mapStateToProps)(ShowConcept);
