import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_APPLY_PERIOD_REQUEST = "PUT_APPLY_PERIOD_REQUEST";
export const PUT_APPLY_PERIOD_SUCCESS = "PUT_APPLY_PERIOD_SUCCESS";
export const PUT_APPLY_PERIOD_ERROR =   "PUT_APPLY_PERIOD_ERROR";

//* ACTIONS ------------------------------------------------
export const putApplyPeriodRequest = (payload = {}, callback) => ({
  type: PUT_APPLY_PERIOD_REQUEST,
  payload,
  callback,
});

export const putApplyPeriodSuccess = () => ({
  type: PUT_APPLY_PERIOD_SUCCESS,
});

export const putApplyPeriodError = (error) => ({
  type: PUT_APPLY_PERIOD_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putApplyPeriodServiceRequest = async (periodId) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/payrolls/periods/${periodId}/apply`, options);
};
