import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Concepts
import {
  // action types
  GET_CONCEPTS_REQUEST,
  // requests
  getConceptsServiceRequest,
  // actions
  getConceptsSuccess,
  getConceptsError,
} from "../../actions/concepts/getConcepts";

//* Concept
import {
  // action types
  GET_CONCEPT_REQUEST,
  // requests
  getConceptServiceRequest,
  // actions
  getConceptSuccess,
  getConceptError,
} from "../../actions/concepts/getConcept";

//* Update Concept
import {
  // action types
  PUT_CONCEPT_REQUEST,
  // requests
  putConceptServiceRequest,
  // actions
  putConceptSuccess,
  putConceptError,
} from "../../actions/concepts/putConcept";

//* Create Concept
import {
  // action types
  POST_CONCEPT_REQUEST,
  // requests
  postConceptServiceRequest,
  // actions
  postConceptSuccess,
  postConceptError,
} from "../../actions/concepts/postConcept";

function* getConcepts(action) {
  try {
    const result = yield call(getConceptsServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getConceptsSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getConceptsError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getConcept(action) {
  try {
    const result = yield call(getConceptServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getConceptSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getConceptError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putConcept(action) {
  try {
    yield call(putConceptServiceRequest, action.payload);
    yield put(putConceptSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putConceptError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postConcept(action) {
  try {
    yield call(postConceptServiceRequest, action.payload);
    yield put(postConceptSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postConceptError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* conceptsSaga() {
  yield takeLatest(GET_CONCEPTS_REQUEST, getConcepts);
  yield takeLatest(GET_CONCEPT_REQUEST, getConcept);
  yield takeLatest(PUT_CONCEPT_REQUEST, putConcept);
  yield takeLatest(POST_CONCEPT_REQUEST, postConcept);
}

export default conceptsSaga;
