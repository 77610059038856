import { lazy } from "react";
import { connect } from "react-redux";
import { loading, types } from "../../../../store/payroll/selectors/payrolls";

const NewPayroll = lazy(() => import("./NewPayroll"));

const mapStateToProps = (state) => ({
  loading: loading(state),
  payrollTypes: types(state),
});

export default connect(mapStateToProps)(NewPayroll);
