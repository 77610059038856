import {
  getTypesPersonnelActionsRequest,
  getTypesPersonnelActionsSuccess,
  getTypesPersonnelActionsError,
  getTypesPersonnelActionsServiceRequest,
} from "../../store/personnelActions/actions/getTypesPersonnelActions";

export const getTypesPersonnelActions = (formData) => async (dispatch, getState) => {
  dispatch(getTypesPersonnelActionsRequest());
  try {
    const response = await getTypesPersonnelActionsServiceRequest(formData);
    dispatch(getTypesPersonnelActionsSuccess(response.data));
  } catch (error) {
    dispatch(getTypesPersonnelActionsError(error))
  }
  return Promise.resolve();
};
