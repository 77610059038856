import {
  getLegalSPUAllRequest,
  getLegalSPUAllSuccess,
  getLegalSPUAllError,
  getLegalSPUAllServiceRequest
} from "../../../store/legal/actions/spu/getSPUAll";

export const getLegalSPUAll = (formData) => async (dispatch, getState) => {
  dispatch(getLegalSPUAllRequest());
  try {
    const response = await getLegalSPUAllServiceRequest(formData);
    dispatch(getLegalSPUAllSuccess(response.data));
  } catch (error) {
    dispatch(getLegalSPUAllError(error))
  }
  return Promise.resolve();
};
