import { lazy } from "react";
import { connect } from "react-redux";

const ScheduleControl = lazy(() => import("./ScheduleControl"));

const mapStateToProps = (state) => {
  return ({
    isLoading: state.schedule.schedule_control.loading,
    scheduleControl: state.schedule.schedule_control.data,
    pagination: state.schedule.schedule_control.pagination,
  })
};

export default connect(mapStateToProps)(ScheduleControl);
