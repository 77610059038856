import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Benefits
import {
  // action types
  GET_BENEFITS_REQUEST,
  // requests
  getBenefitsServiceRequest,
  // actions
  getBenefitsSuccess,
  getBenefitsError,
} from "../../actions/benefits/getBenefits";

//* Benefit
import {
  // action types
  GET_BENEFIT_REQUEST,
  // requests
  getBenefitServiceRequest,
  // actions
  getBenefitSuccess,
  getBenefitError,
} from "../../actions/benefits/getBenefit";

//* Update Benefit
import {
  // action types
  PUT_BENEFIT_REQUEST,
  // requests
  putBenefitServiceRequest,
  // actions
  putBenefitSuccess,
  putBenefitError,
} from "../../actions/benefits/putBenefit";

//* Create Benefit
import {
  // action types
  POST_BENEFIT_REQUEST,
  // requests
  postBenefitServiceRequest,
  // actions
  postBenefitSuccess,
  postBenefitError,
} from "../../actions/benefits/postBenefit";

//* Benefit Employees
import {
  // action types
  GET_BENEFIT_EMPLOYEES_REQUEST,
  // requests
  getBenefitEmployeesServiceRequest,
  // actions
  getBenefitEmployeesSuccess,
  getBenefitEmployeesError,
} from "../../actions/benefits/getBenefitEmployees";

function* getBenefits(action) {
  try {
    const result = yield call(getBenefitsServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getBenefitsSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getBenefitsError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getBenefit(action) {
  try {
    const result = yield call(getBenefitServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getBenefitSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getBenefitError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putBenefit(action) {
  try {
    yield call(putBenefitServiceRequest, action.payload);
    yield put(putBenefitSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putBenefitError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postBenefit(action) {
  try {
    yield call(postBenefitServiceRequest, action.payload);
    yield put(postBenefitSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postBenefitError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getBenefitEmployees(action) {
  try {
    const result = yield call(
      getBenefitEmployeesServiceRequest,
      action.payload
    );
    const data = get(result, "data.data", {});

    yield put(getBenefitEmployeesSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getBenefitEmployeesError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* benefitsSaga() {
  yield takeLatest(GET_BENEFITS_REQUEST, getBenefits);
  yield takeLatest(GET_BENEFIT_REQUEST, getBenefit);
  yield takeLatest(PUT_BENEFIT_REQUEST, putBenefit);
  yield takeLatest(POST_BENEFIT_REQUEST, postBenefit);
  yield takeLatest(GET_BENEFIT_EMPLOYEES_REQUEST, getBenefitEmployees);
}

export default benefitsSaga;
