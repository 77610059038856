import React from "react";
import { Route, Switch } from "react-router-dom";

import Deduction from "../../../modules/Deductions/Administration";
import NewDeduction from "../../../modules/Deductions/Administration/new";
import ShowDeduction from "../../../modules/Deductions/Administration/show";

export default () => {
  return (
    <Switch>
      <Route exact path="/deductions/administration" component={Deduction} />
      <Route
        exact
        path="/deductions/administration/new"
        component={NewDeduction}
      />
      <Route
        exact
        path="/deductions/administration/:id"
        component={ShowDeduction}
      />
    </Switch>
  );
};
