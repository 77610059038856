//Get Data
import {
  GET_LEGAL_ISSS_REQUEST,
  GET_LEGAL_ISSS_SUCCESS,
  GET_LEGAL_ISSS_ERROR,
} from "../../actions/isss/getISSS";

//Get Data all
import {
  GET_LEGAL_ISSS_ALL_REQUEST,
  GET_LEGAL_ISSS_ALL_SUCCESS,
  GET_LEGAL_ISSS_ALL_ERROR,
} from "../../actions/isss/getISSSAll";

//Get Observation Code
import {
  GET_LEGAL_OBSERVATION_CODE_ISSS_REQUEST,
  GET_LEGAL_OBSERVATION_CODE_ISSS_SUCCESS,
  GET_LEGAL_OBSERVATION_CODE_ISSS_ERROR,
} from "../../actions/isss/getObservationCodeISSS";

//Post Legal
import {
  POST_LEGAL_ISSS_REQUEST,
  POST_LEGAL_ISSS_SUCCESS,
  POST_LEGAL_ISSS_ERROR,
} from "../../actions/isss/postSnapshotISSS";

//Put Employee
import {
  PUT_LEGAL_EMPLOYEE_ISSS_REQUEST,
  PUT_LEGAL_EMPLOYEE_ISSS_SUCCESS,
  PUT_LEGAL_EMPLOYEE_ISSS_ERROR,
} from "../../actions/isss/putEmployeISSS";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  getLegalISSS: {
    loading: false,
    error: false,
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  getLegalISSSAll: {
    loading: false,
    error: false,
    data: [],
  },
  getLegalObservationISSS: {
    loading: false,
    error: false,
    data: [],
  },
  postLegalSnapshotISSS: {
    loading: false,
    error: false,
    data: [],
    success: false,
  },
  putLegalEmployeeISSS: {
    loading: false,
    error: false,
    data: [],
  },

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LEGAL_ISSS_REQUEST:
      return {
        ...state,
        getLegalISSS: {
          error: false,
          loading: true,
        }
      };
    case GET_LEGAL_ISSS_SUCCESS:
      return {
        ...state,
        getLegalISSS: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: false,
          loading: false,
        }
      };
    case GET_LEGAL_ISSS_ERROR:
      return {
        ...state,
        getLegalISSS: {
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -----------------------------------------------------------
    case GET_LEGAL_ISSS_ALL_REQUEST:
      return {
        ...state,
        getLegalISSSAll: {
          error: false,
          loading: true,
        }
      };
    case GET_LEGAL_ISSS_ALL_SUCCESS:
      return {
        ...state,
        getLegalISSSAll: {
          data: action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_LEGAL_ISSS_ALL_ERROR:
      return {
        ...state,
        getLegalISSSAll: {
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -----------------------------------------------------------
    case GET_LEGAL_OBSERVATION_CODE_ISSS_REQUEST:
      return {
        ...state,
        getLegalObservationISSS: {
          ...state.getLegalObservationISSS,
          error: false,
          loading: true,
        }
      };
    case GET_LEGAL_OBSERVATION_CODE_ISSS_SUCCESS:
      return {
        ...state,
        getLegalObservationISSS: {
          ...state.getLegalObservationISSS,
          data: action.payload.data,
          error: false,
          loading: false,
        }
      };
    case GET_LEGAL_OBSERVATION_CODE_ISSS_ERROR:
      return {
        ...state,
        getLegalObservationISSS: {
          ...state.getLegalObservationISSS,
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -----------------------------------------------------------
    case POST_LEGAL_ISSS_REQUEST:
      return {
        ...state,
        postLegalSnapshotISSS: {
          data: [],
          error: false,
          loading: true,
          success: false,
        }
      };
    case POST_LEGAL_ISSS_SUCCESS:
      return {
        ...state,
        postLegalSnapshotISSS: {
          data: action.payload,
          error: false,
          loading: false,
          success: true,
        }
      };
    case POST_LEGAL_ISSS_ERROR:
      return {
        ...state,
        postLegalSnapshotISSS: {
          error: action.payload || action.payload.status || 401,
          loading: false,
          success: false,
        }
      };
    // -----------------------------------------------------------
    case PUT_LEGAL_EMPLOYEE_ISSS_REQUEST:
      return {
        ...state,
        putLegalEmployeeISSS: {
          ...state.putLegalEmployeeISSS,
          error: false,
          loading: true,
        }
      };
    case PUT_LEGAL_EMPLOYEE_ISSS_SUCCESS:
      return {
        ...state,
        putLegalEmployeeISSS: {
          ...state.putLegalEmployeeISSS,
          data: action.payload,
          error: false,
          loading: false,
        }
      };
    case PUT_LEGAL_EMPLOYEE_ISSS_ERROR:
      return {
        ...state,
        putLegalEmployeeISSS: {
          ...state.putLegalEmployeeISSS,
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };

    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
