import React from "react";

const ServerErrorPage = () => {
  return (
    <section className="w-screen h-screen flex items-center justify-center">
      <h1 className="font-bold text-6xl mr-5">500</h1>
      <h1>¡Lo Sentimos! intente de nuevo en unos minutos</h1>
    </section>
  );
};

export default ServerErrorPage;
