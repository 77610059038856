import React from "react";
import { Route, Switch } from "react-router-dom";

import Payrolls from "../../../modules/Payroll/Payrolls";
import NewPayroll from "../../../modules/Payroll/Payrolls/new";
import ShowPayroll from "../../../modules/Payroll/Payrolls/show";
import PayrollEmployees from "../../../modules/Payroll/Payrolls/Employees";
import PayrollPeriods from "../../../modules/Payroll/Payrolls/Periods";

export default () => {
  return (
    <Switch>
      <Route exact path="/payroll/administration" component={Payrolls} />
      <Route exact path="/payroll/administration/new" component={NewPayroll} />
      <Route
        exact
        path="/payroll/administration/:id/employees"
        component={PayrollEmployees}
      />
      <Route
        exact
        path="/payroll/administration/:id/periods/:periodId"
        component={PayrollPeriods}
      />
      <Route exact path="/payroll/administration/:id" component={ShowPayroll} />
    </Switch>
  );
};
