import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_LEGAL_AFP_REQUEST = "GET_LEGAL_AFP_REQUEST";
export const GET_LEGAL_AFP_SUCCESS = "GET_LEGAL_AFP_SUCCESS";
export const GET_LEGAL_AFP_ERROR = "GET_LEGAL_AFP_ERROR";

//* ACTIONS ------------------------------------------------
export const getLegalAFPRequest = (payload = {}, callback) => ({
  type: GET_LEGAL_AFP_REQUEST,
  payload,
  callback,
});

export const getLegalAFPSuccess = (data) => ({
  type: GET_LEGAL_AFP_SUCCESS,
  payload: data,
});

export const getLegalAFPError = (error) => ({
  type: GET_LEGAL_AFP_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getLegalAFPServiceRequest = async ({ payrollId, periodId, ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/payrolls/${payrollId}/periods/${periodId}/afp`, options);
};
