import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_PAYROLL_REQUEST = "GET_PAYROLL_REQUEST";
export const GET_PAYROLL_SUCCESS = "GET_PAYROLL_SUCCESS";
export const GET_PAYROLL_ERROR = "GET_PAYROLL_ERROR";

//* ACTIONS ------------------------------------------------
export const getPayrollRequest = (payload = {}, callback) => ({
  type: GET_PAYROLL_REQUEST,
  payload,
  callback,
});

export const getPayrollSuccess = (data) => ({
  type: GET_PAYROLL_SUCCESS,
  payload: data,
});

export const getPayrollError = (error) => ({
  type: GET_PAYROLL_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getPayrollServiceRequest = async (payrollId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/payrolls/${payrollId}`, options);
};
