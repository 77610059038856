import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_PAYROLL_HISTORY_REQUEST = "GET_PAYROLL_HISTORY_REQUEST";
export const GET_PAYROLL_HISTORY_SUCCESS = "GET_PAYROLL_HISTORY_SUCCESS";
export const GET_PAYROLL_HISTORY_ERROR = "GET_PAYROLL_HISTORY_ERROR";

//* ACTIONS ------------------------------------------------
export const getPayrollHistoryRequest = (payload = {}, callback) => ({
  type: GET_PAYROLL_HISTORY_REQUEST,
  payload,
  callback,
});

export const getPayrollHistorySuccess = (data) => ({
  type: GET_PAYROLL_HISTORY_SUCCESS,
  payload: data,
});

export const getPayrollHistoryError = (error) => ({
  type: GET_PAYROLL_HISTORY_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getPayrollHistoryServiceRequest = async ({ payrollId = 0, ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/payrolls/${payrollId}/histories`, options);
};
