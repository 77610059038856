//All Companies
import {
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
} from "../../actions/companies/getCompanies";

//Company
import {
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_ERROR,
} from "../../actions/companies/getCompany";

//Update Company
import {
  PUT_COMPANY_REQUEST,
  PUT_COMPANY_SUCCESS,
  PUT_COMPANY_ERROR,
} from "../../actions/companies/putCompany";

//Create Company
import {
  POST_COMPANY_REQUEST,
  POST_COMPANY_SUCCESS,
  POST_COMPANY_ERROR,
} from "../../actions/companies/postCompany";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Login
    case GET_COMPANIES_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_COMPANIES_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case GET_COMPANY_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loading: false,
      };
    case GET_COMPANY_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case PUT_COMPANY_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_COMPANY_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_COMPANY_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_COMPANY_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_COMPANY_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_COMPANY_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
