import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_CALCULATE_PERIOD_REQUEST = "PUT_CALCULATE_PERIOD_REQUEST";
export const PUT_CALCULATE_PERIOD_SUCCESS = "PUT_CALCULATE_PERIOD_SUCCESS";
export const PUT_CALCULATE_PERIOD_ERROR = "PUT_CALCULATE_PERIOD_ERROR";

//* ACTIONS ------------------------------------------------
export const putCalculatePeriodRequest = (payload = {}, callback) => ({
  type: PUT_CALCULATE_PERIOD_REQUEST,
  payload,
  callback,
});

export const putCalculatePeriodSuccess = () => ({
  type: PUT_CALCULATE_PERIOD_SUCCESS,
});

export const putCalculatePeriodError = (error) => ({
  type: PUT_CALCULATE_PERIOD_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putCalculatePeriodServiceRequest = async (periodId) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/payrolls/periods/${periodId}/calculate`, options);
};
