import { lazy } from "react";
import { connect } from "react-redux";
import { loading } from "../../../../store/administration/selectors/costcenters";

const NewCostCenter = lazy(() => import("./NewCostCenter"));

const mapStateToProps = (state) => ({
  loading: loading(state),
});

export default connect(mapStateToProps)(NewCostCenter);
