import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_FINANCIAL_ENTITIES_REQUEST = "GET_FINANCIAL_ENTITIES_REQUEST";
export const GET_FINANCIAL_ENTITIES_SUCCESS = "GET_FINANCIAL_ENTITIES_SUCCESS";
export const GET_FINANCIAL_ENTITIES_ERROR = "GET_FINANCIAL_ENTITIES_ERROR";

//* ACTIONS ------------------------------------------------
export const getFinancialEntitiesRequest = (payload = {}, callback) => ({
  type: GET_FINANCIAL_ENTITIES_REQUEST,
  payload,
  callback,
});

export const getFinancialEntitiesSuccess = (data) => ({
  type: GET_FINANCIAL_ENTITIES_SUCCESS,
  payload: data,
});

export const getFinancialEntitiesError = (error) => ({
  type: GET_FINANCIAL_ENTITIES_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getFinancialEntitiesServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/financial_entities`, options);
};
