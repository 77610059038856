import { createSelector } from "reselect";

const deductions = (state) => state.deductions.deductions;

export const loading = createSelector(deductions, (state) => state.loading);

export const loadingAction = createSelector(
  deductions,
  (state) => state.loadingAction
);

export const loadingDetail = createSelector(
  deductions,
  (state) => state.loadingDetail
);

export const data = createSelector(
  deductions, 
  (state) => state.data
);

export const pagination = createSelector(
  deductions,
  (state) => state.pagination
);

export const detail = createSelector(deductions, (state) => state.detail);
