import {
  getLegalISSSRequest,
  getLegalISSSSuccess,
  getLegalISSSError,
  getLegalISSSServiceRequest
} from "../../../store/legal/actions/isss/getISSS";

export const getLegalISSS = (formData) => async (dispatch, getState) => {
  dispatch(getLegalISSSRequest());
  try {
    const response = await getLegalISSSServiceRequest(formData);
    dispatch(getLegalISSSSuccess(response.data));
  } catch (error) {
    dispatch(getLegalISSSError(error))
  }
  return Promise.resolve();
};
