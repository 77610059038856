import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_UBICATION_REQUEST = "PUT_UBICATION_REQUEST";
export const PUT_UBICATION_SUCCESS = "PUT_UBICATION_SUCCESS";
export const PUT_UBICATION_ERROR = "PUT_UBICATION_ERROR";

//* ACTIONS ------------------------------------------------
export const putUbicationRequest = (payload = {}, callback) => ({
  type: PUT_UBICATION_REQUEST,
  payload,
  callback,
});

export const putUbicationSuccess = () => ({
  type: PUT_UBICATION_SUCCESS,
});

export const putUbicationError = (error) => ({
  type: PUT_UBICATION_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putUbicationServiceRequest = async ({ ubicationId, ...data }) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/ubications/${ubicationId}`, options);
};
