import React from "react";
import { Route, Switch } from "react-router-dom";

import Settings from "../../../modules/Administration/Settings";
import NewSettings from "../../../modules/Administration/Settings/new";
import ShowSettings from "../../../modules/Administration/Settings/show";

export default () => {
  return (
    <Switch>
      <Route
        exact
        path="/administration/settings"
        component={Settings}
      />
      <Route
        exact
        path="/administration/settings/new"
        component={NewSettings}
      />
      <Route
        exact
        path="/administration/settings/:id"
        component={ShowSettings}
      />
    </Switch>
  );
};
