import { put, takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

//* All Positions
import {
  // action types
  GET_POSITIONS_REQUEST,
  // requests
  getPositionsServiceRequest,
  // actions
  getPositionsSuccess,
  getPositionsError,
} from "../../actions/positions/getPositions";

//* Position
import {
  // action types
  GET_POSITION_REQUEST,
  // requests
  getPositionServiceRequest,
  // actions
  getPositionSuccess,
  getPositionError,
} from "../../actions/positions/getPosition";

//* Update Position
import {
  // action types
  PUT_POSITION_REQUEST,
  // requests
  putPositionServiceRequest,
  // actions
  putPositionSuccess,
  putPositionError,
} from "../../actions/positions/putPosition";

//* Create Position
import {
  // action types
  POST_POSITION_REQUEST,
  // requests
  postPositionServiceRequest,
  // actions
  postPositionSuccess,
  postPositionError,
} from "../../actions/positions/postPosition";

function* getPositions(action) {
  try {
    const result = yield call(getPositionsServiceRequest, action.payload);
    const data = {
      data: get(result, "data.data", []),
      pagination: get(result, "data.pagination", {}),
    };

    yield put(getPositionsSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getPositionsError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* getPosition(action) {
  try {
    const result = yield call(getPositionServiceRequest, action.payload);
    const data = get(result, "data.data", {});

    yield put(getPositionSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getPositionError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* putPosition(action) {
  try {
    yield call(putPositionServiceRequest, action.payload);
    yield put(putPositionSuccess(null));

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(putPositionError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* postPosition(action) {
  try {
    yield call(postPositionServiceRequest, action.payload);
    yield put(postPositionSuccess());

    action.callback && action.callback(null, null);
  } catch (e) {
    yield put(postPositionError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* positionsSaga() {
  yield takeLatest(GET_POSITIONS_REQUEST, getPositions);
  yield takeLatest(GET_POSITION_REQUEST, getPosition);
  yield takeLatest(PUT_POSITION_REQUEST, putPosition);
  yield takeLatest(POST_POSITION_REQUEST, postPosition);
}

export default positionsSaga;
