import {
  getLegalObservationCodeISSSRequest,
  getLegalObservationCodeISSSSuccess,
  getLegalObservationCodeISSSError,
  getLegalObservationCodeISSSServiceRequest,
} from "../../../store/legal/actions/isss/getObservationCodeISSS";

export const  getLegalObservationCodeISSS = () => async (dispatch, getState) => {
  dispatch(getLegalObservationCodeISSSRequest());
  try {
    const response = await getLegalObservationCodeISSSServiceRequest();
    dispatch(getLegalObservationCodeISSSSuccess(response.data));
  } catch (error) {
    dispatch(getLegalObservationCodeISSSError(error))
  }
  return Promise.resolve();
};
