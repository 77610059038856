import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_REQUIREMENT_REQUEST = "GET_REQUIREMENT_REQUEST";
export const GET_REQUIREMENT_SUCCESS = "GET_REQUIREMENT_SUCCESS";
export const GET_REQUIREMENT_ERROR = "GET_REQUIREMENT_ERROR";

//* ACTIONS ------------------------------------------------
export const getRequirementRequest = (payload = {}, callback) => ({
  type: GET_REQUIREMENT_REQUEST,
  payload,
  callback,
});

export const getRequirementSuccess = (data) => ({
  type: GET_REQUIREMENT_SUCCESS,
  payload: data,
});

export const getRequirementError = (error) => ({
  type: GET_REQUIREMENT_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getRequirementServiceRequest = async (requirementId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/requirements/${requirementId}`, options);
};
