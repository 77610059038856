import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  data,
  pagination,
} from "../../../store/administration/selectors/companies";

const Companies = lazy(() => import("./Companies"));

const mapStateToProps = (state) => ({
  isLoading: loading(state),
  companies: data(state),
  pagination: pagination(state),
});

export default connect(mapStateToProps)(Companies);
