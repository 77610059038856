import {
  getHeaderPeriodHistoryRequest,
  getHeaderPeriodHistorySuccess,
  getHeaderPeriodHistoryError,
  getHeaderPeriodHistoryServiceRequest,
} from "../../../store/payroll/actions/periods/getHeaderPeriodHistory";


export const getHeaderPeriodHistory = (formData) => async (dispatch, getState) => {
  dispatch(getHeaderPeriodHistoryRequest());
  try {
    const response = await getHeaderPeriodHistoryServiceRequest(formData);
    dispatch(getHeaderPeriodHistorySuccess(response.data));
  } catch (error) {
    dispatch(getHeaderPeriodHistoryError(error))
  }
  return Promise.resolve();
};;
