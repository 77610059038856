import {
  getPayrollReportPaymentCuscRequest,
  getPayrollReportPaymentCuscSuccess,
  getPayrollReportPaymentCuscError,
  getPayrollReportPaymentCuscServiceRequest
} from "../../../store/payroll/actions/reports/getReportsPaymentCusc";

export const getPayrollReportPaymentCusc = (formData) => async (dispatch, getState) => {
  dispatch(getPayrollReportPaymentCuscRequest());
  try {
    const response = await getPayrollReportPaymentCuscServiceRequest(formData);
    dispatch(getPayrollReportPaymentCuscSuccess(response.data));
  } catch (error) {
    dispatch(getPayrollReportPaymentCuscError(error))
  }
  return Promise.resolve();
};
