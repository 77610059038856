//Get Data
import {
  GET_ALL_PERSONNEL_ACTIONS_REQUEST,
  GET_ALL_PERSONNEL_ACTIONS_SUCCESS,
  GET_ALL_PERSONNEL_ACTIONS_ERROR,
} from "../actions/getAllPersonnelActions";

// Get Types
import {
  GET_TYPES_PERSONNEL_ACTIONS_REQUEST,
  GET_TYPES_PERSONNEL_ACTIONS_SUCCESS,
  GET_TYPES_PERSONNEL_ACTIONS_ERROR,
} from "../actions/getTypesPersonnelActions";

// POST AP
import {
  POST_PERSONNEL_ACTIONS_DEFAULT,
  POST_PERSONNEL_ACTIONS_REQUEST,
  POST_PERSONNEL_ACTIONS_SUCCESS,
  POST_PERSONNEL_ACTIONS_ERROR,
} from "../actions/postPersonnelActions";

// PUT AP
import {
  PUT_PERSONNEL_ACTIONS_DEFAULT,
  PUT_PERSONNEL_ACTIONS_REQUEST,
  PUT_PERSONNEL_ACTIONS_SUCCESS,
  PUT_PERSONNEL_ACTIONS_ERROR
} from "../actions/putPersonnelActions";

// CANCELL AP
import {
  CANCEL_PERSONNEL_ACTIONS_DEFAULT,
  CANCEL_PERSONNEL_ACTIONS_REQUEST,
  CANCEL_PERSONNEL_ACTIONS_SUCCESS,
  CANCEL_PERSONNEL_ACTIONS_ERROR,
} from "../actions/cancelPersonnelActions";

// APPLY AP
import {
  APPLY_PERSONNEL_ACTIONS_DEFAULT,
  APPLY_PERSONNEL_ACTIONS_REQUEST,
  APPLY_PERSONNEL_ACTIONS_SUCCESS,
  APPLY_PERSONNEL_ACTIONS_ERROR,
} from "../actions/applyPersonnelActions";

// APPROVE AP
import {
  APPROVE_PERSONNEL_ACTIONS_DEFAULT,
  APPROVE_PERSONNEL_ACTIONS_REQUEST,
  APPROVE_PERSONNEL_ACTIONS_SUCCESS,
  APPROVE_PERSONNEL_ACTIONS_ERROR,
} from "../actions/aprovePersonnelActions";

// UNAPPLY AP
import {
  UNAPPLY_PERSONNEL_ACTIONS_DEFAULT,
  UNAPPLY_PERSONNEL_ACTIONS_REQUEST,
  UNAPPLY_PERSONNEL_ACTIONS_SUCCESS,
  UNAPPLY_PERSONNEL_ACTIONS_ERROR
} from "../actions/unapplyPersonnelActions";

//Logout
import { LOGOUT } from "../../auth/actions/logout";

const INITIAL_STATE = {
  personnelActions: {
    loading: false,
    error: false,
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  typesPersonnelActions: {
    loading: false,
    error: false,
    data: [],
  },
  postPersonnelActions: {
    loading: false,
    error: false,
    data: [],
    success: false,
    message: null,
  },
  putPersonnelActions: {
    loading: false,
    error: false,
    data: [],
    success: false,
    message: null,
  },
  cancelPersonnelActions: {
    loading: false,
    error: false,
    data: [],
    success: false,
    message: null,
  },
  applyPersonnelActions: {
    loading: false,
    error: false,
    data: [],
    success: false,
    message: null,
  },
  approvePersonnelActions: {
    loading: false,
    error: false,
    data: [],
    success: false,
    message: null,
  },
  unapplyPersonnelActions: {
    loading: false,
    error: false,
    data: [],
    success: false,
    message: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PERSONNEL_ACTIONS_REQUEST:
      return {
        ...state,
        personnelActions: {
          data: [],
          error: false,
          loading: true,
        }
      };
    case GET_ALL_PERSONNEL_ACTIONS_SUCCESS:
      return {
        ...state,
        personnelActions: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: false,
          loading: false,
        }
      };
    case GET_ALL_PERSONNEL_ACTIONS_ERROR:
      return {
        ...state,
        personnelActions: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -----------------------------------------------------------
    case GET_TYPES_PERSONNEL_ACTIONS_REQUEST:
      return {
        ...state,
        typesPersonnelActions: {
          data: [],
          error: false,
          loading: true,
        }
      };
    case GET_TYPES_PERSONNEL_ACTIONS_SUCCESS:
      return {
        ...state,
        typesPersonnelActions: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: false,
          loading: false,
        }
      };
    case GET_TYPES_PERSONNEL_ACTIONS_ERROR:
      return {
        ...state,
        typesPersonnelActions: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // -----------------------------------------------------------
    case POST_PERSONNEL_ACTIONS_DEFAULT:
      return {
        ...state,
        postPersonnelActions: INITIAL_STATE.postPersonnelActions
      };
    case POST_PERSONNEL_ACTIONS_REQUEST:
      return {
        ...state,
        postPersonnelActions: {
          data: [],
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case POST_PERSONNEL_ACTIONS_SUCCESS:
      return {
        ...state, 
        postPersonnelActions: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case POST_PERSONNEL_ACTIONS_ERROR:
      return {
        ...state,
        postPersonnelActions: {
          data: [],
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };
    // -----------------------------------------------------------
    case PUT_PERSONNEL_ACTIONS_DEFAULT:
      return {
        ...state,
        putPersonnelActions: INITIAL_STATE.putPersonnelActions
      };
    case PUT_PERSONNEL_ACTIONS_REQUEST:
      return {
        ...state,
        putPersonnelActions: {
          data: [],
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case PUT_PERSONNEL_ACTIONS_SUCCESS:
      return {
        ...state,
        putPersonnelActions: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case PUT_PERSONNEL_ACTIONS_ERROR:
      return {
        ...state,
        putPersonnelActions: {
          data: [],
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };
    // -----------------------------------------------------------
    case CANCEL_PERSONNEL_ACTIONS_DEFAULT:
      return {
        ...state,
        cancelPersonnelActions: INITIAL_STATE.cancelPersonnelActions
      };
    case CANCEL_PERSONNEL_ACTIONS_REQUEST:
      return {
        ...state,
        cancelPersonnelActions: {
          data: [],
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case CANCEL_PERSONNEL_ACTIONS_SUCCESS:
      return {
        ...state,
        cancelPersonnelActions: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case CANCEL_PERSONNEL_ACTIONS_ERROR:
      return {
        ...state,
        cancelPersonnelActions: {
          data: [],
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };
    // -----------------------------------------------------------
    case APPLY_PERSONNEL_ACTIONS_DEFAULT:
      return {
        ...state,
        applyPersonnelActions: INITIAL_STATE.applyPersonnelActions
      };
    case APPLY_PERSONNEL_ACTIONS_REQUEST:
      return {
        ...state,
        applyPersonnelActions: {
          data: [],
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case APPLY_PERSONNEL_ACTIONS_SUCCESS:
      return {
        ...state,
        applyPersonnelActions: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case APPLY_PERSONNEL_ACTIONS_ERROR:
      return {
        ...state,
        applyPersonnelActions: {
          data: [],
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };
    // -----------------------------------------------------------
    case APPROVE_PERSONNEL_ACTIONS_DEFAULT:
      return {
        ...state,
        approvePersonnelActions: INITIAL_STATE.approvePersonnelActions
      };
    case APPROVE_PERSONNEL_ACTIONS_REQUEST:
      return {
        ...state,
        approvePersonnelActions: {
          data: [],
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case APPROVE_PERSONNEL_ACTIONS_SUCCESS:
      return {
        ...state,
        approvePersonnelActions: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case APPROVE_PERSONNEL_ACTIONS_ERROR:
      return {
        ...state,
        approvePersonnelActions: {
          data: [],
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };
    // -----------------------------------------------------------
    case UNAPPLY_PERSONNEL_ACTIONS_DEFAULT:
      return {
        ...state,
        unapplyPersonnelActions: INITIAL_STATE.unapplyPersonnelActions
      };
    case UNAPPLY_PERSONNEL_ACTIONS_REQUEST:
      return {
        ...state,
        unapplyPersonnelActions: {
          data: [],
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case UNAPPLY_PERSONNEL_ACTIONS_SUCCESS:
      return {
        ...state,
        unapplyPersonnelActions: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case UNAPPLY_PERSONNEL_ACTIONS_ERROR:
      return {
        ...state,
        unapplyPersonnelActions: {
          data: [],
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };
    // -----------------------------------------------------------
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
