import React from "react";
import cx from "classnames";

// styles
import Styled from "./styled";

const ButtonWithIcon = ({
  onClick = () => { },
  text,
  isLoading,
  className,
  iconRight = false,
  icon,
  type = "button",
  disabled = false,
  title = "",
  ...rest
}) => {
  return (
    <Styled
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={cx(
        "hover:opacity-75 text-white font-bold py-2 px-4 rounded focus:outline-none flex items-center justify-center",
        {
          "is-loading": isLoading,
          [className]: className,
          "w-full": !className,
        }
      )}
      title={title}
      {...rest}
    >
      {icon}
      {text && (
        <p
          className={cx("", {
            "mr-4": iconRight,
            "ml-4": !iconRight,
          })}
        >
          {text}
        </p>
      )}
    </Styled>
  );
};

export default ButtonWithIcon;
