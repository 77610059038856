import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_COSTCENTER_REQUEST = "PUT_COSTCENTER_REQUEST";
export const PUT_COSTCENTER_SUCCESS = "PUT_COSTCENTER_SUCCESS";
export const PUT_COSTCENTER_ERROR = "PUT_COSTCENTER_ERROR";

//* ACTIONS ------------------------------------------------
export const putCostCenterRequest = (payload = {}, callback) => ({
  type: PUT_COSTCENTER_REQUEST,
  payload,
  callback,
});

export const putCostCenterSuccess = () => ({
  type: PUT_COSTCENTER_SUCCESS,
});

export const putCostCenterError = (error) => ({
  type: PUT_COSTCENTER_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putCostCenterServiceRequest = async ({
  costCenterId,
  ...data
}) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/costcenters/${costCenterId}`, options);
};
