import request, { Methods, withToken } from "../../../../utils/request";

//* ACTIONTYPES --------------------------------------------
export const POST_SCHEDULE_DEFAULT = "POST_SCHEDULE_DEFAULT";
export const POST_SCHEDULE_REQUEST = "POST_SCHEDULE_REQUEST";
export const POST_SCHEDULE_SUCCESS = "POST_SCHEDULE_SUCCESS";
export const POST_SCHEDULE_ERROR = "POST_SCHEDULE_ERROR";

//* ACTIONS ------------------------------------------------
export const postScheduleDefault = () => ({
  type: POST_SCHEDULE_DEFAULT,
});

export const postScheduleRequest = (payload = {}, callback) => ({
  type: POST_SCHEDULE_REQUEST,
  payload,
  callback,
});

export const postScheduleSuccess = () => ({
  type: POST_SCHEDULE_SUCCESS,
});

export const postScheduleError = (error) => ({
  type: POST_SCHEDULE_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const postScheduleServiceRequest = async (data) => {
  const options = await withToken({
    method: Methods.POST,
    data,
  });

  return request("/schedules", options);
};
