//Get Data
import {
  GET_LEGAL_F910_REQUEST,
  GET_LEGAL_F910_SUCCESS,
  GET_LEGAL_F910_ERROR,
} from "../../actions/f910/getF910";
//Get Code Ingreso
import {
  GET_LEGAL_CODE_INGRESO_REQUEST,
  GET_LEGAL_CODE_INGRESO_SUCCESS,
  GET_LEGAL_CODE_INGRESO_ERROR,
} from "../../actions/f910/getCodeIngreso";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  getLegalF910: {
    loading: false,
    error: false,
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  getCodeIngreso: {
    loading: false,
    error: false,
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case GET_LEGAL_F910_REQUEST:
      return {
        ...state,
        getLegalF910: {
          data: [],
          error: false,
          loading: true,
        }
      };
    case GET_LEGAL_F910_SUCCESS:
      return {
        ...state,
        getLegalF910: {
          ...action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_LEGAL_F910_ERROR:
      return {
        ...state,
        getLegalF910: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };

    // CÓDIGO DE INGRESO
    case GET_LEGAL_CODE_INGRESO_REQUEST:
      return {
        ...state,
        getCodeIngreso: {
          data: [],
          error: false,
          loading: true,
        }
      };
    case GET_LEGAL_CODE_INGRESO_SUCCESS:
      return {
        ...state,
        getCodeIngreso: {
          ...action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_LEGAL_CODE_INGRESO_ERROR:
      return {
        ...state,
        getCodeIngreso: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };


    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
