import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_EMPLOYEE_SCHEDULE_DEFAULT = "GET_EMPLOYEE_SCHEDULE_DEFAULT";
export const GET_EMPLOYEE_SCHEDULE_REQUEST = "GET_EMPLOYEE_SCHEDULE_REQUEST";
export const GET_EMPLOYEE_SCHEDULE_SUCCESS = "GET_EMPLOYEE_SCHEDULE_SUCCESS";
export const GET_EMPLOYEE_SCHEDULE_ERROR = "GET_EMPLOYEE_SCHEDULE_ERROR";

//* ACTIONS ------------------------------------------------

export const getEmployeeScheduleDefault = () => ({
  type: GET_EMPLOYEE_SCHEDULE_DEFAULT,
});

export const getEmployeeScheduleRequest = (payload = {}, callback) => ({
  type: GET_EMPLOYEE_SCHEDULE_REQUEST,
  payload,
  callback,
});

export const getEmployeeScheduleSuccess = (data) => ({
  type: GET_EMPLOYEE_SCHEDULE_SUCCESS,
  payload: data,
});

export const getEmployeeScheduleError = (error) => ({
  type: GET_EMPLOYEE_SCHEDULE_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const employeeScheduleRequest = async (params) => {
  console.log(params)
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
    params
  });

  return request(`/vacations/history_enjoyed`, options);
};
