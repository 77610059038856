import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_COUNTRY_REQUEST = "GET_COUNTRY_REQUEST";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_ERROR = "GET_COUNTRY_ERROR";

//* ACTIONS ------------------------------------------------
export const getCountryRequest = (payload = {}, callback) => ({
  type: GET_COUNTRY_REQUEST,
  payload,
  callback,
});

export const getCountrySuccess = (data) => ({
  type: GET_COUNTRY_SUCCESS,
  payload: data,
});

export const getCountryError = (error) => ({
  type: GET_COUNTRY_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getCountryServiceRequest = async (countryId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/countries/${countryId}`, options);
};
