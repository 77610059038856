import {
  getUsersRequest,
  getUsersSuccess,
  getUsersError,
  getUsersServiceRequest,
} from "../../../store/security/actions/users/getUsers";

export const getFetchUsers = (formData) => async (dispatch, getState) => {
  dispatch(getUsersRequest());
  try {
    const response = await getUsersServiceRequest(formData);
    dispatch(getUsersSuccess(response.data));
  } catch (error) {
    dispatch(getUsersError(error))
  }
  return Promise.resolve();
};

