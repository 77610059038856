import { lazy } from "react";
import { connect } from "react-redux";

const Settings = lazy(() => import("./Settings"));

const mapStateToProps = (state) => {
  return ({
    isLoading: state.administration.settings.loading,
    settings: state.administration.settings.data,
    pagination: state.administration.settings.pagination,
  })
};

export default connect(mapStateToProps)(Settings);
