import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_LEGAL_EMPLOYEE_AFP_REQUEST =
  "PUT_LEGAL_EMPLOYEE_AFP_REQUEST";
export const PUT_LEGAL_EMPLOYEE_AFP_SUCCESS =
  "PUT_LEGAL_EMPLOYEE_AFP_SUCCESS";
export const PUT_LEGAL_EMPLOYEE_AFP_ERROR =
  "PUT_LEGAL_EMPLOYEE_AFP_ERROR";

//* ACTIONS ------------------------------------------------
export const putLegalEmployeeAFPRequest = (payload = {}, callback) => ({
  type: PUT_LEGAL_EMPLOYEE_AFP_REQUEST,
  payload,
  callback,
});

export const putLegalEmployeeAFPSuccess = () => ({
  type: PUT_LEGAL_EMPLOYEE_AFP_SUCCESS,
});

export const putLegalEmployeeAFPError = (error) => ({
  type: PUT_LEGAL_EMPLOYEE_AFP_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putLegalEmployeeAFPServiceRequest = async ({
  employeeId,
  periodId,
  ...data
}) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/payrolls/periods/${periodId}/employee/${employeeId}/afp`, options);
};
