import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_VACATIONS_CURRENT_PERIOD_REQUEST = "GET_VACATIONS_CURRENT_PERIOD_REQUEST";
export const GET_VACATIONS_CURRENT_PERIOD_SUCCESS = "GET_VACATIONS_CURRENT_PERIOD_SUCCESS";
export const GET_VACATIONS_CURRENT_PERIOD_ERROR = "GET_VACATIONS_CURRENT_PERIOD_ERROR";

//* ACTIONS ------------------------------------------------
export const getVacationsCurrentPeriodRequest = (payload = {}, callback) => ({
  type: GET_VACATIONS_CURRENT_PERIOD_REQUEST,
  payload,
  callback,
});

export const getVacationsCurrentPeriodSuccess = (data) => ({
  type: GET_VACATIONS_CURRENT_PERIOD_SUCCESS,
  payload: data,
});

export const getVacationsCurrentPeriodError = (error) => ({
  type: GET_VACATIONS_CURRENT_PERIOD_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getVacationsCurrentPeriodServiceRequest = async ({ ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });
  return request(`/vacations/current_period`, options);
};
