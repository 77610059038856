import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_COSTCENTER_REQUEST = "GET_COSTCENTER_REQUEST";
export const GET_COSTCENTER_SUCCESS = "GET_COSTCENTER_SUCCESS";
export const GET_COSTCENTER_ERROR = "GET_COSTCENTER_ERROR";

//* ACTIONS ------------------------------------------------
export const getCostCenterRequest = (payload = {}, callback) => ({
  type: GET_COSTCENTER_REQUEST,
  payload,
  callback,
});

export const getCostCenterSuccess = (data) => ({
  type: GET_COSTCENTER_SUCCESS,
  payload: data,
});

export const getCostCenterError = (error) => ({
  type: GET_COSTCENTER_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getCostCenterServiceRequest = async (costCenterId) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/costcenters/${costCenterId}`, options);
};
