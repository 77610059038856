import {
  getPayrollReportPaymentBACRequest,
  getPayrollReportPaymentBACSuccess,
  getPayrollReportPaymentBACError,
  getPayrollReportPaymentBACServiceRequest
} from "../../../store/payroll/actions/reports/getReportsPaymentBAC";

export const getPayrollReportPaymentBAC = (formData) => async (dispatch, getState) => {
  dispatch(getPayrollReportPaymentBACRequest());
  try {
    const response = await getPayrollReportPaymentBACServiceRequest(formData);
    dispatch(getPayrollReportPaymentBACSuccess(response.data));
  } catch (error) {
    dispatch(getPayrollReportPaymentBACError(error))
  }
  return Promise.resolve();
};
