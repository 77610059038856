import {
  getFinancialSquareRequest,
  getFinancialSquareSuccess,
  getFinancialSquareError,
  getFinancialSquareServiceRequest,
} from "../../../store/deductions/actions/financialSquare/getFinancialSquare";

export const getFinancialSquare = (formData) => async (dispatch, getState) => {
  dispatch(getFinancialSquareRequest());
  try {
    const response = await getFinancialSquareServiceRequest(formData);
    dispatch(getFinancialSquareSuccess(response.data));
  } catch (error) {
    dispatch(getFinancialSquareError(error))
  }
  return Promise.resolve();
};;
