import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_SETTING_DEFAULT = "PUT_SETTING_DEFAULT";
export const PUT_SETTING_REQUEST = "PUT_SETTING_REQUEST";
export const PUT_SETTING_SUCCESS = "PUT_SETTING_SUCCESS";
export const PUT_SETTING_ERROR = "PUT_SETTING_ERROR";

//* ACTIONS ------------------------------------------------
export const putSettingDefault = () => ({
  type: PUT_SETTING_DEFAULT,
});

export const putSettingRequest = (payload = {}, callback) => ({
  type: PUT_SETTING_REQUEST,
  payload,
  callback,
});

export const putSettingSuccess = () => ({
  type: PUT_SETTING_SUCCESS,
});

export const putSettingError = (error) => ({
  type: PUT_SETTING_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putSettingServiceRequest = async ({
  id,
  ...data
}) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/settings/${id}`, options);
};
