import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

//* ACTIONS ------------------------------------------------
export const getUsersRequest = (payload = {}, callback) => ({
  type: GET_USERS_REQUEST,
  payload,
  callback,
});

export const getUsersSuccess = (data) => ({
  type: GET_USERS_SUCCESS,
  payload: data,
});

export const getUsersError = (error) => ({
  type: GET_USERS_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getUsersServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/users`, options);
};
