import { createSelector } from "reselect";

const periods = (state) => state.payroll.periods;

export const loading = createSelector(periods, (state) => state.loading);

export const loadingAction = createSelector(
  periods,
  (state) => state.loadingAction
);

export const data = createSelector(periods, (state) => state.snapshot.data);

export const pagination = createSelector(
  periods,
  (state) => state.snapshot.pagination
);
