import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const PUT_UPDATE_PERIOD_REQUEST = "PUT_UPDATE_PERIOD_REQUEST";
export const PUT_UPDATE_PERIOD_SUCCESS = "PUT_UPDATE_PERIOD_SUCCESS";
export const PUT_UPDATE_PERIOD_ERROR = "PUT_UPDATE_PERIOD_ERROR";

//* ACTIONS ------------------------------------------------
export const putUpdatePeriodRequest = (payload = {}, callback) => ({
  type: PUT_UPDATE_PERIOD_REQUEST,
  payload,
  callback,
});

export const putUpdatePeriodSuccess = () => ({
  type: PUT_UPDATE_PERIOD_SUCCESS,
});

export const putUpdatePeriodError = (error) => ({
  type: PUT_UPDATE_PERIOD_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const putUpdatePeriodServiceRequest = async ({ periodId, ...data }) => {
  const options = await withToken({
    method: Methods.PUT,
    headers: { CompanyId: await getUserCompany() },
    data: { ...data },
  });

  return request(`/payrolls/periods/${periodId}`, options);
};
