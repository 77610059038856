import React from "react";
import { Route, Switch } from "react-router-dom";

import Administration from "../../../modules/Schedule/Administration";
import ShowUbication from "../../../modules/Schedule/Administration/show";

export default () => {
  return (
    <Switch>
      <Route exact path="/schedule/administration" component={Administration} />
      <Route
        exact
        path="/schedule/administration/:id"
        component={ShowUbication}
      />
    </Switch>
  );
};
