import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR";

//* ACTIONS ------------------------------------------------
export const getCountriesRequest = (payload = {}, callback) => ({
  type: GET_COUNTRIES_REQUEST,
  payload,
  callback,
});

export const getCountriesSuccess = (data) => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: data,
});

export const getCountriesError = (error) => ({
  type: GET_COUNTRIES_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getCountriesServiceRequest = async (params = {}) => {
  const options = await withToken({
    method: Methods.GET,
    params,
    headers: { CompanyId: await getUserCompany() },
  });

  return request(`/countries`, options);
};
