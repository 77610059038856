//All Financial Entities
import {
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_ERROR,
} from "../../actions/settings/getSettings";

//Financial Entitie
import {
  GET_SETTING_REQUEST,
  GET_SETTING_SUCCESS,
  GET_SETTING_ERROR,
} from "../../actions/settings/getSetting";

//Update Financial Entitie
import {
  PUT_SETTING_DEFAULT,
  PUT_SETTING_REQUEST,
  PUT_SETTING_SUCCESS,
  PUT_SETTING_ERROR,
} from "../../actions/settings/putSetting";

//Create Financial Entitie
import {
  POST_SETTING_DEFAULT,
  POST_SETTING_REQUEST,
  POST_SETTING_SUCCESS,
  POST_SETTING_ERROR,
} from "../../actions/settings/postSetting";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
  putSetting: {
    loading: false,
    error: false,
    data: {},
    success: false,
    message: null,
  },
  postSetting: {
    loading: false,
    error: false,
    data: {},
    success: false,
    message: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case GET_SETTINGS_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    //--------------------------
    case GET_SETTING_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_SETTING_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        loading: false,
      };
    case GET_SETTING_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    //--------------------------
    case PUT_SETTING_DEFAULT:
      return {
        ...state,
        putSetting: INITIAL_STATE.putSetting
      };
    case PUT_SETTING_REQUEST:
      return {
        ...state,
        putSetting: {
          data: {},
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case PUT_SETTING_SUCCESS:
      return {
        ...state,
        putSetting: {
          data: action.payload,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case PUT_SETTING_ERROR:
      return {
        ...state,
        putSetting: {
          data: {},
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };
    //--------------------------
    case POST_SETTING_DEFAULT:
      return {
        ...state,
        postSetting: INITIAL_STATE.postSetting
      };
    case POST_SETTING_REQUEST:
      return {
        ...state,
        postSetting: {
          data: {},
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case POST_SETTING_SUCCESS:
      return {
        ...state,
        postSetting: {
          data: action.payload,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case POST_SETTING_ERROR:
      return {
        ...state,
        postSetting: {
          data: {},
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };


    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
