import request, { Methods, withToken } from "../../../../utils/request";
import { getUserCompany } from "../../../../utils/constants";

//* ACTIONTYPES --------------------------------------------
export const GET_PERSONAL_ACTIONS_TYPES_REQUEST = "GET_PERSONAL_ACTIONS_TYPES_REQUEST";
export const GET_PERSONAL_ACTIONS_TYPES_SUCCESS = "GET_PERSONAL_ACTIONS_TYPES_SUCCESS";
export const GET_PERSONAL_ACTIONS_TYPES_ERROR = "GET_PERSONAL_ACTIONS_TYPES_ERROR";

//* ACTIONS ------------------------------------------------
export const getPersonalActionsTypesRequest = (payload = {}, callback) => ({
  type: GET_PERSONAL_ACTIONS_TYPES_REQUEST,
  payload,
  callback,
});

export const getPersonalActionsTypesSuccess = (data) => ({
  type: GET_PERSONAL_ACTIONS_TYPES_SUCCESS,
  payload: data,
});

export const getPersonalActionsTypesError = (error) => ({
  type: GET_PERSONAL_ACTIONS_TYPES_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getPersonalActionsTypesServiceRequest = async ({ ...params }) => {
  const options = await withToken({
    method: Methods.GET,
    headers: { CompanyId: await getUserCompany() },
    params,
  });
  return request(`/personnel_action_types`, options);
};
