import request, { Methods } from "../../../utils/request";

//* ACTIONTYPES --------------------------------------------
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

//* ACTIONS ------------------------------------------------
export const loginRequest = (credentials = {}, callback) => ({
  type: LOGIN_REQUEST,
  payload: credentials,
  callback,
});

export const loginSuccess = (data, callback) => ({
  type: LOGIN_SUCCESS,
  payload: data,
  callback,
});

export const loginError = (error) => ({
  type: LOGIN_ERROR,
  payload: error,
});

//* REQUEST SERVICE ---------------------------------------
export const getLoginRequest = (data = {}) => {
  const options = {
    method: Methods.POST,
    data: {
      ...data,
      grant_type: "password",
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    },
  };

  return request(`/oauth/token`, options);
};
