//All Weeks
import {
  GET_ALL_WEEKS_REQUEST,
  GET_ALL_WEEKS_SUCCESS,
  GET_ALL_WEEKS_ERROR,
} from "../../actions/schedule_control/getAllWeeks";

// Get by employee
import {
  GET_SCHEDULE_BY_EMPLOYEE_DEFAULT,
  GET_SCHEDULE_BY_EMPLOYEE_REQUEST,
  GET_SCHEDULE_BY_EMPLOYEE_SUCCESS,
  GET_SCHEDULE_BY_EMPLOYEE_ERROR,
} from "../../actions/schedule_control/getScheduleByEmployee";

//Crear horario
import {
  POST_SCHEDULE_DEFAULT,
  POST_SCHEDULE_REQUEST,
  POST_SCHEDULE_SUCCESS,
  POST_SCHEDULE_ERROR,
} from "../../actions/schedule_control/postSChedule";

//Crear Semana
import {
  POST_WEEK_DEFAULT,
  POST_WEEK_REQUEST,
  POST_WEEK_SUCCESS,
  POST_WEEK_ERROR,
} from "../../actions/schedule_control/postWeek";

//Copiar Semana
import {
  POST_COPY_WEEK_DEFAULT,
  POST_COPY_WEEK_REQUEST,
  POST_COPY_WEEK_SUCCESS,
  POST_COPY_WEEK_ERROR,
} from "../../actions/schedule_control/postCopyWeek";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
  postWeek: {
    loading: false,
    error: false,
    data: {},
    success: false,
    message: null,
  },
  postCopyWeek: {
    loading: false,
    error: false,
    data: {},
    success: false,
    message: null,
  },
  postSchedule: {
    loading: false,
    error: false,
    data: {},
    success: false,
    message: null,
  },
  getByEmployee: {
    loading: false,
    error: false,
    data: {},
    success: false,
    message: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_WEEKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_WEEKS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case GET_ALL_WEEKS_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    // //--------------------------
    case GET_SCHEDULE_BY_EMPLOYEE_DEFAULT:
      return {
        ...state,
        getByEmployee: INITIAL_STATE.getByEmployee
      };
    case GET_SCHEDULE_BY_EMPLOYEE_REQUEST:
      return {
        ...state,
        getByEmployee: {
          data: {},
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case GET_SCHEDULE_BY_EMPLOYEE_SUCCESS:
      return {
        ...state,
        getByEmployee: {
          data: action.payload,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case GET_SCHEDULE_BY_EMPLOYEE_ERROR:
      return {
        ...state,
        getByEmployee: {
          data: {},
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };
    //--------------------------
    case POST_SCHEDULE_DEFAULT:
      return {
        ...state,
        postSchedule: INITIAL_STATE.postSchedule
      };
    case POST_SCHEDULE_REQUEST:
      return {
        ...state,
        postSchedule: {
          data: {},
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case POST_SCHEDULE_SUCCESS:
      return {
        ...state,
        postSchedule: {
          data: action.payload,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case POST_SCHEDULE_ERROR:
      return {
        ...state,
        postSchedule: {
          data: {},
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };
    //--------------------------
    case POST_WEEK_DEFAULT:
      return {
        ...state,
        postWeek: INITIAL_STATE.postWeek
      };
    case POST_WEEK_REQUEST:
      return {
        ...state,
        postWeek: {
          data: {},
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case POST_WEEK_SUCCESS:
      return {
        ...state,
        postWeek: {
          data: action.payload,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case POST_WEEK_ERROR:
      return {
        ...state,
        postWeek: {
          data: {},
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };
    //--------------------------
    case POST_COPY_WEEK_DEFAULT:
      return {
        ...state,
        postCopyWeek: INITIAL_STATE.postCopyWeek
      };
    case POST_COPY_WEEK_REQUEST:
      return {
        ...state,
        postCopyWeek: {
          data: {},
          error: false,
          loading: true,
          success: false,
          message: null,
        }
      };
    case POST_COPY_WEEK_SUCCESS:
      return {
        ...state,
        postCopyWeek: {
          data: action.payload,
          error: false,
          loading: false,
          success: true,
          message: null,
        }
      };
    case POST_COPY_WEEK_ERROR:
      return {
        ...state,
        postCopyWeek: {
          data: {},
          error: true,
          loading: false,
          success: false,
          message: action.payload || action.payload.status || 401,
        }
      };


    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
